import OTPInputView from '@twotalltotems/react-native-otp-input';
import { Link } from 'expo-router';
import { jwtDecode } from 'jwt-decode';
import { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, IconMail, IconRefresh, Text, tokens } from '@fhs/ui';

import { AuthIdentifiers, IdentifyAuthJwt, useAuthV2 } from '../backend';
import { IdentifyActionSheet } from '../components/actions-sheets/identify-action-sheet';
import { formatPhoneNumber } from '../utils';

const OTP_LENGTH = 6;

export function VerifyOtp() {
  const {
    createOtp,
    validateOtp,
    showIdentifyPopup,
    setShowIdentifyPopup,
    identifyAuthJwts,
    getJwtIdentifier,
  } = useAuthV2();

  const attemptValidateCode = useCallback(
    async otpInput => {
      await validateOtp(otpInput);
    },
    [validateOtp]
  );

  const getIdentifierComponent = useCallback((loginIdentifier, identifierType) => {
    switch (identifierType) {
      case AuthIdentifiers.PHONE_NUMBER:
        //Format as phone
        return <Text weight="bold">{formatPhoneNumber(loginIdentifier)} </Text>;
      case AuthIdentifiers.EMAIL:
        return <Text weight="bold">{loginIdentifier} </Text>;
      case AuthIdentifiers.JWT:
        return (
          <Text weight="bold">
            {jwtDecode<IdentifyAuthJwt>(loginIdentifier).redactedIdentity.redactedEmail}
          </Text>
        );
    }
  }, []);

  const getLinkComponent = useCallback(identifierType => {
    switch (identifierType) {
      case AuthIdentifiers.PHONE_NUMBER:
        return (
          <Link href={'/v2/signin'} style={{ textDecorationLine: 'underline' }}>
            Change Phone
          </Link>
        );
      case AuthIdentifiers.EMAIL:
      case AuthIdentifiers.JWT:
        return (
          <Link href={'/v2/signin/(continuewithemail)'} style={{ textDecorationLine: 'underline' }}>
            Change Email
          </Link>
        );
    }
  }, []);

  const { loginIdentifier, identifierType } = getJwtIdentifier();

  return (
    <>
      <Text.Heading type={'three'}>Verify With Code </Text.Heading>
      <Text>
        {'Enter the 6-digit code sent to you at '}
        {getIdentifierComponent(loginIdentifier, identifierType)}
      </Text>
      {getLinkComponent(identifierType)}
      <OTPInputView
        style={{ width: '100%', height: 100 }}
        pinCount={OTP_LENGTH}
        autoFocusOnLoad
        codeInputFieldStyle={styles.inputBox}
        codeInputHighlightStyle={styles.inputBox}
        onCodeFilled={attemptValidateCode}
      />
      <View style={styles.buttonContainer}>
        <Button style={styles.button} type={'outline'} size="sm">
          <Button.Icon>
            <IconRefresh style={styles.buttonContent} />
          </Button.Icon>
          <Button.Text style={styles.buttonContent}>Resend OTP</Button.Text>
        </Button>
        <Button style={styles.button} type={'outline'} size="sm">
          <Button.Icon>
            <IconMail style={styles.buttonContent} />
          </Button.Icon>
          <Button.Text style={styles.buttonContent}>Verify With Email</Button.Text>
        </Button>
      </View>
      {showIdentifyPopup && (
        <IdentifyActionSheet
          authJwts={identifyAuthJwts}
          isVisible={showIdentifyPopup}
          onClose={() => {
            setShowIdentifyPopup(false);
          }}
          onSelect={(jwt: string) => {
            createOtp({
              loginIdentifier: jwt,
              identifierType: AuthIdentifiers.JWT,
            });
          }}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    marginVertical: 12,
    borderColor: tokens.colors.$blackOpacity75,
    textDecorationColor: tokens.colors.$black,
  },
  buttonContainer: {
    alignItems: 'flex-start',
  },
  buttonContent: {
    alignItems: 'center',
    color: tokens.colors.$black,
  },
  inputBox: {
    width: 32,
    aspectRatio: 4 / 3,
    height: 48,
    textAlign: 'center',
    color: tokens.colors.$black,
  },
});
