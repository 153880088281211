import { StyleSheet, TouchableOpacity, TouchableOpacityProps, View } from 'react-native';

import { IconShoppingBag, Text, tokens } from '@fhs/ui';
import { useOrderContext } from '@fhs-legacy/frontend/src/state/order';

export type HeaderCartButtonProps = Omit<TouchableOpacityProps, 'children'>;
export function HeaderCartButton(props: HeaderCartButtonProps) {
  const { numCartPreviewEntries } = useOrderContext();
  const isCartEmpty = numCartPreviewEntries < 1;
  return (
    <TouchableOpacity style={styles.container} disabled={isCartEmpty} {...props}>
      <View>
        <IconShoppingBag size={32} />
      </View>
      <View
        style={[
          styles.badgeRounded,
          {
            backgroundColor: isCartEmpty
              ? tokens.colors.$houseLight
              : tokens.colors.$houseRedDarken,
          },
        ]}
      >
        <Text.Paragraph
          style={{
            color: isCartEmpty ? tokens.colors.$black : tokens.colors.$white,
          }}
          size="xs"
          weight="bold"
        >
          {numCartPreviewEntries}
        </Text.Paragraph>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-end',
    position: 'relative',
  },
  badgeRounded: {
    width: 20,
    height: 20,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: -10,
    right: -10,
  },
});
