import { Image } from 'expo-image';
import { Fragment } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';
import { IconEdit } from '../icon';
import { Pressable } from '../pressable';

export type StepProps = {
  isActive?: boolean;
  image?: string;
};

export type ItemStepperProps = {
  steps: StepProps[];
  onStepClick?: (index: number) => void;
};

export function ItemStepper(props: ItemStepperProps) {
  const { steps, onStepClick } = props;
  return (
    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
      {steps.map((step, index) => (
        <Fragment key={index}>
          <View style={[styles.box, step.isActive || step.image ? styles.activeBox : null]}>
            <Pressable
              onPress={() => {
                if (onStepClick) {
                  onStepClick(index);
                }
              }}
            >
              <View
                style={[
                  styles.itemButton,
                  step.isActive ? styles.activeItemButton : null,
                  !step.isActive && step.image ? styles.imageItemButton : null,
                ]}
              >
                {step.isActive && <IconEdit size={20} color={tokens.colors.$blackOpacity30} />}
              </View>
              {step.image && !step.isActive && (
                <Image source={{ uri: step.image }} contentFit="contain" style={styles.image} />
              )}
            </Pressable>
          </View>
          {index < steps.length - 1 && (
            <View style={{ justifyContent: 'center' }}>
              <View style={styles.divider} />
            </View>
          )}
        </Fragment>
      ))}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  box: { width: 40, height: 42 },
  activeBox: {
    width: 42,
  },
  itemButton: {
    height: 40,
    width: 40,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: tokens.colors.$blackOpacity30,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'visible',
    position: 'absolute',
    right: 0,
  },
  activeItemButton: {
    borderWidth: 2,
  },
  imageItemButton: {
    borderWidth: 2,
    borderColor: tokens.colors.$success,
  },
  image: {
    width: 46,
    height: 38,
    overflow: 'visible',
    right: 4,
  },
  divider: {
    height: 2,
    width: 8,
    backgroundColor: tokens.colors.$black10,
    left: -1,
  },
});
