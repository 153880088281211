import { CardTypes } from 'state/payment/types';

export enum AcceptedCards {
  MASTERCARD = 'mc',
  VISA = 'visa',
  AMEX = 'amex',
  DISCOVER = 'discover',
  JCB = 'jcb',
  DINERS_CLUB = 'diners',
}

const CardBrandTypes = {
  [AcceptedCards.MASTERCARD]: CardTypes.MASTERCARD,
  [AcceptedCards.VISA]: CardTypes.VISA,
  [AcceptedCards.AMEX]: CardTypes.AMEX,
  [AcceptedCards.DISCOVER]: CardTypes.DISCOVER,
  [AcceptedCards.JCB]: CardTypes.JCB,
  [AcceptedCards.DINERS_CLUB]: CardTypes.DINERS_CLUB,
};

/**
 * We are attempting to map our payment processor cardTypes to our FE CardType's.
 *
 * NOTE: This is intended to map the returned cardType for credit payment methods from the userAccounts query.
 */
export const mapPaymentMethodCardTypes = (paymentMethodCardType: string): CardTypes | undefined => {
  // check for all original card types, mostly first data values
  return CardTypes[paymentMethodCardType]
    ? CardTypes[paymentMethodCardType]
    : CardBrandTypes[paymentMethodCardType];
};
