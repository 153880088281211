import React from 'react';

import { Box, Icon } from '@fhs-legacy/universal-components';
import { CardTypes } from 'state/payment/types';
import { mapPaymentMethodCardTypes } from 'utils/payment/map-payment-method-card-type';

const BlankCard = Box.withConfig({
  backgroundColor: Styles.color.grey.four,
  height: '$5',
  width: '$6',
});

const cardTypeToIcon = {
  [CardTypes.VISA]: <Icon title="Visa" variant="visa" />,
  [CardTypes.MASTERCARD]: <Icon title="Mastercard" variant="mastercard" />,
  [CardTypes.AMEX]: <Icon title="Amex" variant="amex" />,
  [CardTypes.DISCOVER]: <Icon title="Discover" variant="discover" />,
  [CardTypes.APPLE_PAY]: <Icon title="Apple Pay" variant="applepay" />,
  [CardTypes.GOOGLE_PAY]: <Icon title="Google Pay" variant="googlePay" />,
  [CardTypes.PAYPAL]: <Icon title="PayPal" variant="paypal" />,
} as const;

export const cardIconRenderer = (methodType: string): JSX.Element => {
  const cardType = mapPaymentMethodCardTypes(methodType);
  if (!cardType || !cardTypeToIcon[methodType]) {
    return <BlankCard />;
  }

  return cardTypeToIcon[methodType];
};
