import { routes } from 'utils/routing';

// all "boolean" type fields will be strings with one of the following two values
export const TRUE = 'True';
export const FALSE = 'False';

// inconsistent values for boolean string; could be any of the three strings
export const TRUE_VALS = ['Y', 'true', TRUE];
export const FALSE_VALS = ['N', 'false', FALSE];

// Defines keys to be used for mParticle reporting
// The keys will be used to group messages so it's important to be consistent
// when calling an error or custom event
//
// Add new actions here
export enum CustomEventNames {
  ADD_ITEMS_TO_FAVORITE = 'Add Items to Favorite',
  ADD_RECENT_ITEM_TO_CART = 'Add Recent Item To Cart',
  APP_ERROR = 'Application Error',
  APP_REVIEW_POP_UP = 'App Review Pop Up',
  AUTO_SIGN_IN = 'Auto Sign In',
  BUTTON_CLICK_STORE_SELECTED = 'Store Selected',
  CLICK_EVENT = 'Click',
  DELIVERY_STORE_ASSIGNED = 'Delivery Store Assigned',
  DELIVERY_FEES_SECTION_DISPLAYED = 'Delivery Fees Section Displayed',
  DISCOUNT_NOT_APPLIED = 'Discount Not Applied',
  LEGACY_OFFER_ADDED_IN_STORE_ORDER = 'Legacy Offer Added to In Store Order',
  MANUAL_LOCATION_SEARCH = 'Manual location search',
  OFFER_ADDED_TO_ORDER = 'Offer Added to Order',
  OFFER_SELECTED = 'Offer Selected',
  OFFERS_LISTED = 'Offers Listed',
  REWARD_ADDED_TO_ORDER = 'Reward Added to Order',
  REWARD_SELECTED = 'Reward Selected',
  SELECT_SERVICE_MODE = 'Select Service Mode',
  SIGN_IN_OTP_REQUEST = 'Sign in with OTP Request',
  SIGN_IN_OTP_ATTEMPT = 'Sign in with OTP Attempt',
  SIGN_UP_SUCCESSFUL = 'Sign Up Successful',
  SIGN_UP_FLOW_SUCCESSFUL = 'Sign Up Flow Successful',
  STORE_LOCATOR_TAB_VIEW = 'Store Locator Tab View',
  UPDATE_USER_ATTRIBUTES = 'Update User Attributes',
  UPSELL_ADDED = 'Upsell Added',
  UPSELL_DISPLAYED = 'Upsell Displayed',
  UPSIZE_DISPLAYED = 'Upsize Displayed',
  PAGE_VIEW = 'Page View',
  PURCHASE_DELIVERY = 'Purchase Delivery',
  PURCHASE_PICK_UP = 'Purchase Pick Up',
  PUSH_NOTIFICATIONS_RESPONSE = 'Push Notifications Response',
  PYPESTREAM_SUPPORT_OPEN = 'Pypestream Support Open',
  SAVE_NEW_PAYMENT_METHOD = 'Save New Payment Method',
  SUPPORT_FORM_SUBMITTED = 'Support Form Submitted',
  UPSIZE_SELECTION = 'Upsize Selection',
  ACTION_SHEET_SUBMISSION = 'Action Sheet Submission',
  ACTION_SHEET_VIEW = 'Action Sheet View',
  MENU_CARD_DISPLAYED = 'Menu Card Displayed',
  MENU_OFFER_DISPLAYED = 'Menu Offer Displayed',
  E_COMMERCE_PURCHASE = 'eCommerce - Purchase',
  E_COMMERCE_PURCHASE_ITEM = 'eCommerce - Purchase - Item',
  E_COMMERCE_ADD_TO_CART = 'eCommerce - AddToCart',
  E_COMMERCE_ADD_TO_CART_ITEM = 'eCommerce - AddToCart - Item',
  E_COMMERCE_REMOVE_FROM_CART = 'eCommerce - RemoveFromCart',
  E_COMMERCE_REMOVE_FROM_CART_ITEM = 'eCommerce - RemoveFromCart - Item',
  E_COMMERCE_CHECKOUT = 'eCommerce - Checkout',
  E_COMMERCE_CHECKOUT_ITEM = 'eCommerce - Checkout - Item',
  E_COMMERCE_VIEW_DETAIL = 'eCommerce - ViewDetail',
  E_COMMERCE_VIEW_DETAIL_ITEM = 'eCommerce - ViewDetail - Item',
  HOMEPAGE_REORDER = 'Reorder from homepage - Started',
  SCHEDULE_FUTURE_ORDER = 'Schedule Future Order',
  CHANGE_RESTAURANT = 'Change Restaurant to Order',
  DIALOG_VIEW = 'Dialog View',
  ERROR_DIALOG_VIEW = 'Error Dialog View',
  INSTORE_TIP_SELECTED = 'Instore Tip Selected',
}

export enum ClickEventComponentNames {
  MARKETING_TILE = 'Marketing Tile',
  NAV_BAR = 'Nav Bar',
  BUTTON = 'button',
  PRODUCT_MODIFIER_CUSTOMIZATION = 'Product Modifier Customization',
  PRODUCT_SELECTION_MODIFICATION = 'Product Selection Modification',
  PRODUCT_SECTION_TILES = 'Product Sections Tiles',
  PRODUCT_SECTION_TABS = 'Product Sections Tabs',
  PRODUCTS = 'Products',
  PROMO_CODE_TILE = 'Promo Code Tile',
  ADAPTABLE_MENU_CARD = 'Adaptable Menu Card',
  ADAPTABLE_MENU_OFFER = 'Adaptable Menu Offer',
  RAPID_RESCUE_CARD = 'Rapid Rescue Card',
}

export enum ErrorDialogViewComponentNames {
  ERROR_MODAL = 'Error Modal',
  CHECKOUT_ERRORS_MODAL = 'Checkout Errors Modal',
  NETWORK_ERROR_MODAL = 'Network Error Modal',
  REMOVE_STORE_ERROR_MODAL = 'Remove Store Error Modal',
  CART_ERROR_MODAL = 'Cart Error Modal',
  ORDER_CANCELLED_MODAL = 'Order Cancelled Modal',
  REDEMPTION_ERROR_MODAL = 'Redemption Error Modal',
  LOYALTY_CLAIM_POINTS_ERROR_MODAL = 'Loyalty Claim Points Error Modal',
}

export enum DialogViewComponentNames {
  STORE_CLOSED = 'Store Closed',
}

// We can share EventTypes enum between web and RN because the enum values are the same
// between RN and web for mParticle SDK
export enum EventTypes {
  Navigation = 1,
  Location = 2,
  Search = 3,
  Transaction = 4,
  UserContent = 5,
  UserPreference = 6,
  Social = 7,
  Other = 8,
}

// We can share ProductActionTypes enum between web and RN because the enum values are the same
// between RN and web for mParticle SDK
export enum ProductActionTypes {
  AddToCart = 1,
  RemoveFromCart = 2,
  Checkout = 3,
  CheckoutOption = 4,
  Click = 5,
  ViewDetail = 6,
  Purchase = 7,
  Refund = 8,
  AddToWishlist = 9,
  RemoveFromWishlist = 10,
}

export const TRACKED_PAGES = [
  routes.addCard,
  routes.cart,
  routes.cartAddCard,
  routes.info,
  routes.offers,
  routes.orderConfirmation,
  routes.orders,
  routes.signIn,
  routes.signUp,
  routes.storeLocator,
  routes.store,
  // @todo are these routes accurate?
  '/checkin',
  '/checkout',
  '/comboselections',
  '/coupons',
  '/coupons/couponbuilder',
  '/coupons/shortcode',
  '/favorites',
  '/forgotpassword',
  '/loginlegacy',
  '/loginoptions',
  '/menu/productupsell',
  '/menu/specialoffersize',
  '/menucategory',
  '/nickname',
  '/nutritioninfo',
  '/orderdetails',
  '/paymenttypes',
  '/privacypolicy',
  '/productcustomize',
  '/reorder',
  '/restaurantfilters',
  '/restaurants',
  '/settings',
  '/settings/managesubscriptions',
  '/signupoptions',
  '/specialofferitemschooser',
  '/specialoffermealbuilder',
  '/splash',
  '/storedetails',
  '/storeselection',
  '/submitfeedback',
  '/subscriptioncheckout',
  '/terms',
  '/timdubienauquotidien',
  '/tims-for-good',
  '/tims-rewards',
  '/verificationcode',
];
