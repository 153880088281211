import { Image } from 'expo-image';
import { StyleSheet, View } from 'react-native';

import { useMqSelect } from '@fhs/ui';

import { backgroundHeight, backgroundHeightDesktop } from './constants';

export const Background = () => {
  const backgroundImg = (
    <Image
      alt=""
      source={require('./tray-pattern.webp')}
      placeholder={{ thumbhash: '+wcCB4DHxneIZ3hXR5eGeXhwhkCH93wH' }}
      style={styles.backgroundPattern}
      contentFit="cover"
    />
  );
  const backgroundHeightStyle = useMqSelect(
    { $gteDesktop: backgroundHeightDesktop },
    backgroundHeight
  );
  return (
    <View
      style={[
        styles.trayPatternContainer,
        {
          height: backgroundHeightStyle,
        },
      ]}
    >
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
    </View>
  );
};

const styles = StyleSheet.create({
  backgroundPattern: {
    height: '100%',
    aspectRatio: 751 / 706,
  },
  trayPatternContainer: {
    overflow: 'hidden',
    flexDirection: 'row',
  },
});
export { backgroundHeight, backgroundHeightDesktop };
