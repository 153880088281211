import { Link } from 'expo-router';
import React, { useMemo } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Text, createMqStyles } from '@fhs/ui';
import { InlineAlert } from '@fhs-legacy/universal-components';

import { useLoyaltyLegacyStates } from '../../hooks/use-loyalty-legacy-states';

interface Props {
  style?: StyleProp<ViewStyle>;
}

export const SwitchServiceModeInline = ({ style }: Props) => {
  const mqStyles = useMqStyles();
  const { serviceMode } = useLoyaltyLegacyStates();
  const link = useMemo(() => {
    if (serviceMode === 'DELIVERY') {
      return '/store-locator/service-mode';
    }
    return '/store-locator/address';
  }, [serviceMode]);
  const text = useMemo(() => {
    if (serviceMode === 'DELIVERY') {
      return 'Switch to Pickup';
    }
    return 'Switch to Delivery';
  }, [serviceMode]);
  return (
    <InlineAlert
      iconCentered
      status="info"
      borderRadius={8}
      style={[mqStyles.inlineText, style]}
      message={
        <Text.Ui style={[mqStyles.inlineText]} size="md">
          Looking for more offers? {'\n'}
          <Link href={link}>
            <Text.Ui style={mqStyles.textHighlighted} size="md">
              {text}
            </Text.Ui>
          </Link>{' '}
          for additional options.
        </Text.Ui>
      }
    />
  );
};

const useMqStyles = createMqStyles({
  inlineText: {
    $base: {
      fontSize: 12,
      flex: 1,
    },
    $gteDesktop: {
      fontSize: 14,
    },
  },
  textHighlighted: {
    $base: {
      textDecorationLine: 'underline',
      fontSize: 12,
    },
    $gteDesktop: {
      fontSize: 14,
    },
  },
});
