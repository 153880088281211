import { region } from '@fhs-legacy/frontend/src/utils/environment';

export const getMetaTags = ({
  title,
  staticPageSlug,
}: {
  title: string;
  staticPageSlug: string;
}) => {
  const webUrl = region() === 'US' ? 'https://firehousesubs.com' : 'https://firehousesubs.ca';
  return (
    <>
      <meta name="title" content={title} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${webUrl}/v2/${staticPageSlug}`} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={`${webUrl}/logo.png`} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={`${webUrl}/v2/${staticPageSlug}`} />
      <meta property="twitter:url" content={`${webUrl}/v2/${staticPageSlug}`} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:image" content={`${webUrl}/assets/logo.png`} />
    </>
  );
};
