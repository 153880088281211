import { Box, Text } from '@fhs-legacy/universal-components';

import { ModalHeading } from '../styled';

export const DeliveryLogoContainer = Box.withConfig({
  width: '$40',
  marginTop: '$12',
  marginX: 'auto',
  marginBottom: '0',
});

export const LoadingText = Text.withConfig({
  margin: '0',
  fontWeight: 'black',
  textAlign: 'center',
  fontSize: 'md',
  lineHeight: 'md',
});

export const AdditionalInformation = Box.withConfig({
  marginTop: '$2',
  _text: {
    fontSize: 'xs',
  },
});

export const NewAddressModalHeading = ModalHeading.withConfig({
  paddingX: 0,
});

export const DeliveryInfoContainer = Text.withConfig({
  maxWidth: '$48',
  marginY: '$2',
  marginX: 'auto',
  textAlign: 'center',
});

export const ButtonContainer = Box.withConfig({
  marginTop: '$4',
  width: 'full',
});
