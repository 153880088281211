import { router, useLocalSearchParams } from 'expo-router';
import { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { MenuSectionItem } from '@fhs/backend/amplify/functions/_temp/graphql/API';
import { ActionSheet, Text, useMqSelect } from '@fhs/ui';
import ConfirmDialog from '@fhs-legacy/frontend/src/components/confirm-dialog';

import { useOfferDetailUi } from '../../../queries/loyalty.queries';
import { useIsOfferCartDirty, useOfferCart } from '../../../state/offer-guide-cart-context';

import { ContentRenderer } from './content-renderer';
import { Navigation } from './navigation';

const initialExitDialog = {
  onDismiss: () => {},
  onConfirm: () => {},
  showDialog: false,
};

export function OfferGuideScreen() {
  const { offerId } = useLocalSearchParams<{ offerId: string }>();
  const setOffer = useOfferCart(state => state.setOffer);
  const reset = useOfferCart(state => state.reset);
  const { data: offer } = useOfferDetailUi(offerId);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (offer) {
      setOffer(offer);
    }
  }, [setOffer, offer]);

  return <OfferGuideContent />;
}

export function OfferGuideContent() {
  const { height: screenHeight } = useWindowDimensions();
  const isDesktop = useMqSelect({ $gteDesktop: true }, false);
  const [confirmExitDialog, setConfirmExitDialog] = useState(initialExitDialog);

  const { currentStepIndex, goToPrevStep, goToStep } = useOfferCart();

  const isDirty = useIsOfferCartDirty();

  const [selectedEligibleItem, setSelectedEligibleItem] = useState<MenuSectionItem>();

  const close = useCallback(() => {
    router.dismiss();
  }, []);

  const onClose = useCallback(() => {
    if (isDirty) {
      setConfirmExitDialog({
        showDialog: true,
        onConfirm: () => {
          setConfirmExitDialog(initialExitDialog);
          close();
        },
        onDismiss: () => {
          setConfirmExitDialog(initialExitDialog);
        },
      });
      return;
    }
    close();
    return;
  }, [isDirty, close]);

  const onPressPrev = useCallback(() => {
    if (selectedEligibleItem) {
      setSelectedEligibleItem(undefined);
      return;
    }
    if (currentStepIndex === 0) {
      onClose();
      return;
    }
    goToPrevStep();
  }, [goToPrevStep, onClose, selectedEligibleItem, currentStepIndex]);

  const onPressStep = useCallback(
    (index: number) => {
      setSelectedEligibleItem(undefined);
      goToStep(index);
    },
    [goToStep]
  );

  const containerStyle = isDesktop ? { height: screenHeight } : {};

  return (
    <ActionSheet
      onRequestClose={onPressPrev}
      isVisible
      onClose={close}
      containerStyle={{ height: '90%' }}
    >
      <View style={[styles.container, containerStyle]}>
        <Navigation onPressPrev={onPressPrev} onPressStep={onPressStep} onClose={onClose} />
        <ContentRenderer
          selectedEligibleItem={selectedEligibleItem}
          onPressEligibleItem={setSelectedEligibleItem}
        />
      </View>
      <ConfirmDialog
        modalAppearanceEventMessage="Confirm Close Offer Selection"
        showDialog={confirmExitDialog.showDialog}
        onConfirm={confirmExitDialog.onDismiss}
        onDismiss={confirmExitDialog.onDismiss}
        onCancel={confirmExitDialog.onConfirm}
        confirmLabel="Stay Here"
        cancelLabel="Leave"
        bodyComponent={
          <Text style={{ marginBottom: 20 }}>
            The offer won't be applied and you are going to lose all your choices and
            customizations.
          </Text>
        }
        heading="Are You Sure You Want to Leave?"
      />
    </ActionSheet>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
});
