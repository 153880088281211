import { Image } from 'expo-image';
import { router } from 'expo-router';
import { Link, useLocalSearchParams } from 'expo-router';
import { useMemo } from 'react';
import { ImageStyle, Pressable, StyleProp, StyleSheet, View } from 'react-native';

import {
  Button,
  IconClose,
  IconDelete,
  ScrollView,
  Text,
  createMqStyles,
  tokens,
  useMqSelect,
} from '@fhs/ui';
import { IncentiveEvaluationErrorCodes } from '@fhs-legacy/frontend/src/state/loyalty/hooks/types';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';
import { InlineAlert } from '@fhs-legacy/universal-components';

import { OfferDetailsSkeleton } from '../../components/offer-details-skeleton';
import { useApplyOffer } from '../../hooks/use-apply-offer';
import { useLoyaltyLegacyStates } from '../../hooks/use-loyalty-legacy-states';
import { useRemoveOffer } from '../../hooks/use-remove-offer';
import { useOfferIncentive } from '../../queries/loyalty.queries';

function ErrorRenderer({ error }: { error: IncentiveEvaluationErrorCodes }) {
  const styles = useMqStyles();

  const { serviceMode } = useLoyaltyLegacyStates();

  const { actionText, changeServiceModePath, description } = useMemo(() => {
    switch (error) {
      case IncentiveEvaluationErrorCodes.INVALID_SERVICE_MODE: {
        const SERVICE_MODE_ERROR_CONFIG = {
          TAKEOUT: {
            description:
              "This offer isn't available for pickup, please switch to delivery to use it.",
            actionText: 'Switch to Delivery',
            changeServiceModePath: '/store-locator/address',
          },
          DELIVERY: {
            description:
              "This offer isn't available for delivery, please switch to pickup to use it.",
            actionText: 'Switch to Pickup',
            changeServiceModePath: '/store-locator/service-mode',
          },
          default: {
            description:
              "This offer isn't available for the current service mode. Please try a different option.",
            actionText: 'Change Service Mode',
            changeServiceModePath: '/store-locator/service-mode',
          },
        } as const;
        return (
          SERVICE_MODE_ERROR_CONFIG[serviceMode as keyof typeof SERVICE_MODE_ERROR_CONFIG] ??
          SERVICE_MODE_ERROR_CONFIG.default
        );
      }

      case IncentiveEvaluationErrorCodes.INVALID_STORE_ID: {
        return {
          description:
            "This offer isn't available at the currently selected restaurant. Please choose a different location to use it.",
          actionText: 'Change Restaurant',
          changeServiceModePath: '/store-locator/service-mode',
        };
      }

      default: {
        return {
          description: "This offer isn't available right now. Please try again later.",
          actionText: '',
          changeServiceModePath: '',
        };
      }
    }
  }, [error, serviceMode]);

  return (
    <InlineAlert
      iconCentered
      status="info"
      borderRadius={8}
      style={{ marginHorizontal: 16 }}
      message={
        <Text.Ui style={[styles.inlineText]} size="md">
          {description}{' '}
          {actionText && (
            <Text.Ui
              size="md"
              style={[styles.textHighlighted]}
              onPress={() => {
                router.dismiss();
                router.navigate(changeServiceModePath);
              }}
            >
              {actionText}
            </Text.Ui>
          )}
        </Text.Ui>
      }
    />
  );
}

export function OfferDetailPage() {
  const styles = useMqStyles();
  const { offerId } = useLocalSearchParams<{
    offerId: string;
  }>();
  const { loyaltyUserReady, selectedOffer } = useLoyaltyLegacyStates();
  const { data: currentOffer, isLoading: isLoyaltyIncentivesLoading } = useOfferIncentive(offerId);
  const isLoading = isLoyaltyIncentivesLoading || !loyaltyUserReady;
  const isDesktop = useMqSelect({ $gteDesktop: true }, false);
  const isOfferSelected = useMemo(
    () => selectedOffer?._id === offerId,
    [offerId, selectedOffer?._id]
  );
  const { applyOffer } = useApplyOffer();
  const { removeOffer } = useRemoveOffer();

  const error = currentOffer?.errors?.[0] as IncentiveEvaluationErrorCodes | undefined;
  return (
    <View style={styles.modalContainer}>
      {isDesktop && (
        <View style={styles.header}>
          <Text.Ui size="md" style={styles.headerTitle}>
            Offer Details
          </Text.Ui>
          <IconClose size={32} />
        </View>
      )}
      {/* Dismiss modal when pressing outside */}
      <Link href={'/v2/offers'} asChild>
        <Pressable style={StyleSheet.absoluteFill} />
      </Link>

      {isLoading ? (
        <OfferDetailsSkeleton textBlockLines={15} />
      ) : (
        <View style={styles.content}>
          <View style={styles.imageContainer}>
            <Image
              style={styles.image as StyleProp<ImageStyle>}
              source={{ uri: currentOffer?.image }}
              contentFit="cover"
            />
          </View>

          <View style={styles.title}>
            <Text.Heading type="three" style={styles.name}>
              {currentOffer?.name}
            </Text.Heading>
            <Text.Ui size="md" style={styles.description}>
              {currentOffer?.description}
            </Text.Ui>
          </View>
          {error && <ErrorRenderer error={error} />}

          <ScrollView style={styles.scrollViewContainer}>
            <Text.Ui style={styles.termsAndConditions} size="md">
              {currentOffer?.termsAndConditions}
            </Text.Ui>
          </ScrollView>
          <View style={styles.footer}>
            {isOfferSelected ? (
              <Button style={styles.buttonContainer} size="md" onPress={() => removeOffer()}>
                <Button.Icon color={tokens.colors.$white}>
                  <IconDelete />
                </Button.Icon>
                <Button.Text style={styles.buttonLabel}>Remove Offer</Button.Text>
              </Button>
            ) : (
              <Button
                disabled={!currentOffer?.isAvailable}
                style={styles.buttonContainer}
                size="md"
                onPress={() => {
                  // TODO: Add coverage if we has error fetching the offer
                  if (currentOffer) {
                    router.dismiss();
                    applyOffer(currentOffer);
                    if (!currentOffer.requireGuideFlow) {
                      router.navigate(routes.menu);
                    }
                  }
                }}
              >
                <Button.Text style={styles.buttonLabel}>Apply Offer</Button.Text>
              </Button>
            )}
          </View>
        </View>
      )}
    </View>
  );
}

const useMqStyles = createMqStyles({
  modalContainer: {
    $base: {
      flex: 1,
    },
    $gteDesktop: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: tokens.colors.$blackOpacity75,
    },
  },
  header: {
    $gteDesktop: {
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      padding: 28,
      height: 90,
      width: 520,
      backgroundColor: tokens.colors.$white,
      borderBottomWidth: 1,
      borderBottomColor: tokens.colors.$blackOpacity04,
    },
  },
  headerTitle: {
    $base: {
      fontSize: 24,
      fontWeight: 600,
    },
  },
  content: {
    $base: {
      flex: 1,
      justifyContent: 'space-between',
      backgroundColor: tokens.colors.$white,
    },
    $gteDesktop: {
      width: 520,
    },
  },

  imageContainer: {
    $base: {
      borderBottomWidth: 1,
      borderBottomColor: tokens.colors.$blackOpacity04,
    },
  },
  image: {
    $base: {
      height: 216,
    },
  },
  title: {
    $base: {
      borderBottomWidth: 1,
      borderBottomColor: tokens.colors.$blackOpacity04,
      marginTop: 24,
      marginLeft: 16,
      marginRight: 16,
    },
    $gteDesktop: {
      marginLeft: 24,
      marginRight: 24,
    },
  },
  name: {
    $base: {
      textAlign: 'center',
      fontSize: 24,
      paddingTop: 4,
      color: tokens.colors.$black,
      fontWeight: 600,
      marginBottom: 4,
    },
    $gteDesktop: {
      fontSize: 30,
      paddingBottom: 8,
    },
  },
  description: {
    $base: {
      textAlign: 'center',
      fontSize: 14,
      color: tokens.colors.$black,
      marginBottom: 20,
    },
    $gteDesktop: {
      fontSize: 16,
    },
  },
  scrollViewContainer: {
    $base: {
      flex: 1,
      paddingLeft: 16,
      paddingRight: 16,
    },
    $gteDesktop: {
      paddingLeft: 28,
      paddingRight: 28,
    },
  },
  termsAndConditions: {
    $base: {
      color: tokens.colors.$blackOpacity55,
      fontSize: 12,
      lineHeight: 16.8,
      paddingTop: 20,
    },
    $gteDesktop: {
      fontSize: 14,
      lineHeight: 19.6,
      paddingTop: 24,
    },
  },

  footer: {
    $base: {
      height: 114,
      borderTopWidth: 1,
      borderTopColor: tokens.colors.$blackOpacity04,
      alignItems: 'center',
    },
    $gteDesktop: {
      height: 104,
    },
  },
  buttonContainer: {
    $base: {
      width: 358,
      height: 48,
      marginTop: 16,
    },
    $gteDesktop: {
      width: 464,
      marginTop: 28,
    },
  },
  buttonLabel: {
    $base: {
      fontSize: 18,
    },
  },
  inlineText: {
    $base: {
      fontSize: 12,
      flex: 1,
    },
    $gteDesktop: {
      fontSize: 14,
    },
  },
  textHighlighted: {
    $base: {
      textDecorationLine: 'underline',
      fontSize: 12,
    },
    $gteDesktop: {
      fontSize: 14,
    },
  },
});
