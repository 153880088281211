import type { PressableProps } from '../pressable';

import type { IconByType } from './constants';

export type ServiceModeLocationType = keyof typeof IconByType;

export type ServiceModeLocationProps = Omit<PressableProps, 'children'> & {
  type: ServiceModeLocationType;
  storeText?: string;
  serviceModeSubtitle?: string;
};
