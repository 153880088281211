import { Stack } from 'expo-router';
import { Link } from 'expo-router';
import React from 'react';
import { View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import {
  HeaderFrame,
  HeaderIconButton,
  HeaderTitleText,
  IconChevronLeft,
  IconClose,
  MqSwitch,
  Text,
  createMqStyles,
  tokens,
} from '@fhs/ui';

const CustomizeHeader = ({
  title,
  subtitle,
  onClose,
  onCloseUrl,
}: {
  title: string;
  subtitle: string;
  onClose: () => void;
  onCloseUrl: string;
}) => {
  const mqStyles = useMqStyles();
  return (
    <View style={mqStyles.container}>
      <HeaderFrame>
        <HeaderFrame.Left>
          <CloseButtonFrameLeft onClose={onClose} onCloseUrl={onCloseUrl} />
        </HeaderFrame.Left>
        <HeaderFrame.Middle style={{ flexDirection: 'column' }}>
          <HeaderTitleText style={mqStyles.headerTitle} text={title} />
          <Text style={mqStyles.headerSubInfo}>{subtitle}</Text>
        </HeaderFrame.Middle>
        <HeaderFrame.Right>
          <CloseButtonFrameRight onClose={onClose} onCloseUrl={onCloseUrl} />
        </HeaderFrame.Right>
      </HeaderFrame>
    </View>
  );
};

const CloseButtonFrameLeft = ({
  onClose,
  onCloseUrl,
}: {
  onClose: () => void;
  onCloseUrl: string;
}) => (
  <MqSwitch
    $ltDesktop={() => (
      <Link href={onCloseUrl} asChild>
        <HeaderIconButton onPress={onClose} aria-label="Go back">
          <IconChevronLeft />
        </HeaderIconButton>
      </Link>
    )}
    $gteDesktop={() => null}
  />
);

const CloseButtonFrameRight = ({
  onClose,
  onCloseUrl,
}: {
  onClose: () => void;
  onCloseUrl: string;
}) => (
  <MqSwitch
    $ltDesktop={() => null}
    $gteDesktop={() => (
      <Link href={onCloseUrl} asChild>
        <View style={{ top: -18, right: 12 }}>
          <HeaderIconButton
            onPress={onClose}
            size="lg"
            style={{
              height: 48,
              width: 48,
              borderRadius: 100,
              borderColor: tokens.colors.$blackOpacity10,
              shadowColor: tokens.colors.$black,
              shadowOffset: { width: 0, height: 20 },
              shadowOpacity: 0.1,
              shadowRadius: 30,
            }}
            aria-label="Go back"
          >
            <IconClose />
          </HeaderIconButton>
        </View>
      </Link>
    )}
  />
);

export default function CustomizeLayout() {
  return (
    <SafeAreaProvider>
      <Stack
        screenOptions={{
          presentation: 'modal',
          header: () => (
            <CustomizeHeader
              title="Customize Sub"
              subtitle="Medium Size • 720 Calories"
              onClose={() => {}}
              onCloseUrl="/v2/menu/"
            />
          ),
        }}
      />
    </SafeAreaProvider>
  );
}

const useMqStyles = createMqStyles({
  container: {
    $gteDesktop: {
      height: 124,
      backgroundColor: tokens.colors.$white,
      justifyContent: 'center',
    },
  },
  headerTitle: {
    $base: {
      fontSize: 14,
      lineHeight: 19.6,
    },
    $gteDesktop: {
      fontSize: 30,
      lineHeight: 42,
    },
  },
  headerSubInfo: {
    $base: {
      fontSize: 12,
      lineHeight: 16.8,
    },
    $gteDesktop: {
      fontSize: 16,
      lineHeight: 22.4,
    },
  },
});
