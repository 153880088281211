import { useMutation } from '@tanstack/react-query';
import { router, useGlobalSearchParams } from 'expo-router';
import { useCallback, useState } from 'react';
import { View } from 'react-native';

import { addItemToCart } from '@fhs/cart';
import { Button, ErrorBoundary, NotFound, QuantityCounter, createMqStyles, tokens } from '@fhs/ui';

import { MenuErrorFallback } from '../components/menu-error-fallback';
import { MenuItemDetails } from '../components/menu-item-details';
import { formatCentsToDollars } from '../components/util';
import { useMenuPicker } from '../hooks';
import type { ScreenMenuItemProps } from '../types';

export function ScreenMenuItem(props: ScreenMenuItemProps) {
  const mqStyles = useMqStyles();

  const [isShowingNutrition, setIsShowingNutrition] = useState(false);
  const [selectedPickerAspectOptionId, _setSelectedPickerAspectOptionId] = useState<string | null>(
    props.item.pickerAspect.defaultOptionKey
  );
  const [selectedModifiersKeys, setSelectedModifiersKeys] = useState<Record<string, string>>({});
  const setSelectedPickerAspectOptionId = useCallback((pickerAspectOptionId: string) => {
    _setSelectedPickerAspectOptionId(pickerAspectOptionId);
  }, []);

  const [quantity, setQuantity] = useState(1);
  const { isPending, mutate } = useMutation({
    ...addItemToCart,
    onSuccess() {
      router.push('/v2/menu');
    },
  });

  let addItemButtonText = 'Add Item';
  // TODO: Include modifiers up-charges
  const selectedOption = props.item.pickerAspect.options.find(
    o => o.key === selectedPickerAspectOptionId
  );
  const totalPriceCents = quantity * (selectedOption?.item?.basePriceCents ?? 0);
  if (totalPriceCents) {
    addItemButtonText += ` | ${formatCentsToDollars(totalPriceCents)}`;
  }

  return (
    <View style={{ flex: 1 }}>
      <ErrorBoundary fallback={<MenuErrorFallback />}>
        <View style={{ flex: 1 }}>
          <MenuItemDetails
            picker={props.item}
            selectedPickerAspectOptionId={selectedPickerAspectOptionId}
            setSelectedPickerAspectOptionId={setSelectedPickerAspectOptionId}
            selectedModifiersKeys={selectedModifiersKeys}
            setSelectedModifiersKeys={setSelectedModifiersKeys}
            isShowingNutrition={isShowingNutrition}
            setIsShowingNutrition={setIsShowingNutrition}
          />
        </View>

        <View style={mqStyles.layout}>
          <View {...props} style={mqStyles.footer}>
            <QuantityCounter
              value={quantity}
              onChangeValue={newValue => setQuantity(newValue)}
              maxValue={10}
              minValue={0}
            >
              <QuantityCounter.Stepper />
            </QuantityCounter>
            <Button
              loading={isPending}
              onPress={() => {
                const itemId = props.item.pickerAspect?.options.find(
                  option => option.key === selectedPickerAspectOptionId
                )?.itemId;

                if (!itemId) {
                  throw new Error('No itemId found');
                }
                const modifiersToStringsArray = Object.entries(selectedModifiersKeys).map(
                  ([key, value]) => `${key}:${value}`
                );
                mutate({
                  itemId,
                  quantity,
                  modifiers: modifiersToStringsArray,
                  isCombo: false,
                });
              }}
              style={{ flex: 1, maxWidth: 336 }}
            >
              <Button.Text>{addItemButtonText}</Button.Text>
            </Button>
          </View>
        </View>
      </ErrorBoundary>
    </View>
  );
}

type ScreenMenuItemWithQueryProps = {
  selectedRestaurantId?: string;
  region: 'us' | 'ca';
  serviceMode: 'delivery' | 'pickup';
};

export function ScreenMenuItemWithQuery({
  selectedRestaurantId,
  serviceMode,
  region,
}: ScreenMenuItemWithQueryProps) {
  const { slug } = useGlobalSearchParams<{ slug: string }>();
  const {
    data: menuItemData,
    isLoading,
    isError,
  } = useMenuPicker({
    slug,
    restaurantId: selectedRestaurantId,
    serviceMode,
    region,
  });

  if (isLoading) {
    return null;
  }

  // TODO: add refresh logic
  if (isError) {
    return (
      <View style={{ flex: 1 }}>
        <MenuErrorFallback />
      </View>
    );
  }

  if (!menuItemData) {
    return <NotFound buttonText="Go To Menu" buttonUrl="/menu" />;
  }

  return <ScreenMenuItem item={menuItemData} />;
}

const useMqStyles = createMqStyles({
  layout: {
    $base: {
      backgroundColor: tokens.colors.$white,
      padding: 12,
    },
  },
  footer: {
    $base: {
      flexDirection: 'row',
      alignSelf: 'center',
      justifyContent: 'center',
      width: '100%',
      gap: 52,
    },
    $gteDesktop: {
      justifyContent: 'flex-end',
      maxWidth: 1440,
    },
  },
});
