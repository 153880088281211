// TODO: Handle premium modifiers
export function computeSubtotal(cart: any): number {
  const cartEntriesSubtotal = cart.entries.reduce((price, entry) => price + entry.price, 0);
  const donation = cart.donationAmount;

  const savings = cart.appliedIncentives.reduce(
    (discount, incentive) => discount + (incentive.savingsAmount ?? 0),
    0
  );

  // clamp price to never go negative
  return Math.max(cartEntriesSubtotal + donation - savings, 0);
}
