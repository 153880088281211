import { PixelRatio } from 'react-native';

import { IMAGE_HEIGHT } from './constants';

const dpr = Math.max(PixelRatio.get(), 3).toString();

export function optimizeSanityImageUri(uri: string = ''): string {
  const imageUri = new URL(uri);
  imageUri.searchParams.set('h', IMAGE_HEIGHT.toString());
  imageUri.searchParams.set('dpr', dpr);

  return imageUri.toString();
}
