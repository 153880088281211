import { HorizontalPickerAspectOptionSelector } from './horizontal-picker-aspect-option-selector';
import { PickerAspectOptionSelectorProps } from './types';
import { VerticalPickerAspectOptionSelector } from './vertical-picker-aspect-option-selector';

export function PickerAspectOptionSelector(props: PickerAspectOptionSelectorProps) {
  if (props.options.length <= 3) {
    return <HorizontalPickerAspectOptionSelector {...props} />;
  }

  return <VerticalPickerAspectOptionSelector {...props} />;
}
