import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import { useMemo } from 'react';
import { ActivityIndicator, Platform, StyleSheet, View } from 'react-native';
import Animated, {
  FadeIn,
  FadeOut,
  SlideInLeft,
  SlideInRight,
  SlideOutLeft,
  SlideOutRight,
} from 'react-native-reanimated';

import { MenuSectionItem } from '@fhs/backend/amplify/functions/_temp/graphql/API';
import { ActionSheet, ErrorCard, ProductListTile, Text, tokens } from '@fhs/ui';
import { ICartEntry } from '@rbi-ctg/menu';

import { useLegacyMenuStates } from '../../../hooks/use-legacy-menu-states';
import {
  addCartEntryImage,
  useOfferCart,
  useSelectedStep,
} from '../../../state/offer-guide-cart-context';
import { ProductWizard } from '../../legacy-menu/product-wizard';

const StepView = Platform.select({
  native: ({ children, isGoingForward = true }) => (
    <Animated.View
      style={{ flex: 1 }}
      entering={isGoingForward ? SlideInRight : SlideInLeft}
      exiting={isGoingForward ? SlideOutLeft : SlideOutRight}
    >
      {children}
    </Animated.View>
  ),
  web: ({ children }) => <View style={{ flex: 1 }}>{children}</View>,
}) as any;

const ContentView = Platform.select({
  native: ({ children, style = {} }) => (
    <Animated.View style={style} entering={FadeIn.duration(500)} exiting={FadeOut}>
      {children}
    </Animated.View>
  ),
  web: ({ children, style = {} }) => <View style={style}>{children}</View>,
}) as any;

const numColumns = 2;
const renderItem = ({ item, index }: ListRenderItemInfo<any>) => {
  const isFirstColumn = index % numColumns === 0;
  const isLastColumn = index % numColumns === numColumns - 1;
  return (
    <View
      style={[styles.item, isFirstColumn && styles.firstColumn, isLastColumn && styles.lastColumn]}
    >
      <ProductListTile size="lg" {...item} />
    </View>
  );
};

function EligibleItemRenderer({
  productId,
  addItemToSimplyOfferCart,
}: {
  productId: string;
  addItemToSimplyOfferCart: (cartEntry: ICartEntry) => void;
}) {
  const { product, loading } = useLegacyMenuStates({
    productId,
  });

  if (loading) {
    return (
      <View style={styles.loading}>
        <ActivityIndicator size="large" color={tokens.colors.$houseRedDarken} />
      </View>
    );
  }

  if (product) {
    return (
      <ContentView key={product?._id} style={{ flex: 1 }}>
        <ProductWizard
          product={product}
          isSimplyOffer
          addItemToSimplyOfferCart={addItemToSimplyOfferCart}
        />
      </ContentView>
    );
  }

  return <ErrorCard description="Product not found" hideButton />;
}

interface ContentProps {
  selectedEligibleItem: MenuSectionItem;
  onPressEligibleItem: (eligibleItem: MenuSectionItem) => void;
}

export function Content({ selectedEligibleItem, onPressEligibleItem }: ContentProps) {
  const { currentStepIndex, isGoingForward, modifyItemSelectionInStep } = useOfferCart();

  const selectedStep = useSelectedStep();

  const eligibleItemsFormatted = useMemo(
    () =>
      selectedStep?.eligibleItems?.map(eligibleItem => ({
        id: eligibleItem.slug,
        title: eligibleItem.displayName,
        description: eligibleItem.descriptionShort,
        badge: eligibleItem.badge,
        image: {
          asset: {
            uri: eligibleItem.image?.asset?.uri,
            blurHash: eligibleItem.image?.asset?.blurHash,
          },
          altText: eligibleItem.image?.altText,
        },
        isAvailable: eligibleItem.isAvailable,
        onPress: () => onPressEligibleItem(eligibleItem),
      })),
    [selectedStep?.eligibleItems, onPressEligibleItem]
  );

  return (
    <>
      {!selectedEligibleItem && (
        <View style={styles.contentHeader}>
          <Text.Ui size="xs">{selectedStep?.title}</Text.Ui>
          {selectedStep?.subtitle && (
            <Text.Heading type="three">{selectedStep?.subtitle}</Text.Heading>
          )}
        </View>
      )}
      <StepView key={currentStepIndex} isGoingForward={isGoingForward}>
        <View style={{ flex: 1 }}>
          {selectedEligibleItem ? (
            <EligibleItemRenderer
              productId={selectedEligibleItem._legacySlug}
              addItemToSimplyOfferCart={cartEntry => {
                onPressEligibleItem(undefined);
                modifyItemSelectionInStep(
                  currentStepIndex,
                  addCartEntryImage(cartEntry, selectedEligibleItem)
                );
              }}
            />
          ) : (
            <ContentView style={styles.content}>
              <FlashList
                showsVerticalScrollIndicator={false}
                data={eligibleItemsFormatted}
                estimatedItemSize={200}
                renderItem={renderItem}
                numColumns={numColumns}
                renderScrollComponent={ActionSheet.ScrollView}
              />
            </ContentView>
          )}
        </View>
      </StepView>
    </>
  );
}

const styles = StyleSheet.create({
  contentHeader: {
    alignItems: 'center',
    gap: 4,
  },
  content: {
    flex: 1,
    paddingHorizontal: 16,
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: tokens.colors.$white,
  },
  item: {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 12,
  },
  firstColumn: {
    paddingLeft: 0,
  },
  lastColumn: {
    paddingRight: 0,
  },
});
