import { actions, useAppDispatch } from '@fhs-legacy/frontend/src/state/global-state';

import { useLoyaltyLegacyStates } from './use-loyalty-legacy-states';

export const useRemoveOffer = () => {
  const { setOfferValidationError } = useLoyaltyLegacyStates();
  const dispatch = useAppDispatch();
  const removeOffer = () => {
    dispatch(actions.loyalty.setSelectedOffer(null));
    dispatch(actions.loyalty.resetAppliedOffers());
    dispatch(actions.loyalty.setShouldRefetchOffers(true));
    setOfferValidationError(false);
  };
  return { removeOffer };
};
