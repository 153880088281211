import { type ImageAssetWithAltText, RadioGroup } from '@fhs/ui';

import { ListItemGroup } from '../list-item-group-section/list-item-group';
import { ListItemRadio } from '../list-item-group-section/list-item-radio';

type ListItemGroupRadioGroupProps = {
  value: string;
  options: Array<{
    value: string;
    title: string;
    subtitle?: string;
    image?: ImageAssetWithAltText;
    disabled?: boolean;
  }>;
  onChange: (nextPickerAspectOptionId: string) => void;
};

export function ListItemGroupRadioGroup({
  value,
  onChange,
  options = [],
}: ListItemGroupRadioGroupProps) {
  return (
    <RadioGroup value={value} onChange={onChange}>
      <ListItemGroup
        items={options.map(opt => {
          return {
            id: opt.value,
            render: ({ isFirstItem, isLastItem }) => (
              <ListItemRadio
                isFirstItem={isFirstItem}
                isLastItem={isLastItem}
                value={opt.value}
                title={opt.title}
                image={opt.image}
                subtitle={opt.subtitle}
                disabled={opt.disabled}
              />
            ),
          };
        })}
      />
    </RadioGroup>
  );
}
