import { Text } from '@fhs-legacy/universal-components';
import Link from 'components/link';

export const DeliveryTermsText = Text.withConfig({
  fontSize: 'xs',
  textAlign: 'center',
});

export const TermsLink = Link.withConfig({
  _text: {
    textTransform: 'capitalize',
  },
  height: '18px',
  isUnderlined: true,
});
