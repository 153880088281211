import { useCallback, useMemo, useRef } from 'react';
import {
  type LayoutChangeEvent,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  type ScrollView,
} from 'react-native';

export function useScrollIntoViewIfNotVisible(direction: 'horizontal' | 'vertical' = 'horizontal') {
  const scrollerRef = useRef<ScrollView>(null);
  const scrollerLayoutRef = useRef({ x: 0, y: 0, width: 0, height: 0 });
  const scrollerContentOffsetRef = useRef({ x: 0, y: 0 });

  const handleScrollerLayout = useCallback((event: LayoutChangeEvent) => {
    scrollerLayoutRef.current = event.nativeEvent.layout;
  }, []);
  const handleScrollerScroll = useCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
    scrollerContentOffsetRef.current = event.nativeEvent.contentOffset;
  }, []);

  const scrollIntoView = useCallback(
    ({ position, length, offset }: { position: number; length: number; offset?: number }) => {
      const scrollOffset = offset ?? 0;
      const scrollerPosition =
        direction === 'horizontal'
          ? scrollerContentOffsetRef.current.x
          : scrollerContentOffsetRef.current.y;
      const scrollerLength =
        direction === 'horizontal'
          ? scrollerLayoutRef.current.width
          : scrollerLayoutRef.current.height;

      const scrollViewStart = Math.floor(scrollerPosition);
      const scrollViewEnd = Math.ceil(scrollerPosition + scrollerLength);

      const isBeforeVisibleViewport = position < scrollViewStart;
      if (isBeforeVisibleViewport) {
        // Scroll in from start
        const targetPosition = Math.floor(position - scrollOffset);
        scrollerRef.current?.scrollTo(
          direction === 'horizontal' ? { x: targetPosition } : { y: targetPosition }
        );
        return;
      }

      const isAfterVisibleViewport = position + length > scrollViewEnd;
      if (isAfterVisibleViewport) {
        // Scroll in from end
        const targetPosition = Math.ceil(position - scrollerLength + length + scrollOffset);
        scrollerRef.current?.scrollTo(
          direction === 'horizontal' ? { x: targetPosition } : { y: targetPosition }
        );
        return;
      }
    },
    [direction]
  );

  return useMemo(
    () => ({
      scrollerRef,
      scrollIntoView,
      handleScrollerLayout,
      handleScrollerScroll,
    }),
    [handleScrollerLayout, handleScrollerScroll, scrollIntoView]
  );
}
