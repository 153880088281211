import { StyleSheet, View } from 'react-native';

import { Text } from '@fhs/ui';

type Props = {
  total: string;
};

export function CheckoutOrderSummary({ total }: Props) {
  return (
    <>
      <View style={styles.container}>
        <Text.Ui size="md">Subtotal</Text.Ui>
        <Text.Ui size="md">{total}</Text.Ui>
      </View>
      <View style={styles.container}>
        <Text.Ui size="md">Tax</Text.Ui>
        <Text.Ui size="md">2.99</Text.Ui>
      </View>
      <View style={[styles.container, { marginTop: 16 }]}>
        <Text.Ui weight="bold" size="md">
          Total
        </Text.Ui>
        <Text.Ui weight="bold" size="md">
          12.99
        </Text.Ui>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
