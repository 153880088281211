import { memo } from 'react';
import { View, type ViewProps } from 'react-native';

import { createMqStyles } from '../../mq-styles';
import { tokens } from '../../tokens';
import { Text } from '../text';

export type SectionListHeaderProps = ViewProps & {
  title: string;
};

export const SectionListHeader = memo((props: SectionListHeaderProps) => {
  const mqStyles = useMqStyles();

  return (
    <View
      {...props}
      style={[mqStyles.sectionContainer, !props.title && mqStyles.empty, props.style]}
    >
      <Text.Heading type="two">{props.title}</Text.Heading>
    </View>
  );
});

const useMqStyles = createMqStyles({
  empty: {
    $base: {
      height: 0,
      paddingTop: 0,
    },
  },
  sectionContainer: {
    $base: {
      overflow: 'hidden',
      paddingTop: 20,
      backgroundColor: tokens.colors.$white,
    },
    $gteDesktop: {
      paddingTop: 44,
    },
  },
});
