import { router } from 'expo-router';
import { Pressable, StyleSheet, View } from 'react-native';

import { useQuery } from '@fhs/client';
import {
  IconChevronRight,
  IconPaymentTypeApplePay,
  IconPaymentTypeGooglePay,
  IconPaymentTypeVisa,
  Skeleton,
  SkeletonTextBlock,
  Text,
  tokens,
} from '@fhs/ui';

import { getLegacyUserPaymentInformationQuery } from '../../api';
import { useSelectedPayment } from '../../state/payments';

const PaymentMethod = {
  VISA: {
    Icon: IconPaymentTypeVisa,
    text: 'Visa •••• ',
  },
  APPLE_PAY: {
    Icon: IconPaymentTypeApplePay,
    text: 'Apple Pay',
  },
  GOOGLE_PAY: {
    Icon: IconPaymentTypeGooglePay,
    text: 'Google Pay',
  },
} as const;

export function CheckoutPaymentDetails() {
  const { isPending } = useQuery(getLegacyUserPaymentInformationQuery);
  const selectedMethod = useSelectedPayment();

  if (isPending) {
    return (
      <View style={styles.container}>
        <Skeleton style={styles.iconCircle} />
        <SkeletonTextBlock style={styles.text} lines={1} />
      </View>
    );
  }

  if (!selectedMethod) {
    // TODO: Return the add payment method screen
    return null;
  }

  const { Icon, text } =
    PaymentMethod[selectedMethod.brand!] || PaymentMethod[selectedMethod.type!] || {};

  return (
    <Pressable style={styles.anchor} onPress={() => router.setParams({ action: 'payment' })}>
      {props => (
        <View style={[styles.container, props.pressed && styles.pressedContainer]}>
          <View style={[styles.iconCircle, props.pressed && styles.iconCirclePressed]}>
            <Icon size={24} />
          </View>
          <Text.Ui size="md" style={styles.text}>
            {text}
            {selectedMethod.last4}
          </Text.Ui>
          <IconChevronRight size={24} />
        </View>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  pressedContainer: {
    backgroundColor: tokens.colors.$houseLight,
  },
  container: {
    alignItems: 'center',
    height: 72,
    width: '100%',
    borderColor: tokens.colors.$black10,
    backgroundColor: tokens.colors.$white,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 8,
    gap: 8,
    padding: 16,
    flexDirection: 'row',
  },
  text: {
    flex: 1,
  },
  anchor: {
    width: '100%',
  },
  iconCirclePressed: {
    backgroundColor: tokens.colors.$black10,
  },
  iconCircle: {
    height: 40,
    width: 40,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colors.$houseLight,
  },
});
