import React, { useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent, useWindowDimensions } from 'react-native';

import {
  Box,
  addWithConfig,
  usePropsResolution,
  useStyledSystemPropsResolver,
} from '@fhs-legacy/universal-components';
import { parseAssetID } from 'remote/build-image-url';
import { useUIContext } from 'state/ui';
import { PictureBoundaryDimensions, generateSrcUri } from 'utils/image';

import ImageWithPreload from './image-with-preload';
import { IPictureProps, ImageStyle, MeasuredDimensions } from './types';
import { getBoundaryDimensions, isSVG } from './utils';

const Boundary = Box.withConfig({
  overflow: 'hidden',
  width: 'full',
});

const imageProps: ImageStyle = {
  width: '100%',
  height: '100%',
};

const imagePropsSizedByChildren: ImageStyle = {
  width: 'auto',
  height: 'auto',
};

const imagePropsLoading: ImageStyle = {
  ...imageProps,
  opacity: 0.8,
};

function getPlaceholderImage(
  metadata: Partial<Record<'blurHash' | 'lqip', string | null>> | null | undefined
) {
  return metadata?.blurHash || metadata?.lqip || undefined;
}

// A react native extension file for the picture component has been created
// since react-native-web does not currently support source sets properly for web
// through the RN Image component.
// Reference: https://github.com/necolas/react-native-web/issues/515
const Picture = ({
  alt,
  children,
  image,
  lazy = false,
  objectFitContain = false,
  quality = 80,
  resolutionMultiplier = 1, // Not sure if this is necessary anymore. Should we remove it?
  onImageLoaded,
  sizedByChildren = false,
  priority = 'normal',
  skipBreakpoints,
  ...boundaryBoxProps
}: IPictureProps) => {
  // The Image cache check can take a while, so we are storing a local cache too
  const { buildImageUrl } = useUIContext();
  const { width: screenWidth } = useWindowDimensions();

  const [resolvedBoundaryBoxStyleProps] = useStyledSystemPropsResolver(
    usePropsResolution('Picture', boundaryBoxProps)
  );

  const [measuredDimensions, setMeasuredDimensions] = useState<MeasuredDimensions>({
    width: 0,
    height: 0,
  });

  const { format, dimensions } = parseAssetID(image?.asset?._id);
  const isFinalFormatSvg = isSVG(format);

  const boundaryDimensions: PictureBoundaryDimensions = useMemo(() => {
    return sizedByChildren
      ? { height: null, width: null }
      : getBoundaryDimensions({
          extrinsicDimensions: {
            width: resolvedBoundaryBoxStyleProps.width,
            height: resolvedBoundaryBoxStyleProps.height,
          },
          intrinsicDimensions: dimensions,
          measuredDimensions,
        });
  }, [
    dimensions,
    measuredDimensions,
    resolvedBoundaryBoxStyleProps.height,
    resolvedBoundaryBoxStyleProps.width,
    sizedByChildren,
  ]);

  const resolvedURI = useMemo(() => {
    if (!image?.asset?._id) {
      return '';
    }
    return isFinalFormatSvg
      ? buildImageUrl(image)
      : generateSrcUri({
          buildImageUrl,
          image,
          boundaryDimensions,
          measuredDimensions,
          screenWidth,
          quality,
          resolutionMultiplier,
          skipBreakpoints,
        });
  }, [
    boundaryDimensions,
    buildImageUrl,
    image,
    isFinalFormatSvg,
    measuredDimensions,
    quality,
    resolutionMultiplier,
    screenWidth,
    skipBreakpoints,
  ]);

  const handleLayoutEvent = useCallback(
    (event: LayoutChangeEvent) => {
      const layout = event.nativeEvent.layout;
      if (
        layout.width !== measuredDimensions.width ||
        layout.height !== measuredDimensions.height
      ) {
        setMeasuredDimensions({
          width: event.nativeEvent.layout.width,
          height: event.nativeEvent.layout.height,
        });
      }
    },
    [measuredDimensions.height, measuredDimensions.width]
  );

  if (lazy && __DEV__) {
    // eslint-disable-next-line no-console
    console.error(
      'Lazy loading is not yet supported in React Native. ' +
        'If you have a need for lazy loading of images, please use a FlatList'
    );
  }

  if (!image?.asset?._id) {
    return <>{children}</>;
  }

  return (
    <Boundary
      width={boundaryDimensions.width}
      height={boundaryDimensions.height}
      onLayout={handleLayoutEvent}
      {...boundaryBoxProps}
    >
      <ImageWithPreload
        testID="picture-img"
        format={format}
        preloadImage={getPlaceholderImage(image.asset.metadata)}
        uri={resolvedURI}
        accessibilityLabel={alt}
        onLoad={onImageLoaded}
        priority={priority}
        loadingStyle={sizedByChildren ? imagePropsSizedByChildren : imagePropsLoading}
        style={sizedByChildren ? imagePropsSizedByChildren : imageProps}
        contentFit={objectFitContain ? 'contain' : 'cover'}
        sizedByChildren={sizedByChildren}
      >
        {children && <Boundary>{children}</Boundary>}
      </ImageWithPreload>
    </Boundary>
  );
};

export default addWithConfig(Picture);
