import { omit } from 'lodash';
import { StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';
import { IconChevronRight } from '../icon';
import { Pressable, PressableProps } from '../pressable';
import { XStack, YStack } from '../stack';
import { Text } from '../text';

import { IconByType, ServiceModeTextByType } from './constants';
import type { ServiceModeLocationProps } from './types';

export function MobileBottomBarServiceModeLocation(props: ServiceModeLocationProps) {
  const pressableProps = omit(props, [
    'type',
    'storeText',
    'serviceModeSubtitle',
  ]) satisfies PressableProps;
  const Icon = IconByType[props.type];

  return (
    <Pressable
      borderRadius={0}
      outlineStyle={styles.pressableOutlineInset}
      {...pressableProps}
      style={[styles.pressable, pressableProps.style]}
    >
      <View style={styles.flexShrink0}>
        <Icon size={24} />
      </View>
      {props.type !== 'deliveryWithFees' ? (
        <XStack style={styles.textWrapper}>
          <View style={styles.flexShrink0}>
            <Text weight="bold" numberOfLines={1} ellipsizeMode="tail" style={styles.headingText}>
              {ServiceModeTextByType[props.type]}
            </Text>
          </View>
          {props.type !== 'location' && (
            <Text weight="normal" numberOfLines={1} ellipsizeMode="tail" style={styles.bodyText}>
              {props.storeText}
            </Text>
          )}
        </XStack>
      ) : (
        <YStack style={styles.textWrapperVertical}>
          <Text weight="bold" numberOfLines={1} ellipsizeMode="tail" style={styles.headingText}>
            {props.storeText}
          </Text>
          <Text weight="normal" numberOfLines={1} ellipsizeMode="tail" style={styles.bodyText}>
            {props.serviceModeSubtitle}
          </Text>
        </YStack>
      )}
      <View style={styles.flexShrink0}>
        <IconChevronRight size={24} />
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  pressable: {
    flexDirection: 'row',
    gap: 8,
    maxWidth: '100%',
    width: '100%',
    paddingVertical: 12,
    paddingHorizontal: 16,
    backgroundColor: tokens.colors.$houseLight,
    alignItems: 'center',
  },

  flexShrink0: {
    flexShrink: 0,
  },

  textWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    gap: 8,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  textWrapperVertical: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 4,
  },

  headingText: {
    fontSize: 14,
    lineHeight: 20,
    flexShrink: 999,
    flexGrow: 1,
  },

  bodyText: {
    fontSize: 12,
    lineHeight: 16,
    flexShrink: 999,
    flexGrow: 999,
  },

  pressableOutlineInset: {
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    borderRadius: 0,
  },
});
