import { Redirect } from 'expo-router'; // Import the 'Text' component
import { ActivityIndicator, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { ScreenMenuItemWithQuery } from '@fhs/menu/src/screens/screen-menu-item';
import { tokens } from '@fhs/ui';
import { useLocale } from '@fhs-legacy/frontend/src/state/intl';
import { REGIONS } from '@fhs-legacy/frontend/src/state/intl/types';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { useServiceModeContext } from '@fhs-legacy/frontend/src/state/service-mode';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';
import { isSSG } from '@fhs-legacy/frontend/src/utils/environment';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function MenuItemPage() {
  const { store } = useStoreContext();
  const { region } = useLocale();
  const { isDelivery } = useServiceModeContext();

  // TODO(simplified-menu): Remove when simplified menu is out of beta
  const enableSimplifiedMenuBeta = useFlag<boolean>(LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA);
  const enableSimplifiedMenuBetaDetailView = useFlag<boolean>(
    LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA_DETAIL_VIEW
  );

  // The flag will be `undefined` initially, so we need to return null to avoid rendering the `menu` page,
  // because that will cause a redirect to the store locator if the store is not set.
  const flagsAreUndefined =
    enableSimplifiedMenuBeta === undefined && enableSimplifiedMenuBetaDetailView === undefined;
  if (flagsAreUndefined && !isSSG) {
    return <ActivityIndicator />;
  }
  if (!(enableSimplifiedMenuBeta || enableSimplifiedMenuBetaDetailView) && !isSSG) {
    return <Redirect href="/menu" />;
  }
  // END of TODO

  // Defaulting to `0` returns the global menu, w/o pricing and availability
  const selectedRestaurantId = store.number || '0';
  const serviceMode = isDelivery ? 'delivery' : 'pickup';
  const regionAsUnionType = region === REGIONS.US ? 'us' : 'ca';

  return (
    <SafeAreaView edges={['bottom', 'left', 'right']} style={styles.safeArea}>
      <ScreenMenuItemWithQuery
        selectedRestaurantId={selectedRestaurantId}
        region={regionAsUnionType}
        serviceMode={serviceMode}
      />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeArea: {
    backgroundColor: tokens.colors.$white,
    flex: 1,
  },
});
