import { Image } from 'expo-image';
import React from 'react';
import { View } from 'react-native';

import { Checkbox, type CheckboxProps, Text, XStack } from '@fhs/ui';

import { BORDER_RADIUS } from './constants';
import { listStyles } from './shared-styles';
import type { ListItemType } from './types';
import { optimizeSanityImageUri } from './utils';

type ListItemCheckboxProps = ListItemType & {
  onChange: CheckboxProps['onChange'];
  checked: CheckboxProps['checked'];
  disabled?: CheckboxProps['disabled'];
};
export function ListItemCheckbox(props: ListItemCheckboxProps) {
  return (
    <Checkbox
      onChange={props.onChange}
      checked={props.checked}
      label={[props.title, props.subtitle].filter(Boolean).join(' - ')}
      disabled={props.disabled}
    >
      <Checkbox.Pressable
        style={[listStyles.item, props.isLastItem && listStyles.lastItem]}
        hoveredStyle={listStyles.itemHover}
        borderTopLeftRadius={props.isFirstItem ? BORDER_RADIUS : 0}
        borderTopRightRadius={props.isFirstItem ? BORDER_RADIUS : 0}
        borderBottomLeftRadius={props.isLastItem ? BORDER_RADIUS : 0}
        borderBottomRightRadius={props.isLastItem ? BORDER_RADIUS : 0}
      >
        {props.image?.asset?.uri && (
          <Image
            contentFit="contain"
            style={listStyles.image}
            alt={props.image.altText}
            source={{ uri: optimizeSanityImageUri(props.image.asset.uri) }}
            placeholder={{ blurhash: props.image.asset.blurHash }}
          />
        )}
        <View style={listStyles.titleTextContainer}>
          <View>
            <Text.Ui weight={props.titleFontWeight ?? 'semibold'} size="md" numberOfLines={1}>
              {props.title}
            </Text.Ui>
          </View>
          {Boolean(props.subtitle) && (
            <View>
              <Text.Ui size="sm" numberOfLines={1} style={listStyles.subtitle}>
                {props.subtitle}
              </Text.Ui>
            </View>
          )}
        </View>
        <XStack style={listStyles.indicatorWithTextWrapper}>
          {Boolean(props.indicatorText) && (
            <Text.Ui size="sm" numberOfLines={1} style={listStyles.indicatorText}>
              {props.indicatorText}
            </Text.Ui>
          )}
          <Checkbox.Indicator />
        </XStack>
      </Checkbox.Pressable>
    </Checkbox>
  );
}
