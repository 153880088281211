import { StyleSheet } from 'react-native';

import { type ParagraphTextProps, Text, tokens } from '@fhs/ui';

export const NutritionDisclaimerText = (props: ParagraphTextProps) => {
  return <Text.Paragraph size="sm" {...props} style={[styles.paragraphStyles, props.style]} />;
};

const styles = StyleSheet.create({
  paragraphStyles: {
    marginTop: 16,
    color: tokens.colors.$houseDark,
    lineHeight: 16.8,
  },
});
