import { OfferDetailPage } from '@fhs/loyalty';
import { withStoreSelected } from '@fhs-legacy/frontend/src/components/store-selected';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

function OfferDetails() {
  //load offer detail here with amplify
  return <OfferDetailPage />;
}

export default withStoreSelected(OfferDetails);
