import { Stack } from 'expo-router';

import { MqSwitch } from '@fhs/ui';

import { getScreenTitleHeader } from '../../../components/get-screen-title-header';

export default function MenuLayout() {
  return (
    <Stack>
      <Stack.Screen
        name="(menu)/index"
        options={{
          header: props => <MqSwitch $ltDesktop={() => getScreenTitleHeader()(props)} />,
          title: 'Menu',
        }}
      />
      <Stack.Screen
        name="(menu)/[slug]"
        options={{ header: getScreenTitleHeader(), title: '', headerShown: false }}
      />
    </Stack>
  );
}
