import { getMenuSectionsForRestaurant } from '@fhs/backend/amplify/functions/_temp/graphql/queries';
import { client, useQuery } from '@fhs/client';

export const useMenuList = ({
  restaurantId,
  serviceMode,
  region,
}: {
  restaurantId: string;
  serviceMode: 'pickup' | 'delivery';
  region: 'us' | 'ca';
}) =>
  useQuery({
    enabled: Boolean(restaurantId && serviceMode),
    queryKey: ['menus', restaurantId, serviceMode],
    queryFn: async () => {
      if (!restaurantId) {
        return;
      }

      try {
        // XXX: this query is missing the section headers discuss with AWS
        // Can use the API directly
        // const { data: menu } = await client.models.Menu.get(
        //   {
        //     restaurantId: restaurantId,
        //     serviceMode: 'pickup',
        //     // TODO: Ask AWS about this type error
        //   } as never,
        //   {
        //     selectionSet: ['sections.displayName', 'sections.items.id', 'metadata.*'],
        //   }
        // );
        // const response = await client.queries.getMenuSectionsForRestaurant({
        //   region,
        //   restaurantId: '18',
        //   serviceMode,
        // });
        // const menu = response?.data;
        // XXX remove this after discussion with AWS
        // Or a GraphQL query
        const response = await client.graphql({
          query: getMenuSectionsForRestaurant,
          variables: {
            region,
            restaurantId,
            serviceMode,
          },
        });
        const menu = response?.data?.getMenuSectionsForRestaurant;
        return menu;
      } catch (error) {
        // TODO: handle error
        return null;
      }
    },
  });

export const useMenuPicker = ({
  slug,
  restaurantId,
  serviceMode,
  region,
}: {
  slug: string;
  restaurantId: string;
  serviceMode: 'pickup' | 'delivery';
  region: 'us' | 'ca';
}) =>
  useQuery({
    enabled: Boolean(slug && restaurantId && serviceMode),
    queryKey: ['menus', restaurantId, serviceMode, slug],
    queryFn: async () => {
      if (!(restaurantId && serviceMode && slug)) {
        return;
      }
      const { data: menuItem } = await client.queries.getMenuPickerForRestaurant({
        slug,
        restaurantId,
        serviceMode,
        region,
      });

      return menuItem;
    },
  });
