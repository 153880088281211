import { useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, YStack, tokens, useMqSelect } from '@fhs/ui';
import { NutritionalInformation } from '@fhs/ui/src/components/nutritional-information';

import type { MenuItemCustomizationOption, MenuPickerData } from '../types';

import {
  ListItemCheckbox,
  ListItemGroup,
  ListItemGroupSection,
  ListItemLinkPressable,
  ListItemStepper,
} from './list-item-group-section';
import { PickerActionSheet } from './picker-action-sheet';
import { PickerAspectOptionSelector } from './picker-aspect-option-selector';
import { ProductDetailLayout } from './product-detail-layout';
import { ProductNameAndImage } from './product-name-and-image';
import { formatCentsToDollarsWithPlusSign } from './util';

type MenuDetailsProps = {
  picker: MenuPickerData;
  selectedPickerAspectOptionId?: string;
  setSelectedPickerAspectOptionId: (selectedPickerAspectOptionId: string) => void;
  selectedModifiersKeys: Record<string, any>;
  setSelectedModifiersKeys: (modifiersKeys: Record<string, string>) => void;
  isShowingNutrition: boolean;
  setIsShowingNutrition: (isShowingNutrition: boolean) => void;
};

export function MenuItemDetails(props: MenuDetailsProps) {
  const requiresPickAspectOptionSelection = props.picker.pickerAspect.options.length > 1;
  // The option that is currently being selected in the picker action sheet
  // If the value is `null, the picker action sheet is not visible
  const [activePickerActionSheetOption, setActivePickerActionSheetOption] =
    useState<MenuItemCustomizationOption | null>(null);
  const [isShowingAllCustomizations, setIsShowingAllCustomizations] = useState(false);

  const pickerAspectOptionMap = useMemo(
    () => new Map(props.picker.pickerAspect.options.map(option => [option.key, option])),
    [props.picker.pickerAspect.options]
  );
  const selectedPickerAspectOption = pickerAspectOptionMap.get(props.selectedPickerAspectOptionId);

  const displayGroups = selectedPickerAspectOption?.item?.customizations.displayGroups;
  const topLevelChoices = displayGroups?.find(
    customization => customization.displayGroup.showOnProductPage
  );
  // All display groups except the top level choices
  const allCustomizations = displayGroups?.filter(
    customization => !customization.displayGroup.showOnProductPage
  );

  const hasCustomizations =
    selectedPickerAspectOption?.item?.customizations?.displayGroups?.length > 0 || false;

  const baseCaloriesDisplay = selectedPickerAspectOption?.item?.baseCalories
    ? `${selectedPickerAspectOption?.item?.baseCalories} Cal`
    : null;

  return (
    <ProductDetailLayout
      nameAndImageContent={
        <ProductNameAndImage
          displayName={props.picker?.displayName}
          description={selectedPickerAspectOption?.item?.description}
          variant={selectedPickerAspectOption?.displayName}
          cals={baseCaloriesDisplay}
          asHero={useMqSelect({ $gteDesktop: true }, !requiresPickAspectOptionSelection)}
          imageUrl={props.picker.image.asset.uri}
        />
      }
      customizationContent={
        <YStack style={styles.customizationContent}>
          {requiresPickAspectOptionSelection && (
            <ListItemGroupSection heading={props.picker.pickerAspect?.displayName}>
              <PickerAspectOptionSelector
                value={props.selectedPickerAspectOptionId}
                onChange={props.setSelectedPickerAspectOptionId}
                options={props.picker.pickerAspect?.options.map(opt => ({
                  value: opt.id,
                  title: opt.displayName,
                  subtitle: opt.description,
                  image: opt.image,
                }))}
              />
            </ListItemGroupSection>
          )}
          {hasCustomizations && (
            <ListItemGroupSection heading="Customizations">
              <ListItemGroup
                items={(topLevelChoices?.options ?? []).map(opt => ({
                  id: opt.key,
                  render: getOptionRenderer({
                    option: opt,
                    selectedModifiersKeys: props.selectedModifiersKeys,
                    setSelectedModifiersKeys: props.setSelectedModifiersKeys,
                    setActivePickerActionSheetOption: setActivePickerActionSheetOption,
                  }),
                }))}
                footer={
                  !isShowingAllCustomizations && (
                    <Button
                      type="outline"
                      size="md"
                      onPress={() => setIsShowingAllCustomizations(true)}
                    >
                      <Button.Text>Customize Ingredients</Button.Text>
                    </Button>
                  )
                }
              />
            </ListItemGroupSection>
          )}

          {isShowingAllCustomizations &&
            (allCustomizations ?? []).map((opt, i) => (
              <ListItemGroupSection
                heading={opt?.displayGroup.displayName}
                key={opt.displayGroup.id ?? i}
              >
                <ListItemGroup
                  items={(opt.options ?? []).map(o => ({
                    id: o.key,
                    render: getOptionRenderer({
                      option: o,
                      selectedModifiersKeys: props.selectedModifiersKeys,
                      setSelectedModifiersKeys: props.setSelectedModifiersKeys,
                      setActivePickerActionSheetOption: setActivePickerActionSheetOption,
                    }),
                  }))}
                />
              </ListItemGroupSection>
            ))}

          <View style={styles.divider} />
          <ListItemGroup
            items={[
              {
                render: ({ isFirstItem, isLastItem }) => (
                  <ListItemLinkPressable
                    isFirstItem={isFirstItem}
                    isLastItem={isLastItem}
                    title="Nutritional Information"
                    onPress={() => props.setIsShowingNutrition(true)}
                  />
                ),
              },
            ]}
          />

          {/* Bottom Sheets */}
          <NutritionalInformation
            nutritionFacts={{
              title: selectedPickerAspectOption?.item?.displayName,
              image: selectedPickerAspectOption?.item?.image,
              // @ts-ignore
              facts: selectedPickerAspectOption?.item?.nutrition ?? {},
            }}
            isVisible={props.isShowingNutrition}
            onClose={() => props.setIsShowingNutrition(false)}
          />
          <PickerActionSheet
            selectedOptionKey={
              // Get the active option key based on the parent option key or default to the parent key
              props.selectedModifiersKeys[activePickerActionSheetOption?.key]
            }
            options={activePickerActionSheetOption?.options ?? []}
            isVisible={!!activePickerActionSheetOption}
            onSelect={option => {
              props.setSelectedModifiersKeys({
                ...props.selectedModifiersKeys,
                // This is the parent option that was selected
                [activePickerActionSheetOption.key]: option.key,
              });
              setActivePickerActionSheetOption(null);
            }}
            onClose={() => setActivePickerActionSheetOption(null)}
          />
        </YStack>
      }
    />
  );
}

const styles = StyleSheet.create({
  customizationContent: {
    paddingHorizontal: 16,
    gap: 24,
  },

  divider: {
    height: 1,
    backgroundColor: tokens.colors.$blackOpacity04,
  },
});

function getOptionRenderer({
  option,
  selectedModifiersKeys,
  setSelectedModifiersKeys,
  setActivePickerActionSheetOption,
}) {
  switch (option.displayType) {
    case 'CHECKBOX': {
      return ({ isFirstItem, isLastItem }) => {
        const onOption = option.options.find(o => o.multiplier > 0);
        const offOption = option.options.find(o => o.multiplier === 0);
        return (
          <ListItemCheckbox
            isFirstItem={isFirstItem}
            isLastItem={isLastItem}
            checked={selectedModifiersKeys[option.key] === onOption?.key}
            onChange={nextState =>
              setSelectedModifiersKeys(prev => ({
                ...prev,
                [option.key]: nextState ? onOption?.key : offOption?.key,
              }))
            }
            image={onOption?.image}
            title={option.displayName}
            subtitle={onOption?.caloriesOffset && `${onOption.caloriesOffset} Cal`}
            indicatorText={formatCentsToDollarsWithPlusSign(onOption?.upChargeCents)}
          />
        );
      };
    }

    case 'SELECT': {
      return ({ isFirstItem, isLastItem }) => {
        const currentValue = selectedModifiersKeys[option.key] ?? option.defaultOptionKey;
        const selectedOption = option.options.find(o => o.key === currentValue);

        return (
          <ListItemLinkPressable
            onPress={() => setActivePickerActionSheetOption?.(option)}
            isFirstItem={isFirstItem}
            isLastItem={isLastItem}
            image={selectedOption?.image}
            title={option.displayName}
            subtitle={[
              formatCentsToDollarsWithPlusSign(selectedOption.upChargeCents),
              selectedOption.displayName,
            ]
              .filter(Boolean)
              .join(' • ')}
          />
        );
      };
    }

    case 'STEPPER': {
      return ({ isFirstItem, isLastItem }) => {
        const currentValue = selectedModifiersKeys[option.key] ?? option.defaultOptionKey;
        const selectedOption = option.options.find(o => o.key === currentValue);

        return (
          <ListItemStepper
            isFirstItem={isFirstItem}
            isLastItem={isLastItem}
            options={option.options.map(o => ({
              label: o.prefix ?? o.displayName,
              value: o.key,
            }))}
            value={currentValue}
            onChangeValue={nextValue =>
              setSelectedModifiersKeys(prev => ({ ...prev, [option.key]: nextValue }))
            }
            image={selectedOption?.image}
            title={option.displayName}
            subtitle={selectedOption?.caloriesOffset && `${selectedOption.caloriesOffset} Cal`}
          />
        );
      };
    }

    default: {
      return null;
    }
  }
}
