import { ListItemGroupRadioGroup } from '../list-item-group-section';

import type { PickerAspectOptionSelectorProps } from './types';

export function VerticalPickerAspectOptionSelector(props: PickerAspectOptionSelectorProps) {
  return (
    <ListItemGroupRadioGroup
      value={props.value}
      onChange={props.onChange}
      options={props.options}
    />
  );
}
