import { Stack } from 'expo-router';

import { getScreenTitleHeader } from '../../../components/get-screen-title-header';

export default function MenuStackLayout() {
  return (
    <Stack
      screenOptions={{
        header: getScreenTitleHeader({ cannotGoBackHref: '/v2/' }),
        title: 'Menu Sync',
      }}
    />
  );
}
