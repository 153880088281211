import { router } from 'expo-router';
import { useEffect } from 'react';

import { client, queryClient, useQuery } from '@fhs/client';
import { getCurrentSession } from '@fhs-legacy/frontend/src/remote/auth';
import { ServiceMode } from '@fhs-legacy/frontend/src/state/service-mode';
import { Toast } from '@fhs-legacy/native-base';

const cartUpdateHandler = result => {
  if (result.errors) {
    throw result.errors;
  }
  if (result.data) {
    queryClient.setQueryData(['cart'], result.data, { updatedAt: Date.now() });
    return result.data;
  }
  throw [new Error('UndetectableCartError')];
};

const commonErrorHandler = async res => {
  let errors = Array.isArray(res) ? res : [res];
  while (errors.length) {
    const error = errors.pop();

    switch (error.message) {
      case 'MissingCartError':
        router.push({
          pathname: '/store-locator/service-mode',
          params: {
            back: '../',
          },
        });
        // TODO: Blaine - get design advice here
        Toast.show({ title: 'You must select a store before continuing' });
    }
  }

  throw errors;
};

const getAuthMode = async (): Promise<any> => {
  const session = await getCurrentSession();
  if (session) {
    return {
      authToken: 'Bearer ' + session.getIdToken().getJwtToken(),
      authMode: 'lambda',
    };
  }

  return { authMode: 'apiKey' };
};

export const getOrderTimeslots = {
  queryKey: ['order-timeslots'],
  queryFn: async () => client.queries.orderTimeslots(await getAuthMode()).then(res => res.data),
};

export const getLegacyUserPaymentInformationQuery = {
  queryKey: ['legacy-user-payment-information'],
  queryFn: async () =>
    client.queries.legacyUserPaymentInformation(await getAuthMode()).then(res => res.data),
};

export function useCartSubscription() {
  const queryData = useQuery(getCart);

  useEffect(() => {
    let unsubscribe = () => {};
    getAuthMode().then(auth => {
      const sub = client.subscriptions.cartUpdates(auth).subscribe(cart => {
        queryClient.setQueryData(['cart'], cart, { updatedAt: Date.now() });
      });
      unsubscribe = sub.unsubscribe;
    });
    return unsubscribe;
  }, []);

  return queryData;
}

export const getCart = {
  queryKey: ['cart'],
  queryFn: async () => {
    return client.queries
      .cart(await getAuthMode())
      .then(res => res.data)
      .catch(commonErrorHandler);
  },
};

export const priceCart = {
  mutationFn: async () => {
    return client.mutations
      .priceCart(await getAuthMode())
      .then(cartUpdateHandler)
      .catch(commonErrorHandler);
  },
};

export const commitOrder = {
  mutationFn: async ({ payment }: { payment: Record<string, any> }) => {
    return client.mutations
      .commitCart({ paymentJson: JSON.stringify(payment) }, await getAuthMode())
      .then(cartUpdateHandler)
      .catch(commonErrorHandler);
  },
};

export const selectStore = {
  mutationFn: async ({
    storeNumber,
    serviceMode,
  }: {
    storeNumber: string;
    serviceMode: ServiceMode;
  }) => {
    return client.mutations
      .selectStore({ storeNumber, serviceMode }, await getAuthMode())
      .then(cartUpdateHandler)
      .catch(commonErrorHandler);
  },
};

export const addOfferToCart = {
  mutationFn: async ({ offerId, entries: _entries }: { offerId: string; entries: any }) => {
    return client.mutations
      .addOfferToCart({ offerId }, await getAuthMode())
      .then(cartUpdateHandler)
      .catch(commonErrorHandler);
  },
};

export const removeOfferfromCart = {
  mutationFn: async ({ offerId }: { offerId: string }) => {
    return client.mutations
      .removeOfferFromCart({ offerId }, await getAuthMode())
      .then(cartUpdateHandler)
      .catch(commonErrorHandler);
  },
};

export const addItemToCart = {
  mutationFn: async ({
    itemId,
    quantity,
    modifiers,
    isCombo,
  }: {
    itemId: string;
    quantity: number;
    modifiers: any;
    isCombo: boolean;
  }) => {
    return client.mutations
      .addItemToCart(
        {
          itemId,
          quantity,
          modifiersJson: JSON.stringify(modifiers),
          isCombo,
        },
        await getAuthMode()
      )
      .then(cartUpdateHandler)
      .catch(commonErrorHandler);
  },
};

export const updateItemInCart = {
  mutationFn: async ({
    lineId,
    itemId,
    quantity,
    modifiers,
    isCombo,
  }: {
    lineId: string;
    itemId?: string;
    quantity?: number;
    modifiers?: any;
    isCombo?: boolean;
  }) => {
    return client.mutations
      .updateItemInCart(
        {
          lineId,
          itemId,
          quantity,
          modifiersJson: JSON.stringify(modifiers),
          isCombo,
        },
        await getAuthMode()
      )
      .then(cartUpdateHandler)
      .catch(commonErrorHandler);
  },
};

export const setDonationAmount = {
  mutationFn: async ({ amount }: { amount: number }) => {
    return client.mutations
      .setDonationAmount(
        {
          amount,
        },
        await getAuthMode()
      )
      .then(cartUpdateHandler)
      .catch(commonErrorHandler);
  },
};

export const setOrderFulfillmentTime = {
  mutationFn: async ({ fulfillmentTime }: { fulfillmentTime: string }) => {
    return client.mutations
      .setOrderFulfillmentTime({ fulfillmentTime }, await getAuthMode())
      .then(cartUpdateHandler)
      .catch(commonErrorHandler);
  },
};

export const usePointsOnItem = {
  mutationFn: async ({ lineId }: { lineId: string }) => {
    return client.mutations
      .usePointsOnItem({ lineId }, await getAuthMode())
      .then(cartUpdateHandler)
      .catch(commonErrorHandler);
  },
};

export const removePointUsageFromItem = {
  mutationFn: async ({ lineId }: { lineId: string }) => {
    return client.mutations
      .removePointUsageFromItem({ lineId }, await getAuthMode())
      .then(cartUpdateHandler)
      .catch(commonErrorHandler);
  },
};
