import { StyleSheet } from 'react-native';

import { tokens } from '@fhs/ui';

import { BORDER_RADIUS, IMAGE_HEIGHT } from './constants';

export const listStyles = StyleSheet.create({
  list: {
    borderRadius: BORDER_RADIUS,
    borderColor: tokens.colors.$blackOpacity10,
    borderWidth: 1,
    flexGrow: 1,
    flexShrink: 1,
  },
  item: {
    minHeight: 65,
    paddingVertical: 12,
    paddingHorizontal: 16,
    gap: 12,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 0,
    borderBottomWidth: 1,
    borderBottomColor: tokens.colors.$blackOpacity10,
  },
  itemHover: {
    backgroundColor: tokens.colors.$blackOpacity04,
  },
  lastItem: {
    borderBottomColor: tokens.colors.$transparent,
  },
  titleTextContainer: {
    marginRight: 4,
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'center',
  },
  image: {
    aspectRatio: 1,
    height: IMAGE_HEIGHT,
  },
  subtitle: {
    color: tokens.colors.$blackOpacity75,
  },
  indicatorText: {
    color: tokens.colors.$disabledText,
  },
  indicatorWithTextWrapper: {
    alignItems: 'center',
    gap: 12,
  },
});
