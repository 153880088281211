import { useLocalSearchParams } from 'expo-router';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { IBaseProps, Nullable, SetState } from '@rbi-ctg/frontend';
import useDialogModal from 'hooks/use-dialog-modal';
import { useLocalStorageState } from 'hooks/use-local-storage-state';
import { useCRMEventsContext } from 'state/crm-events';
import { serializePickupMode, serializeServiceMode } from 'state/crm-events/utils';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import noop from 'utils/noop';
import {
  isCateringDelivery as checkIfItsCateringDelivery,
  isCateringPickup as checkIfItsCateringPickup,
} from 'utils/service-mode';

import { DeliveryFeeDialogContent } from './delivery-fee-dialog-content';
import { DeliveryFees, ServiceMode } from './types';

export { ServiceMode };

const getServiceModeFromLS = (): ServiceMode | null => {
  const preloaded = LocalStorage.getItem(StorageKeys.SERVICE_MODE);
  return preloaded ? preloaded.serviceMode : null;
};

const persistServiceMode = (serviceMode: Nullable<ServiceMode>) => {
  LocalStorage.setItem(StorageKeys.SERVICE_MODE, { serviceMode });
};

export interface IServiceModeCtx {
  deliveryFees: Nullable<DeliveryFees | null>;
  serviceMode: Nullable<ServiceMode>;
  setShowFeeWarning: SetState<Boolean | null>;
  setServiceMode: SetState<ServiceMode | null>;
  updateDeliveryFees: (newFees: DeliveryFees) => void;
  isDelivery: boolean;
  isTakeout: boolean;
  isEatIn: boolean;
  isCurbside: boolean;
  isCatering: boolean;
}

export const ServiceModeContext = createContext<IServiceModeCtx>({
  deliveryFees: null,
  serviceMode: null,
  setShowFeeWarning: noop,
  setServiceMode: noop,
  updateDeliveryFees: noop,
  isDelivery: false,
  isTakeout: false,
  isEatIn: false,
  isCurbside: false,
  isCatering: false,
});

export const useServiceModeContext = () => useContext(ServiceModeContext);

export const ServiceModeProvider = ({ children }: IBaseProps) => {
  const { formatMessage } = useIntl();
  const params = useLocalSearchParams<{ 'service-mode': string }>();
  const serviceModeInRoute = ServiceMode[`${params['service-mode']}`] ?? '';

  const [serviceMode, setServiceMode] = useState<ServiceMode | null>(
    serviceModeInRoute || getServiceModeFromLS()
  );

  const enableDeliveryOnly = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY_ONLY);
  const enableDeliveryFeeSurchargeModal = Boolean(
    useFlag(LaunchDarklyFlag.SHOW_INCREASED_DELIVERY_FEES_MODAL)
  );

  const isDelivery = serviceMode === ServiceMode.DELIVERY;
  const isTakeout = serviceMode === ServiceMode.TAKEOUT;
  const isEatIn = serviceMode === ServiceMode.EAT_IN;
  const isCurbside = serviceMode === ServiceMode.CURBSIDE;
  const isCateringDelivery = checkIfItsCateringDelivery(serviceMode);
  const isCateringPickup = checkIfItsCateringPickup(serviceMode);
  const isCatering = isCateringDelivery || isCateringPickup;
  const { updateServiceMode } = useLDContext();
  const { updateUniversalAttributes } = useCRMEventsContext();

  const [showFeeWarning, setShowFeeWarning, clearShowFeeWarning] =
    useLocalStorageState<Boolean | null>({
      key: StorageKeys.DELIVERY_SURCHARGE_FEE_CONFIRM,
      defaultReturnValue: null,
    });

  const [deliveryFees, setDeliveryFees] = useLocalStorageState<DeliveryFees | null>({
    key: StorageKeys.DELIVERY_SURCHARGE_FEES,
    defaultReturnValue: null,
  });

  const updateDeliveryFees = useCallback(
    (newFees: DeliveryFees) => {
      setDeliveryFees(newFees);
    },
    [setDeliveryFees]
  );

  const [DeliveryFeeSurchargeDialog, openDeliveryFeeSurcharge] = useDialogModal({
    onConfirm: clearShowFeeWarning,
    modalAppearanceEventMessage: 'Delivery Surcharge',
  });

  const showDeliveryFeeSurchargeModal = enableDeliveryFeeSurchargeModal && !!showFeeWarning;

  useEffect(() => {
    if (showDeliveryFeeSurchargeModal) {
      openDeliveryFeeSurcharge();
    }
  }, [openDeliveryFeeSurcharge, showDeliveryFeeSurchargeModal]);

  useEffect(() => {
    persistServiceMode(serviceMode);
    // Updates service/pickup modes for all mParticle events/page views
    updateUniversalAttributes({
      'Service Mode': serializeServiceMode(serviceMode),
      'Pickup Mode': serializePickupMode(serviceMode),
    });
    // update LaunchDarkly
    updateServiceMode(serviceMode);
  }, [serviceMode, updateServiceMode, updateUniversalAttributes]);

  useEffect(() => {
    // If the service mode isn't delivery and the only service mode available is delivery, wipe out the current service mode
    if (enableDeliveryOnly && !isDelivery) {
      setServiceMode(null);
    }
  }, [enableDeliveryOnly, isDelivery, serviceMode]); // Needed to be checked every time serviceMode is being changed

  useEffect(() => {
    if (serviceModeInRoute && serviceModeInRoute !== serviceMode) {
      setServiceMode(serviceModeInRoute);
    }
  }, [serviceMode, serviceModeInRoute]);
  return (
    <ServiceModeContext.Provider
      value={{
        deliveryFees,
        serviceMode,
        setServiceMode,
        updateDeliveryFees,
        setShowFeeWarning,
        isDelivery,
        isTakeout,
        isEatIn,
        isCurbside,
        isCatering,
      }}
    >
      {enableDeliveryFeeSurchargeModal && showFeeWarning && (
        <DeliveryFeeSurchargeDialog heading={formatMessage({ id: 'deliveryFee' })}>
          <DeliveryFeeDialogContent
            deliveryTotalFee={deliveryFees?.deliveryTotalFee}
            deliveryFees={deliveryFees?.fees || []}
            onTermsRedirect={clearShowFeeWarning}
          />
        </DeliveryFeeSurchargeDialog>
      )}
      {children}
    </ServiceModeContext.Provider>
  );
};
