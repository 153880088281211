import { Redirect, router, useLocalSearchParams, useSegments } from 'expo-router';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

import { ActionSheet, tokens } from '@fhs/ui';

import { useCartSubscription } from '../api';
import { PaymentMethodSelection } from '../components/action-payment-method-selection';
import { CartActionPickupTimeSelector } from '../components/cart-action-pickup-time-selector';
import { CartActionRemoveItem } from '../components/cart-action-remove-item';
import { CartActionRemoveOffer } from '../components/cart-action-remove-offer';

import { CartScreen as CartReviewScreen } from './_cart';
import { CheckoutScreen } from './_checkout';

const onCloseCart = () => router.replace('/v2/cart');
const onCloseCheckout = () => router.replace('/v2/cart/(checkout)');

export function CartScreen() {
  const { data: cart, error, isPending } = useCartSubscription();

  const { action, id } = useLocalSearchParams<{
    action: 'remove-offer' | 'remove-item' | 'time' | 'service-mode' | 'payment';
    id: string;
  }>();
  const segments = useSegments();
  const showTimeSelector = action === 'time';
  const showPaymentSelector = action === 'payment';
  const showServiceModeSelector = action === 'service-mode';
  const showRemoveOfferSelector = action === 'remove-offer';
  const showRemoveItemSelector = action === 'remove-item';
  const isCheckout = segments.includes('(checkout)');

  if (isPending) {
    return (
      <View style={styles.page}>
        <ActivityIndicator />
      </View>
    );
  }

  if (!cart || error) {
    return null; // TODO: error state
  }

  if (cart.state === 'INSERT_REQUESTED') {
    return <Redirect href={`/v2/order/${cart.rbiOrderId}`} />;
  }

  return (
    <>
      <View style={styles.page}>
        {isCheckout ? <CheckoutScreen cart={cart} /> : <CartReviewScreen cart={cart} />}
      </View>
      {showRemoveOfferSelector && (
        <ActionSheet isVisible onClose={onCloseCart}>
          <CartActionRemoveOffer id={id} onClose={onCloseCart} />
        </ActionSheet>
      )}
      {showRemoveItemSelector && (
        <ActionSheet isVisible onClose={onCloseCart}>
          <CartActionRemoveItem id={id} onClose={onCloseCart} />
        </ActionSheet>
      )}
      {showTimeSelector && (
        <ActionSheet isVisible onClose={onCloseCheckout}>
          <CartActionPickupTimeSelector onClose={onCloseCheckout} />
        </ActionSheet>
      )}
      {showServiceModeSelector && (
        <ActionSheet isVisible onClose={onCloseCheckout}>
          service mode
        </ActionSheet>
      )}
      {showPaymentSelector && (
        <ActionSheet isVisible onClose={onCloseCheckout}>
          <PaymentMethodSelection />
        </ActionSheet>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    borderTopColor: tokens.colors.$black10,
    borderTopWidth: 1,
    flex: 1,
  },
});
