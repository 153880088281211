import { IDeliveryFee } from 'generated/graphql-gateway';

export enum ServiceMode {
  CATERING_PICKUP = 'CATERING_PICKUP',
  CATERING_DELIVERY = 'CATERING_DELIVERY',
  CURBSIDE = 'CURBSIDE',
  DELIVERY = 'DELIVERY',
  DRIVE_THRU = 'DRIVE_THRU',
  EAT_IN = 'EAT_IN',
  TAKEOUT = 'TAKEOUT',
  PICKUP_WINDOW = 'PICKUP_WINDOW',
}

export type ServiceModeStatus = {
  [key in ServiceMode]: {
    /**
     * store is available in MDM
     */
    capable: boolean;
    /**
     * store is available if store hours are within service mode availability
     * and the store supports this service mode type
     */
    available: boolean;
    /**
     * state is disabled based on flag from LD, separate from store's availability
     */
    disabled: boolean;
    /**
     * store hours are open within service mode availability
     */
    open?: boolean;
  };
};

export type DeliveryFees = {
  deliveryTotalFee?: number;
  fees?: IDeliveryFee[];
};

export const DELIVERY_DETAILED_FEES = {
  BASE_FEE: 'baseDeliveryFeeCents',
  GEOGRAPHICAL_FEE: 'geographicalFeeCents',
  LONG_DISTANCE_FEE: 'deliverySurchargeFeeCents',
  SERVICE_FEE: 'serviceFeeCents',
  SMALL_CART_FEE: 'smallCartFeeCents',
};

export const SUPPORTED_BREAKDOWN_FEES = [
  DELIVERY_DETAILED_FEES.BASE_FEE,
  DELIVERY_DETAILED_FEES.GEOGRAPHICAL_FEE,
  DELIVERY_DETAILED_FEES.LONG_DISTANCE_FEE,
  DELIVERY_DETAILED_FEES.SERVICE_FEE,
  DELIVERY_DETAILED_FEES.SMALL_CART_FEE,
];

export const FEES_MESSAGE_IDS = {
  [DELIVERY_DETAILED_FEES.BASE_FEE]: 'deliveryFeeBreakdownBase',
  [DELIVERY_DETAILED_FEES.GEOGRAPHICAL_FEE]: 'deliveryFeeBreakdownGeographical',
  [DELIVERY_DETAILED_FEES.LONG_DISTANCE_FEE]: 'deliveryFeeBreakdownLongDistance',
  [DELIVERY_DETAILED_FEES.SERVICE_FEE]: 'deliveryFeeBreakdownService',
  [DELIVERY_DETAILED_FEES.SMALL_CART_FEE]: 'deliveryFeeBreakdownSmallCartFee',
};
