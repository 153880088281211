import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconArrowBack = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg width={size} height={size} viewBox="0 0 21 21" fill="none" color={color} {...otherProps}>
      <Path
        d="M6.644 11.125l4.747 4.747-.891.878-6.25-6.25 6.25-6.25.891.878-4.747 4.747H16.75v1.25H6.644z"
        fill="#01000B"
      />
    </Svg>
  );
};
