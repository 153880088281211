import { create, persist } from '@fhs/zustand';

interface IAuthStore {
  loginJwt: string | null;
  setLoginJwt: (newLoginJwt: string) => void;
  clearLoginJwt: () => void;
}

export const useAuthStore = create<IAuthStore>()(
  persist(
    set => ({
      loginJwt: null,
      setLoginJwt: newLoginJwt => set({ loginJwt: newLoginJwt }),
      clearLoginJwt: () => set({ loginJwt: null }),
    }),
    { name: 'RBI:AUTH_V2_LOGIN_JWT' }
  )
);
