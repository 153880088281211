import {
  getConfigValue,
  sanityDataset as getSanityDataset,
  region,
} from '@fhs-legacy/frontend/src/utils/environment';

export async function fetchQuery(query: string): Promise<Record<string, any>> {
  const sanityProjectId = getConfigValue({ key: 'sanityProjectId' });
  const sanityDataset = `${getSanityDataset()}_${String(region()).toLowerCase()}`;

  const sanityUrl = `https://${sanityProjectId}.api.sanity.io/v1/graphql/${sanityDataset}/default`;

  const data = {
    query,
  };

  const res = await fetch(sanityUrl, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'content-type': 'application/json' },
  }).then(response => response.json());
  if (res.error || !res.data) {
    process.stderr.write(JSON.stringify(res, null, 2));
    process.exit(1);
  }
  return res.data;
}
