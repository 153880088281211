import { ServiceModeLocationProps, ServiceModeLocationType, useMqSelect } from '@fhs/ui';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { useOrderContext } from '@fhs-legacy/frontend/src/state/order';
import { ServiceMode, useServiceModeContext } from '@fhs-legacy/frontend/src/state/service-mode';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';
import { useUIContext } from '@fhs-legacy/frontend/src/state/ui';

function getTypeFromServiceMode(opts: {
  serviceMode?: ServiceMode;
  enableDeliveryFeeOnServiceModeBar?: boolean;
}): ServiceModeLocationType {
  if (!opts.serviceMode) {
    return 'location';
  }

  if (
    opts.serviceMode === ServiceMode.CATERING_DELIVERY ||
    opts.serviceMode === ServiceMode.CATERING_PICKUP
  ) {
    return 'catering';
  }

  if (opts.serviceMode === ServiceMode.DELIVERY) {
    return opts.enableDeliveryFeeOnServiceModeBar ? 'deliveryWithFees' : 'delivery';
  }

  return 'pickup';
}

export function useServiceModeLocation(): Pick<
  ServiceModeLocationProps,
  'type' | 'storeText' | 'serviceModeSubtitle'
> {
  const { serviceMode, deliveryFees } = useServiceModeContext();
  const enableDeliveryFeeOnServiceModeBar = Boolean(
    useFlag(LaunchDarklyFlag.SHOW_DELIVERY_FEE_ON_SERVICE_MODE_BAR)
  );
  const isDelivery = serviceMode === ServiceMode.DELIVERY;

  const { store } = useStoreContext();
  const { deliveryAddress } = useOrderContext();

  const { formatCurrencyForLocale: currencyFormatter } = useUIContext();

  const type = getTypeFromServiceMode({ serviceMode, enableDeliveryFeeOnServiceModeBar });

  const nonDelivery = {
    type,
    serviceModeSubtitle: '',
    storeText: useMqSelect(
      {
        $gteDesktop: store.name,
      },
      [store.name, store.physicalAddress?.address1].filter(Boolean).join(' • ')
    ),
  };

  if (!isDelivery) {
    return nonDelivery;
  }

  return {
    type,
    storeText: deliveryAddress?.addressLine1,
    serviceModeSubtitle:
      enableDeliveryFeeOnServiceModeBar && deliveryFees?.deliveryTotalFee
        ? `${currencyFormatter(deliveryFees.deliveryTotalFee)} Delivery Fee (Per Order)}`
        : '',
  };
}
