import { format, isToday, isTomorrow } from 'date-fns';
import { Link, router } from 'expo-router';
import { useCallback } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import { Schema } from '@fhs/backend';
import {
  Button,
  Checkbox,
  IconChevronRight,
  IconDineIn,
  IconInfoFill,
  IconStore,
  IconTime,
  Text,
  tokens,
} from '@fhs/ui';

type Props = {
  store: Schema['CartStore']['type'];
  orderTime?: string | null;
};

export function CheckoutPickupDetails(props: Props) {
  const formatOrderTime = useCallback(orderTime => {
    const date = new Date(orderTime ?? Date.now());
    const time = orderTime ? format(date, 'h:mm a') : 'ASAP';
    if (isToday(date)) {
      return `Today - ${time}`;
    } else if (isTomorrow(date)) {
      return `Tomorrow - ${time}`;
    } else {
      return `${format(date, 'eee')} - ${time}`;
    }
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.sectionBorder}>
        <View style={styles.section}>
          <View style={styles.iconCircle}>
            <IconStore size={24} />
          </View>
          <View style={styles.flex1}>
            <Text.Ui size="xs">Pick up at:</Text.Ui>
            <Text.Heading numberOfLines={1} type="three">
              {props.store.name}
              Mandarin
            </Text.Heading>
            <Text.Ui numberOfLines={1} size="sm">
              {[props.store.addressLine1, props.store.city, props.store.state].join(', ')}
            </Text.Ui>
          </View>
          <View>
            <Link href="/store-locator?back=/v2/cart/(checkout)" asChild>
              <Text.Ui style={styles.link} size="sm">
                Change
              </Text.Ui>
            </Link>
          </View>
        </View>

        <View style={styles.buttonContainer}>
          <Button type="ghost" style={[styles.button, styles.activeButton]}>
            <Button.Icon color="white">
              <IconStore />
            </Button.Icon>
            <Button.Text style={styles.buttonActiveText}>Pick Up</Button.Text>
          </Button>

          <Button type="outline" style={styles.button}>
            <Button.Icon color="black">
              <IconDineIn />
            </Button.Icon>
            <Button.Text style={styles.buttonText}>Dine In</Button.Text>
          </Button>
        </View>

        <View style={styles.locationCalloutContainer}>
          <IconInfoFill />
          <Text.Ui size="xs">No refunds for orders to the wrong location</Text.Ui>
        </View>
      </View>

      <Pressable
        style={[styles.linkSection, styles.sectionBorder]}
        onPress={() => router.setParams({ action: 'time' })}
      >
        {({ pressed }) => (
          <View style={[styles.section, pressed && styles.activeSection]}>
            <View style={[styles.iconCircle, pressed && styles.iconCirclePressed]}>
              <IconTime size={24} />
            </View>

            <View style={styles.flex1}>
              <Text.Ui size="xs">Pick up time:</Text.Ui>
              <Text.Ui style={styles.marginBottom4} size="md" weight="bold">
                {formatOrderTime(props.orderTime)}
              </Text.Ui>
            </View>

            <View style={styles.timeAndArrow}>
              <IconChevronRight size={24} />
            </View>
          </View>
        )}
      </Pressable>
      <Checkbox onChange={() => {}} checked={false} label="Include Napkins & Utensils?">
        <Checkbox.Pressable focusable={false} style={styles.linkSection}>
          {({ pressed }) => (
            <View style={[styles.section, pressed && styles.activeSection]}>
              <View style={[styles.iconCircle, pressed && styles.iconCirclePressed]}>
                <IconDineIn size={24} />
              </View>

              <View style={styles.flex1}>
                <Text.Ui size="md" weight="bold">
                  Include Napkins & Utensils?
                </Text.Ui>
                <Text.Ui size="xs">Items included depend on your order</Text.Ui>
              </View>

              <Checkbox.Control />
            </View>
          )}
        </Checkbox.Pressable>
      </Checkbox>
    </View>
  );
}

const styles = StyleSheet.create({
  flex1: { flex: 1 },
  buttonText: {
    color: tokens.colors.$black,
  },
  buttonActiveText: {
    color: tokens.colors.$white,
  },
  button: {
    flex: 1,
    backgroundColor: tokens.colors.$white,
    borderColor: tokens.colors.$black10,
  },
  activeButton: {
    backgroundColor: tokens.colors.$black,
  },
  timeAndArrow: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 8,
  },
  activeSection: {
    backgroundColor: tokens.colors.$houseLight,
  },
  linkSection: {
    width: '100%',
  },
  section: {
    width: '100%',
    gap: 8,
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  sectionBorder: {
    width: '100%',
    borderBottomColor: tokens.colors.$black10,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  locationCalloutContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    marginHorizontal: 16,
    marginBottom: 12,
    paddingVertical: 12,
    paddingHorizontal: 16,
    backgroundColor: tokens.colors.$houseLight,
    flex: 1,
    borderRadius: 8,
  },
  link: {
    textDecorationLine: 'underline',
  },
  iconCirclePressed: {
    backgroundColor: tokens.colors.$black10,
  },
  iconCircle: {
    height: 40,
    width: 40,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colors.$houseLight,
  },
  marginBottom4: {
    marginBottom: 4,
  },
  container: {
    alignItems: 'center',
    width: '100%',
    borderColor: tokens.colors.$black10,
    backgroundColor: tokens.colors.$white,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 8,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
});
