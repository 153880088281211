import { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { IconChevronLeft, IconClose, ItemStepper, StepProps } from '@fhs/ui';

import { useIsItemInCart, useOfferCart } from '../../../state/offer-guide-cart-context';

interface NavigationProps {
  onPressPrev: () => void;
  onPressStep: (index: number) => void;
  onClose: () => void;
}

export function Navigation({ onPressPrev, onPressStep, onClose }: NavigationProps) {
  const { steps: offerSteps, currentStepIndex } = useOfferCart();
  const isItemInCart = useIsItemInCart();

  const steps = useMemo(
    () =>
      offerSteps.map<StepProps>((offerStep, index) => ({
        image: offerStep?.selectedEntry?.image?.asset?.url,
        isActive: currentStepIndex === index,
      })),
    [offerSteps, currentStepIndex]
  );

  return (
    <View style={styles.navigation}>
      <TouchableOpacity style={{ justifyContent: 'center' }} onPress={onPressPrev}>
        <IconChevronLeft size={24} />
      </TouchableOpacity>
      <View style={styles.itemStepperContainer}>
        {!isItemInCart && <ItemStepper steps={steps} onStepClick={onPressStep} />}
      </View>
      <TouchableOpacity style={{ justifyContent: 'center' }} onPress={onClose}>
        <IconClose size={24} />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  itemStepperContainer: {
    alignItems: 'center',
    flexGrow: 1,
  },
  navigation: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
});
