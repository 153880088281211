import { Link } from 'expo-router';
import { useIntl } from 'react-intl';
import { Platform, StyleSheet } from 'react-native';

import { Text, YStack } from '@fhs/ui';
import { IDeliveryFee } from 'generated/graphql-gateway';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useUIContext } from 'state/ui';

import { DELIVERY_DETAILED_FEES, FEES_MESSAGE_IDS, SUPPORTED_BREAKDOWN_FEES } from './types';

type DeliveryFeeModalProps = {
  deliveryTotalFee?: number;
  deliveryFees: IDeliveryFee[];
  onTermsRedirect: () => void;
};

export const DeliveryFeeDialogContent = ({
  deliveryTotalFee = 0,
  deliveryFees,
  onTermsRedirect,
}: DeliveryFeeModalProps) => {
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale: currencyFormatter } = useUIContext();

  const enableDeliveryFeesBreakdown = Boolean(
    useFlag(LaunchDarklyFlag.SHOW_DELIVERY_FEES_BREAKDOWN_ON_MODAL)
  );

  const breakdownFees = deliveryFees?.filter(fee => SUPPORTED_BREAKDOWN_FEES.includes(fee.name));

  // We just want to show the breakdown fees if we have more fees other than the LONG_DISTANCE_FEE
  const showFeeBreakdown =
    enableDeliveryFeesBreakdown &&
    breakdownFees?.some(
      fee => fee.name !== DELIVERY_DETAILED_FEES.LONG_DISTANCE_FEE && fee.itemValueCents > 0
    );

  const handleRedirectCallback = () => {
    // For iOS, we don't want to close the modal when redirecting to terms page
    // because the full screen modal navigation behavior is different
    if (Platform.OS === 'ios') {
      return;
    }

    onTermsRedirect();
  };

  const renderFee = (fee: IDeliveryFee) => {
    // We just want to show the Base Fee + the fees with value
    if (fee.name !== DELIVERY_DETAILED_FEES.BASE_FEE && fee.totalValueCents === 0) {
      return;
    }

    return (
      <Text.Paragraph size="sm" style={styles.textItem}>
        {formatMessage(
          { id: FEES_MESSAGE_IDS[fee.name] },
          { fee: currencyFormatter(fee.totalValueCents) }
        )}
      </Text.Paragraph>
    );
  };

  return (
    <YStack>
      <Text.Paragraph size="sm" style={styles.textItem}>
        {formatMessage(
          { id: 'deliveryFeeMessage' },
          {
            feeSurcharge: <Text weight="semibold">{currencyFormatter(deliveryTotalFee)}</Text>,
          }
        )}
      </Text.Paragraph>
      {showFeeBreakdown && (
        <YStack style={styles.feeBreakdown}>
          <Text.Paragraph size="sm" style={styles.textItem}>
            {formatMessage({ id: 'deliveryFeeBreakdown' })}
          </Text.Paragraph>
          {breakdownFees.map(fee => renderFee(fee))}
        </YStack>
      )}
      <Text.Paragraph size="sm" style={styles.textItem}>
        {formatMessage(
          { id: 'deliveryFeeTermsLink' },
          {
            link: (
              <Link
                href={formatMessage({ id: 'routes.deliveryTerms' })}
                onPress={handleRedirectCallback}
                style={styles.link}
              >
                {formatMessage({ id: 'clickHere' })}
              </Link>
            ),
          }
        )}
      </Text.Paragraph>
      <Text.Paragraph size="sm" style={styles.textItem}>
        {formatMessage({ id: 'deliveryFeeTerms' })}
      </Text.Paragraph>
    </YStack>
  );
};

const styles = StyleSheet.create({
  feeBreakdown: {
    marginVertical: 18,
  },
  textItem: {
    lineHeight: 24,
  },
  link: {
    textDecorationLine: 'underline',
  },
});
