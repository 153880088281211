import { Image, ImageBackground, ImageStyle } from 'expo-image';
import { StyleProp, View } from 'react-native';

import { createMqStyles, useMqSelect } from '@fhs/ui';

import { CtaButton } from '../shared/cta-button';

export function FrenchDipCard() {
  const mqStyles = useMqStyles();
  const backgroundSource = useMqSelect(
    {
      $gteDesktopLg: require('./french-dip-card-background-desktop.webp'),
    },
    require('./french-dip-card-background-tablet.webp')
  );
  return (
    <ImageBackground
      alt={'New French Dip. Premium Cut Roast Beef'}
      source={backgroundSource}
      placeholder={{ thumbhash: 'LzkOG4aPlpZqeXeIcIYIWIk' }}
      style={mqStyles.background}
    >
      <View style={mqStyles.contentContainer}>
        <Image
          source={require('./french-dip-card-logo.webp')}
          alt="French Dip"
          placeholder={{ thumbhash: '0aiGCogUR5h5hn9Id4909weXeoRyk8c' }}
          style={mqStyles.logo as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        {/*  */}
        <CtaButton
          href={'/menu/section-b95bc971-3838-495c-9c8f-93183e68a539'}
          style={mqStyles.ctaContainer}
        />
      </View>
    </ImageBackground>
  );
}

const useMqStyles = createMqStyles({
  background: {
    $base: {
      aspectRatio: 1024 / 505,
    },
    $gteDesktopLg: {
      aspectRatio: 1920 / 757,
    },
  },
  contentContainer: {
    $base: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '41%',
      top: '16%',
      left: '8%',
    },
    $gteDesktopLg: {
      width: '50%',
      top: '20%',
      left: '3%',
    },
  },
  logo: {
    $base: {
      aspectRatio: 422 / 103,
      width: '100%',
    },
  },
  ctaContainer: {
    $base: {
      marginTop: 64,
    },
  },
});
