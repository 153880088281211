import { useIntl } from 'react-intl';
import { View } from 'react-native';

import {
  Button,
  IconArrowBack,
  IconArrowFoward,
  IconChevronRight,
  MqSwitch,
  Text,
  createMqStyles,
  tokens,
  useMqSelect,
} from '@fhs/ui';

interface Props {
  onPressSeeAll: () => void;
  nextCarouselPage: () => void;
  prevCarouselPage: () => void;
}

export function OffersSectionHeader({ onPressSeeAll, nextCarouselPage, prevCarouselPage }: Props) {
  const mqStyles = useMqStyles();
  const { formatMessage } = useIntl();

  return (
    <View style={mqStyles.container}>
      <Text.Heading type={useMqSelect({ $gteDesktop: 'one' }, 'three')}>
        {formatMessage({ id: 'offers' })}
      </Text.Heading>
      <MqSwitch
        $base={() => (
          <Button type="ghost" size="sm" onPress={onPressSeeAll}>
            <Button.Text>{formatMessage({ id: 'seeAll' })}</Button.Text>
            <Button.Icon>
              <IconChevronRight />
            </Button.Icon>
          </Button>
        )}
        $gteDesktop={() => (
          <View style={mqStyles.carouselButtonsContainer}>
            <Button
              style={mqStyles.carouselButton}
              type="ghost"
              size="sm"
              onPress={prevCarouselPage}
            >
              <Button.Icon>
                <IconArrowBack />
              </Button.Icon>
            </Button>

            <Button
              style={mqStyles.carouselButton}
              type="ghost"
              size="sm"
              onPress={nextCarouselPage}
            >
              <Button.Icon>
                <IconArrowFoward />
              </Button.Icon>
            </Button>
          </View>
        )}
      />
    </View>
  );
}

const useMqStyles = createMqStyles({
  container: {
    $base: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      paddingTop: 16,
    },
    $gteDesktop: {
      paddingTop: 32,
    },
  },
  carouselButtonsContainer: {
    $base: {
      flexDirection: 'row',
      gap: 16,
    },
  },
  carouselButton: {
    $base: {
      borderColor: tokens.colors.$blackOpacity10,
      justifyContent: 'space-around',
    },
  },
});
