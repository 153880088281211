import React, { FC } from 'react';

import { MqSwitch } from '@fhs/ui';
import { StickyFooter } from 'components/sticky-footer/sticky-footer';

import {
  StyledProductCtaWrapper,
  StyledProductCtaWrapperChild,
  footerWrapperProps,
} from './product-cta.styled';
import { IProductCtaWrapperProps } from './types';

// Wrapper for the CTA buttons depending on the device
export const ProductCtaWrapper: FC<React.PropsWithChildren<IProductCtaWrapperProps>> = ({
  elements,
}) => {
  const elementsToRender = elements.filter(({ element }) => element);

  return (
    <MqSwitch
      $gteDesktop={() => (
        <StyledProductCtaWrapper {...footerWrapperProps}>
          {elementsToRender.map(({ element }, index) => (
            <StyledProductCtaWrapperChild key={index}>{element}</StyledProductCtaWrapperChild>
          ))}
        </StyledProductCtaWrapper>
      )}
      $ltDesktop={() => <StickyFooter boxProps={footerWrapperProps} elements={elementsToRender} />}
    />
  );
};
