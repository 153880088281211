import { StyleSheet, View } from 'react-native';

import { Text, XStack, YStack, tokens } from '@fhs/ui';

const TableHeader = ({ tableTitle }) => {
  return (
    <Text.Heading type="five" style={styles.tableTitle}>
      {tableTitle}
    </Text.Heading>
  );
};

const TableRow = ({ displayName, displayValue }) => {
  return (
    <XStack style={styles.tableRow}>
      <Text>{displayName}</Text>
      <Text>{displayValue}</Text>
    </XStack>
  );
};

type FactsProps = {
  facts: {
    key: string;
    displayName: string;
    displayValue: string;
  }[];
};

export const NutritionTable = ({ facts }: FactsProps) => {
  return (
    <YStack style={styles.tableContainer}>
      <TableHeader tableTitle="Per Serving" />
      <View style={styles.divider} />
      <YStack style={styles.tableContent}>
        {facts.map(fact => (
          <TableRow
            key={fact.key}
            displayName={fact.displayName}
            displayValue={fact.displayValue}
          />
        ))}
      </YStack>
    </YStack>
  );
};

const styles = StyleSheet.create({
  tableContainer: {
    gap: 12,
  },
  tableTitle: {
    fontWeight: '600',
    fontSize: 14,
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: tokens.colors.$black10,
  },
  tableContent: {
    gap: 8,
  },
  tableRow: {
    justifyContent: 'space-between',
  },
});
