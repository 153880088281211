import { Image } from 'expo-image';
import { StyleSheet, View } from 'react-native';

import { Text } from '@fhs/ui';

import type { ImageAssetWithAltText } from '../../../types';

import { NutritionTable } from './nutrition-table';

export type NutritionFactsProps = {
  title: string;
  image: ImageAssetWithAltText;
  facts: {
    key: string;
    displayName: string;
    displayValue: string;
  }[];
};

export const NutritionFacts = ({ title, image, facts }: NutritionFactsProps) => {
  return (
    <View>
      <Image
        source={{ uri: image.asset.uri }}
        contentFit="contain"
        style={styles.image}
        alt={image.altText}
      />
      <Text.Heading type="two" style={styles.textTitle}>
        {title}
      </Text.Heading>
      <NutritionTable facts={facts} />
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    height: 120,
    width: '100%',
    alignSelf: 'center',
    marginTop: 24,
  },
  textTitle: {
    alignSelf: 'center',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 18,
    marginVertical: 16,
    justifyContent: 'center',
  },
});
