import { type FlashList } from '@shopify/flash-list';
import { type RefObject, createContext, createRef, useContext } from 'react';

import type { FlashListData, FlashListDatum, SectionType, TileSizeType } from './types';

export type AnchorSectionListContextType = {
  size: TileSizeType;
  activeSectionId: string;
  setActiveSectionId: (sectionId: string) => void;
  manualActiveSectionId: string;
  setManualActiveSectionId: (sectionId: string) => void;
  sections: Array<SectionType>;
  flashListRef: RefObject<FlashList<FlashListDatum>>;
  flashListData: FlashListData;
};

export const anchorSectionListContext = createContext(<AnchorSectionListContextType>{
  size: 'lg',
  activeSectionId: '',
  setActiveSectionId: () => {},
  manualActiveSectionId: '',
  setManualActiveSectionId: () => {},
  sections: [],
  flashListRef: createRef(),
  flashListData: [],
});

export const useAnchorSectionList = () => useContext(anchorSectionListContext);
