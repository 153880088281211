import { Stack } from 'expo-router';

import { getScreenTitleHeader } from '../../../../../components/get-screen-title-header';

export default function SlugLayout() {
  return (
    <Stack>
      <Stack.Screen name="index" options={{ header: getScreenTitleHeader(), title: '' }} />
      <Stack.Screen name="customize" options={{ headerShown: false }} />
    </Stack>
  );
}
