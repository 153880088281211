import { Redirect, useLocalSearchParams } from 'expo-router';
import { useCallback, useEffect, useState } from 'react';
import { StyleSheet, TextInput, View } from 'react-native';

import { type Schema, client } from '@fhs/client';
import { Button, Text, XStack, YStack } from '@fhs/ui';
import { useLocale } from '@fhs-legacy/frontend/src/state/intl';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { isSSG } from '@fhs-legacy/frontend/src/utils/environment';

export default function Screen() {
  const { region } = useLocale();

  const enableSimplifiedMenuBeta = useFlag<boolean>(LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA);
  const [menuData, setMenuData] = useState<Schema['Menu']['type'][]>([]);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const { restaurantId: restaurantIdParam } = useLocalSearchParams<{
    restaurantId: string;
  }>();
  const [restaurantId, setRestaurantId] = useState<string>(restaurantIdParam);

  useEffect(() => {
    const sub = client.models.Menu.observeQuery().subscribe({
      next: ({ items }) => {
        setMenuData([...items]);
      },
    });

    return () => sub.unsubscribe();
  }, []);

  const onSyncMenu = useCallback(async () => {
    setIsSyncing(true);
    await client.mutations.legacyMenuSync(
      { restaurantId, serviceMode: 'pickup', region: region.toLowerCase() },
      {
        authMode: 'apiKey',
      }
    );
    setIsSyncing(false);
  }, [restaurantId, region]);

  if (!enableSimplifiedMenuBeta && !isSSG) {
    return <Redirect href="/menu" />;
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Text.Heading type="one">Menu Sync</Text.Heading>
        <Text.Paragraph size="lg">Demonstrates the ability to sync the menu data</Text.Paragraph>
      </View>
      <View style={styles.controls}>
        <Text.Heading type="three">Store Number:</Text.Heading>
        <TextInput onChangeText={setRestaurantId} value={restaurantId} style={styles.input} />
        <Button disabled={isSyncing} onPress={onSyncMenu}>
          <Button.Text>{isSyncing ? 'Syncing...' : 'Sync Menu'}</Button.Text>
        </Button>
      </View>
      <YStack style={styles.tableContainer}>
        <XStack style={styles.tableHeader}>
          <Text.Paragraph style={styles.tableCell} size="lg">
            ID
          </Text.Paragraph>
          <Text.Paragraph style={styles.tableCell} size="lg">
            Region
          </Text.Paragraph>
          <Text.Paragraph style={styles.tableCell} size="lg">
            Created At
          </Text.Paragraph>
          <Text.Paragraph style={styles.tableCell} size="lg">
            Updated At
          </Text.Paragraph>
        </XStack>
        {menuData.map(menuItem => (
          <XStack key={menuItem.restaurantId} style={styles.tableRow}>
            <Text.Paragraph style={styles.tableCell} size="sm">
              {menuItem.restaurantId}
            </Text.Paragraph>
            <Text.Paragraph style={styles.tableCell} size="sm">
              {menuItem.region}
            </Text.Paragraph>
            <Text.Paragraph style={styles.tableCell} size="sm">
              {menuItem?.metadata?.availabilityLastUpdatedAt}
            </Text.Paragraph>
            <Text.Paragraph style={styles.tableCell} size="sm">
              {menuItem.metadata?.menuLastUpdatedAt}
            </Text.Paragraph>
          </XStack>
        ))}
      </YStack>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    // backgroundColor: '#fff',
    maxWidth: 600,
  },
  headerContainer: {
    paddingVertical: 16,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 16,
    backgroundColor: '#f0f0f0',
  },
  tableContainer: {},
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
  },
  tableCell: {
    flex: 1,
    padding: 8,
  },
  controls: {
    padding: 16,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    backgroundColor: '#fff',
    padding: 8,
    marginBottom: 16,
  },
});
