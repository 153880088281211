import { router } from 'expo-router';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useState } from 'react';

import { useAuthStore } from '../state';
import { stripPhoneNumber } from '../utils';

import { cognitoSignIn } from './cognito';
import {
  AuthIdentifiers,
  AuthOptInType,
  AuthV2ChallengeType,
  ValidatedIdentifiers,
  useCreateOtpv2Mutation,
  useSignUpV2Mutation,
  useValidateOtpV2Mutation,
} from './types';
import { CreateOtpInput, IdentifyAuthJwt, LoginJwt } from './types';

export const useAuthV2 = () => {
  const { loginJwt, setLoginJwt, clearLoginJwt } = useAuthStore();
  const [showIdentifyPopup, setShowIdentifyPopup] = useState<boolean>(false);
  const [identifyAuthJwts, setIdentifyAuthJwts] = useState<IdentifyAuthJwt[]>([]);
  //Stub implementation for now, this will eventually need to pull from Amplitude
  function getDeviceUid(): string {
    return 'some-device-id-here';
  }

  const onValidateOtp = useCallback(
    (data, _clientOptions) => {
      setLoginJwt(data.validateOtpV2.loginJwt);
      return data;
    },
    [setLoginJwt]
  );
  const onCreateOtp = useCallback(
    (data, _clientOptions) => {
      //Persist login JWT
      setLoginJwt(data.createOtpV2.loginJwt);
      //Push user to verify page
      router.push('/v2/signin/(verify)');
      return data;
    },
    [setLoginJwt]
  );
  const onSignUp = useCallback(
    (data, _clientOptions) => {
      //Persist login JWT
      setLoginJwt(data.signUpV2.loginJwt);
      return data.signUpV2.loginJwt;
    },
    [setLoginJwt]
  );
  const [internalCreateOtp] = useCreateOtpv2Mutation({ onCompleted: onCreateOtp });
  const [internalValidateOtp] = useValidateOtpV2Mutation({ onCompleted: onValidateOtp });
  const [internalSignUp] = useSignUpV2Mutation({ onCompleted: onSignUp });
  const createOtp = useCallback(
    async (partialInput: CreateOtpInput) => {
      await internalCreateOtp({
        variables: {
          input: {
            deviceId: getDeviceUid(),
            ...{ loginJwt: loginJwt || undefined },
            ...partialInput,
          },
        },
      });
    },
    [internalCreateOtp, loginJwt]
  );
  ////////////////////////////////////////////////////////////
  // Confirm Login
  ////////////////////////////////////////////////////////////
  const validateOtp = useCallback(
    async (otpInput: string) => {
      if (loginJwt) {
        const response = await internalValidateOtp({
          variables: { input: { otpInput, loginJwt } },
        });
        const validateResult = response.data.validateOtpV2;
        if (validateResult) {
          switch (validateResult.challengeType) {
            case AuthV2ChallengeType.SIGN_IN_CHALLENGE: {
              const { challengeCode, cognitoId, sessionId } = validateResult.challenge;
              //Authenticate with Cognito.
              const session = await cognitoSignIn(cognitoId, sessionId, challengeCode);
              //Validate the user is authenticated here
              if (session) {
                //Route to home page,
                router.push('/');
                clearLoginJwt();
                //Clear the login state
              }
              break;
            }
            case AuthV2ChallengeType.SIGN_UP_CHALLENGE: {
              router.push('/v2/signin/(signup)');
              break;
            }
            case AuthV2ChallengeType.IDENTIFY_CHALLENGE: {
              //Render select account popup
              const { authJwts } = validateResult.challenge;
              setIdentifyAuthJwts(
                authJwts?.map(jwt => {
                  return {
                    ...jwtDecode<IdentifyAuthJwt>(jwt),
                    jwt,
                  };
                }) ?? []
              );
              setShowIdentifyPopup(true);
              break;
            }
          }
        } else {
          throw Error('Validate OTP Error');
        }
      }
    },
    [internalValidateOtp, clearLoginJwt, loginJwt]
  );
  ////////////////////////////////////////////////////////////
  // Sign Up
  ////////////////////////////////////////////////////////////
  const signUp = useCallback(
    async (name, email, phoneNumber, dob, wantsPromotionalEmail) => {
      const response = await internalSignUp({
        variables: {
          input: {
            name,
            loginJwt: loginJwt!,
            email,
            phoneNumber: stripPhoneNumber(phoneNumber),
            dob,
            optIns: [
              {
                identifier: 'EMAIL_MARKETING' as unknown as AuthOptInType,
                optInStatus: wantsPromotionalEmail,
              },
            ],
          },
        },
      });
      const signUpResult = response.data.signUpV2;
      if (signUpResult) {
        switch (signUpResult.challengeType) {
          case AuthV2ChallengeType.SIGN_IN_CHALLENGE: {
            const { challengeCode, cognitoId, sessionId } = signUpResult.challenge;
            //Authenticate with Cognito.
            const session = await cognitoSignIn(cognitoId, sessionId, challengeCode);
            //Validate the user is authenticated here
            if (session) {
              //Push to home page
              router.push('/');
              //Clear the login state
              clearLoginJwt();
            }
            break;
          }
          case AuthV2ChallengeType.SIGN_UP_CHALLENGE:
          case AuthV2ChallengeType.IDENTIFY_CHALLENGE: {
            throw Error("Something went wrong, these aren't valid states");
          }
        }
      }
    },
    [clearLoginJwt, internalSignUp, loginJwt]
  );
  const getJwtIdentifier = useCallback(() => {
    if (loginJwt) {
      return jwtDecode<LoginJwt>(loginJwt);
    }
    //Return a dummy JWT.
    return {
      loginIdentifier: '',
      identifierType: AuthIdentifiers.EMAIL,
      validatedIdentifiers: {} as ValidatedIdentifiers,
    };
  }, [loginJwt]);
  return {
    //Mutations
    createOtp,
    validateOtp,
    signUp,
    //Auth State
    identifyAuthJwts,
    setIdentifyAuthJwts,
    showIdentifyPopup,
    setShowIdentifyPopup,
    getJwtIdentifier,
    clearLoginJwt,
  };
};
export * from './types';
