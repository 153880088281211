import { useCallback } from 'react';

import { OfferDetail } from '@fhs/backend/amplify/functions/_temp/graphql/API';
import { addOfferToCart } from '@fhs/cart';
import { useMutation } from '@fhs/client';
import { IIncentiveSelection } from '@fhs-legacy/frontend/src/generated/graphql-gateway';
import { OfferType } from '@fhs-legacy/frontend/src/generated/rbi-graphql';
import { actions, useAppDispatch } from '@fhs-legacy/frontend/src/state/global-state';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { LoyaltyOffer } from '@fhs-legacy/frontend/src/state/loyalty/types';
import { createTextBlock } from '@fhs-legacy/frontend/src/utils/sanity';
import { useToast } from '@fhs-legacy/universal-components';

import { LoyaltyCmsOfferV2, LoyaltyCmsOfferV2Incentive, PricingStrategyEnum } from '../types';

// TODO: This type is a hack, we need to sync between all screens and types from amplify to have the type consistent
export type Offer = Pick<
  OfferDetail,
  | 'id'
  | 'cmsId'
  | 'description'
  | 'incentiveType'
  | 'name'
  | 'pricingStrategy'
  | 'requireGuideFlow'
  | 'discountFees'
> & { isAvailable?: boolean | null };

export function useAddIncentiveToCart() {
  const { mutateAsync } = useMutation(addOfferToCart);
  const dispatch = useAppDispatch();
  const useSimplyBetterCart = useFlag(
    LaunchDarklyFlag.ENABLE_SIMPLY_BETTER_CART_SERVICE_OFFER_INTEGRATION
  );

  const toast = useToast({
    placement: 'bottom',
  });

  const addIncentiveToCartService = useCallback(
    async (offer: Offer) => {
      if (!offer || !offer.id) {
        return;
      }
      return mutateAsync({ offerId: offer.id, entries: [] });
    },
    [mutateAsync]
  );

  const addIncentiveToCart = useCallback(
    async (offer: Offer, selections?: IIncentiveSelection[], showMessage?: boolean) => {
      if (!offer.pricingStrategy) {
        return;
      }
      const {
        cmsId,
        description,
        id,
        incentiveType,
        name,
        pricingStrategy: pricingStrategyDetails,
        discountFees,
      } = offer;
      const { pricingStrategy, type, value } = pricingStrategyDetails;

      const incentives: LoyaltyCmsOfferV2Incentive[] =
        pricingStrategy === PricingStrategyEnum.DISCOUNT && type && value
          ? [
              {
                discountType: type,
                discountValue: value,
                _type: pricingStrategy,
                incentiveType,
                discountFees,
              },
            ]
          : [];

      const mapOfferV2ToV1: LoyaltyCmsOfferV2 = {
        __typename: 'SystemwideOfferV2',
        _type: 'simplyOffer',
        _id: id,
        description: { localeRaw: createTextBlock([{ text: description, marks: [] }]) },
        id: id,
        incentives,
        loyaltyEngineId: id,
        name: { localeRaw: createTextBlock([{ text: name, marks: [] }]) },
      };
      // casting to minimize changes on all V1 files
      dispatch(actions.loyalty.setCmsOffersV2([mapOfferV2ToV1 as unknown as LoyaltyOffer]));

      dispatch(
        actions.loyalty.selectedOfferV2({
          offer: mapOfferV2ToV1 as unknown as LoyaltyOffer,
          selections,
        })
      );
      dispatch(
        actions.loyalty.setAppliedOffers([
          {
            id: mapOfferV2ToV1.loyaltyEngineId,
            type: OfferType.GLOBAL,
            cartId: 'discount-offer',
            isStackable: false,
            isSurprise: false,
            cmsId,
          },
        ])
      );
      if (showMessage) {
        toast.show({
          text: 'Offer Successfully Applied!',
          variant: 'positive',
        });
      }
    },

    [dispatch, toast]
  );
  return {
    addIncentiveToCart: useSimplyBetterCart ? addIncentiveToCartService : addIncentiveToCart,
  };
}
