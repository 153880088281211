import { type ReactNode } from 'react';
import { StyleSheet } from 'react-native';

import { Text, YStack, type YStackProps } from '@fhs/ui';

export type ListItemGroupSectionProps = YStackProps & {
  heading: ReactNode;
};

export function ListItemGroupSection({
  children,
  heading,
  ...yStackProps
}: ListItemGroupSectionProps) {
  return (
    <YStack {...yStackProps} style={[styles.section, yStackProps.style]}>
      {typeof heading === 'string' ? <Text.Heading type="four">{heading}</Text.Heading> : heading}
      {children}
    </YStack>
  );
}

const styles = StyleSheet.create({
  section: {
    gap: 12,
  },
});
