import React, { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { useRedeemReward } from 'components/cart-item/redeem-reward/use-redeem-reward';
import ItemUnavailableMessage from 'components/item-unavailable-message';
import { SelectedStoreAndServiceModeButton } from 'components/selected-store-and-service-mode-button';
import { NumberInput } from 'components/ucl';
import { useIsMobileBp } from 'hooks/breakpoints';
import { useProductPrice } from 'hooks/menu/use-product-price';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { ClickEventComponentNames } from 'state/crm-events/constants';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useMenuContext } from 'state/menu';
import { useOrderContext } from 'state/order';
import { useProductWizardContext } from 'state/product-wizard';
import { useEditingCartEntry } from 'state/product-wizard/hooks/use-editing-cart-entry';
import useProductWizardFlow from 'state/product-wizard/hooks/use-product-wizard-flow';
import { useServiceModeContext } from 'state/service-mode';
import { MIN_CART_QUANTITY, getMenuObjectCartQuantity } from 'utils/cart';
import { MENU_PRODUCT_PAGE_PRIMARY_ACTION } from 'utils/test-ids';

import { useProductAvailability } from '../use-product-availability';
import { useProductCart } from '../use-product-cart';

import {
  EnsureOfferIsAddedDialog,
  EnsureOfferIsAddedDialogRef,
} from './ensure-offer-is-added-dialog';
import { ProductCtaWrapper } from './product-cta-wrapper';
import {
  HomeCTAContainer,
  NumberInputWrapper,
  displayValueStyleProps,
  incrementorStyleProps,
} from './product-cta.styled';

export const ProductHomeCta: React.FC<React.PropsWithChildren<unknown>> = () => {
  const ensureOfferRef = useRef<EnsureOfferIsAddedDialogRef>({ disable: () => {} });
  const { isOffer } = useMenuContext();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser } = useLoyaltyUser();
  const { cartEntries } = useOrderContext();
  const {
    productQuantity,
    selectedProduct,
    setProductQuantity,
    isSimplyOffer,
    addItemToSimplyOfferCart,
  } = useProductWizardContext();
  const enableServiceModeBarForDelivery = useFlag(
    LaunchDarklyFlag.ENABLE_SERVICE_BAR_ON_PRODUCT_DETAIL_SCREEN_FOR_DELIVERY
  );

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { addToOrder, computeCartEntriesForCart } = useProductCart();
  const editingCartEntry = useEditingCartEntry();
  const cartIdEditing = editingCartEntry?.cartId;

  const { totalPrice, formattedProductPrice } = useProductPrice({
    shouldIncludeModifications: true,
    shouldIncludeQuantity: true,
  });

  const appliedLoyaltyRewardsArray = useAppSelector(
    selectors.loyalty.selectAppliedLoyaltyRewardsArray
  );
  const appliedLoyaltyOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);

  const { isDelivery } = useServiceModeContext();
  const isMobile = useIsMobileBp();

  const hasIncentiveInCart =
    appliedLoyaltyOffers.length > 0 ||
    (appliedLoyaltyRewardsArray && appliedLoyaltyRewardsArray.length > 0);

  const { dayParts, isItemAvailableForDaypart, itemDayParts } = useProductAvailability();

  const { incentiveNotInMenu, limitPerOrderMet, rewardPointCost } = useRedeemReward();
  const { rewardBenefitId } = useProductWizardFlow();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const stagedCartPoints = useAppSelector(selectors.loyalty.selectStagedCartPoints);

  const { availableCartQuantity, maxCartQuantityMet: isLimitReached } = getMenuObjectCartQuantity({
    menuObject: selectedProduct,
    quantityToAdd: productQuantity,
    cartEntries,
    cartIdEditing,
  });
  const maxCartQuantityMet = isLimitReached && !editingCartEntry;
  const { logRBIEvent } = useCRMEventsContext();

  const handleQuantityUpdate = useCallback(
    (value: number) => {
      const rewardApplied = appliedLoyaltyRewards[cartIdEditing];
      if (value < productQuantity && rewardApplied?.timesApplied > 1) {
        dispatch(
          actions.loyalty.unApplyReward({
            rewardBenefitId: rewardApplied.rewardId,
            cartId: cartIdEditing,
            loyaltyUser,
          })
        );
      }
      setProductQuantity(value);

      logRBIEvent({
        name: CustomEventNames.CLICK_EVENT,
        type: EventTypes.Other,
        attributes: {
          component: ClickEventComponentNames.BUTTON,
          text: 'Product Incrementor',
        },
      });
    },
    [
      appliedLoyaltyRewards,
      cartIdEditing,
      dispatch,
      logRBIEvent,
      loyaltyUser,
      productQuantity,
      setProductQuantity,
    ]
  );

  // If a loyalty incentive (offer, reward) is not available as a regular menu item,
  // the incrementor also increments incentive redemptions in addition to adjusting quantity.
  // This takes into account user point and determines whether there are
  // sufficient points for further redemptions.
  const isIncrementDisabledForRewardRedemption = () => {
    if (limitPerOrderMet) {
      return true;
    }
    if (!incentiveNotInMenu || !loyaltyEnabled) {
      return false;
    }
    const checkRedemptionEligibility = () => {
      if (rewardBenefitId) {
        const incrementedPointCost = rewardPointCost * (productQuantity + 1);
        return stagedCartPoints <= incrementedPointCost;
      }
      return false;
    };
    return !!cartIdEditing || checkRedemptionEligibility();
  };

  const showIncrementor = useMemo(
    () => !isSimplyOffer && isOffer === false && !maxCartQuantityMet && isItemAvailableForDaypart,
    [isSimplyOffer, isOffer, maxCartQuantityMet, isItemAvailableForDaypart]
  );

  const disableIncrementQuantity = maxCartQuantityMet || isIncrementDisabledForRewardRedemption();
  const disableIncrementor =
    maxCartQuantityMet || (loyaltyEnabled && incentiveNotInMenu && !!cartIdEditing);

  // If price is not set correctly, don't allow adding such an item to cart, except offers
  const isCurrentPriceValid = totalPrice() > 0 || isOffer;
  const productName = selectedProduct?.name?.locale;

  const messageId = editingCartEntry
    ? 'updateQuantityAndItemWithPrice'
    : 'addQuantityAndItemWithPrice';

  const ariaLabel = formatMessage(
    { id: messageId },
    { itemName: productName, quantity: productQuantity, price: formattedProductPrice }
  );

  const addToOrderText = formatMessage({
    id: editingCartEntry ? 'update' : 'addToCart',
  });

  const label = useMemo(() => {
    if (isSimplyOffer) {
      return 'Add Item';
    } else if (maxCartQuantityMet) {
      return formatMessage({ id: 'limitReached' });
    }
    return `${addToOrderText} - ${formattedProductPrice}`;
  }, [addToOrderText, formatMessage, formattedProductPrice, isSimplyOffer, maxCartQuantityMet]);

  const handlePress = () => {
    ensureOfferRef.current?.disable();
    if (isSimplyOffer) {
      const { mainEntry } = computeCartEntriesForCart();
      mainEntry && addItemToSimplyOfferCart(mainEntry);
    } else {
      addToOrder();
    }
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Navigation,
      attributes: {
        component: ClickEventComponentNames.BUTTON,
        text: editingCartEntry ? 'Product Update' : 'Product Addition',
      },
    });
  };

  let invalidProductCriteria = !isCurrentPriceValid || maxCartQuantityMet;

  if (rewardBenefitId) {
    invalidProductCriteria = invalidProductCriteria || !!hasIncentiveInCart;
  }

  const shouldShowServiceModeBar =
    enableServiceModeBarForDelivery && isDelivery && isMobile && !isSimplyOffer;

  return (
    <>
      {!isSimplyOffer && <EnsureOfferIsAddedDialog ref={ensureOfferRef} />}
      {shouldShowServiceModeBar && <SelectedStoreAndServiceModeButton />}
      <ProductCtaWrapper
        elements={[
          {
            element: showIncrementor && (
              <NumberInputWrapper>
                <NumberInput
                  displayedValue={productQuantity}
                  value={productQuantity}
                  onChange={handleQuantityUpdate}
                  min={MIN_CART_QUANTITY}
                  max={availableCartQuantity}
                  hideNumberInput
                  disableIncrement={disableIncrementQuantity}
                  disabled={disableIncrementor}
                  ariaLabel={`Quantity ${productQuantity} of ${productName}`}
                  decrementAriaLabel={`decrement-${productName}`}
                  incrementAriaLabel={`increment-${productName}`}
                  displayValueStyleProps={displayValueStyleProps}
                  incrementorStyleProps={incrementorStyleProps}
                />
              </NumberInputWrapper>
            ),
          },
          {
            element: isItemAvailableForDaypart ? (
              <HomeCTAContainer showIncrementor={showIncrementor}>
                <ActionButton
                  accessibilityLabel={ariaLabel}
                  testID={MENU_PRODUCT_PAGE_PRIMARY_ACTION}
                  disabled={invalidProductCriteria}
                  onPress={handlePress}
                  fullWidth
                  px={{ desktop: '$9' }}
                >
                  {label}
                </ActionButton>
              </HomeCTAContainer>
            ) : (
              <ItemUnavailableMessage
                dayParts={dayParts}
                itemDayParts={itemDayParts}
                marginAndBorders={false}
              />
            ),
          },
        ]}
      />
    </>
  );
};
