import React from 'react';
import { View } from 'react-native';

import { NutritionFactsProps } from './nutrition-facts/nutrition-facts';
import { NutritionalBottomSheet } from './nutritional-bottom-sheet';

type NutritionalInformationProps = {
  nutritionFacts: NutritionFactsProps;
  onClose: () => void;
  isVisible: boolean;
};

export const NutritionalInformation = ({
  nutritionFacts,
  onClose,
  isVisible,
}: NutritionalInformationProps) => {
  return (
    <View>
      <NutritionalBottomSheet
        onClose={onClose}
        isVisible={isVisible}
        headerTitle="Nutritional Information"
        nutritionFacts={nutritionFacts}
      />
    </View>
  );
};
