import { OfferMock } from '../types';

import { data } from './mock.data';

/*
 * represents a graphql mutation:
 *
 * mutation AddItemToCart($id: ID!) {
 *   addItemToCart(id: $id): Cart
 * }
 */
export async function _getOffers(): Promise<OfferMock[]> {
  await sleep(500);
  return data;
}

const sleep = (time: number) => new Promise(resolve => setTimeout(resolve, time));
