import { Redirect, router } from 'expo-router';
import { useEffect } from 'react';
import { SafeAreaView, SectionList, StyleSheet } from 'react-native';
import { View } from 'react-native';

import { Schema } from '@fhs/backend';
import { useMutation } from '@fhs/client';
import { Button, IconLocked, Text, tokens } from '@fhs/ui';
import { Toast } from '@fhs-legacy/native-base';

import { commitOrder } from '../api';
import { CheckoutOrderSummary } from '../components/checkout-order-summary';
import { CheckoutPaymentDetails } from '../components/checkout-payment-details';
import { CheckoutPickupDetails } from '../components/checkout-pickup-details';
import { useSelectedPayment } from '../state/payments';

type CartState = Schema['CartState']['type'];

const sections = [
  { title: 'PickupDetails', data: [void 0] },
  { title: 'PaymentDetails', data: [void 0] },
  { title: 'OrderSummary', data: [void 0] },
];

export function CheckoutScreen({ cart }: { cart: Schema['Cart']['type'] }) {
  const {
    isPending: committingOrder,
    error,
    mutate,
  } = useMutation({
    ...commitOrder,
    onSuccess(data) {
      if (data.state === 'INSERT_SUCCESSFUL') {
        router.replace(`/v2/order/${data.rbiOrderId}`);
      }
    },
  });

  useEffect(() => {
    if (error) {
      Toast.show({ title: error.message });
    }
  }, [error]);

  if (cart.entries.length === 0) {
    return <Redirect href="/v2/cart" />;
  }

  return (
    <>
      {committingOrder && <View style={styles.uiblock} />}

      <SectionList
        sections={sections}
        style={styles.flex1}
        contentContainerStyle={[styles.container, styles.list]}
        renderSectionHeader={({ section }) => {
          switch (section.title) {
            case 'PickupDetails':
              return (
                <Text.Ui size="md" weight="bold">
                  Pick Up Details
                </Text.Ui>
              );
            case 'PaymentDetails':
              return (
                <Text.Ui size="md" weight="bold">
                  Payment Details
                </Text.Ui>
              );
            case 'OrderSummary':
              return (
                <Text.Ui size="md" weight="bold">
                  Order Summary
                </Text.Ui>
              );
            default:
              return null;
          }
        }}
        renderItem={({ section }) => {
          switch (section.title) {
            case 'PickupDetails':
              // This shouldnt ever happen.
              if (!cart.store) {
                throw new Error('Store should be defined to get to this point');
              }

              return (
                <CheckoutPickupDetails
                  store={cart.store}
                  orderTime={cart.metadata?.userSelectedOrderTime}
                />
              );
            case 'PaymentDetails':
              return <CheckoutPaymentDetails />;
            case 'OrderSummary':
              return <CheckoutOrderSummary total={(10).toFixed(2)} />;
            default:
              return null;
          }
        }}
      />

      <SafeAreaView>
        <View style={[styles.container, styles.buttonContainer]}>
          <ActionButton commit={mutate} loading={committingOrder} state={cart.state} />
        </View>
      </SafeAreaView>
    </>
  );
}

function ActionButton({
  state,
  loading,
  commit,
}: {
  state: CartState;
  loading: boolean;
  commit: Function;
}) {
  const payment = useSelectedPayment();

  switch (state) {
    case 'LOYALTY_INVALID':
      // todo, show loyalty error states
      return (
        <Button size="lg" disabled>
          <Button.Icon>
            <IconLocked />
          </Button.Icon>
          <Button.Text>Place Secure Order</Button.Text>
        </Button>
      );

    case 'NEEDS_LOYALTY_VALIDATION':
      return (
        <Button size="lg" loading>
          <Button.Text>Validating Offers</Button.Text>
        </Button>
      );

    default:
      return (
        <Button
          size="lg"
          loading={loading || state === 'PRICE_REQUESTED'}
          onPress={() => {
            if (!payment) {
              throw new Error('Payment should be defined to get to this point');
            }

            commit({
              payment: {
                worldpayInput: {
                  storedPaymentMethodId: payment.id,
                },
                fullName: payment.fullName,
              },
            });
          }}
        >
          <Button.Icon>
            <IconLocked />
          </Button.Icon>
          <Button.Text>Place Secure Order</Button.Text>
        </Button>
      );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    borderTopColor: tokens.colors.$black10,
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  container: {
    marginHorizontal: 'auto',
    padding: 16,
    width: '100%',
  },
  flex1: {
    flex: 1,
  },
  list: {
    gap: 16,
  },
  subtotalContainer: {
    backgroundColor: tokens.colors.$houseLight,
    width: '100%',
    height: 48,
  },
  subtotalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uiblock: {
    backgroundColor: tokens.colors.$black10,
    zIndex: 1000,
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});
