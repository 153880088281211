import { StyleSheet, View, ViewProps } from 'react-native';

import { Text, Wordmark, createMqStyles } from '@fhs/ui';

import { ProductTray } from '../product-detail-layout/product-tray';

type HeroProps = {
  displayName: string;
  description: string;
  variant: string;
  cals: string;
  imageUrl: string;
};

export const HeroVariant = ({ cals, description, displayName, imageUrl, variant }: HeroProps) => {
  const mqStyles = useMqStyles();
  return (
    <>
      <Spacer style={styles.minHeight24} />
      <View style={styles.content}>
        <Wordmark style={mqStyles.logo} />
        <View style={styles.gap12}>
          <View>
            <Text weight="bold" style={[mqStyles.name]}>
              {displayName}
            </Text>
            {cals && (
              <Text.Paragraph size="lg" style={[styles.textCenter, mqStyles.cals]}>
                {variant} • {cals}
              </Text.Paragraph>
            )}
          </View>
          {description && (
            <Text.Paragraph size="md" style={[styles.textCenter, mqStyles.description]}>
              {description}
            </Text.Paragraph>
          )}
        </View>
      </View>
      <Spacer style={styles.minHeight28} />
      <ProductTray source={imageUrl} />
    </>
  );
};

function Spacer(props: ViewProps) {
  return <View {...props} style={[styles.spacer, props.style]} />;
}

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    paddingHorizontal: 24,
    marginHorizontal: 'auto',
  },
  spacer: {
    flexGrow: 1,
  },
  minHeight28: {
    minHeight: 28,
  },
  minHeight24: {
    minHeight: 24,
  },
  textCenter: {
    textAlign: 'center',
  },
  gap12: {
    gap: 12,
  },
});

const useMqStyles = createMqStyles({
  logo: {
    $base: {
      display: 'flex',
      height: 56,
      alignSelf: 'center',
      marginBottom: 24,
    },
    $gteDesktop: {
      display: 'none',
    },
  },
  description: {
    $gteDesktop: {
      fontSize: 'clamp(12px, 1.6vh, 16px)' as any,
    },
  },
  cals: {
    $gteDesktop: {
      fontSize: 'clamp(14px, 2.1vh, 20px)' as any,
    },
  },
  name: {
    $base: {
      fontSize: 28,
      lineHeight: 28 * 1.4,
      textAlign: 'center',
    },
    $gteDesktop: {
      fontSize: 'clamp(20px, 5vh, 48px)' as any,
      lineHeight: `clamp(30px, 5vh, ${48 * 1.4}px)` as any,
    },
  },
});
