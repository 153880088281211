import { produce } from 'immer';
import { useMemo } from 'react';

import {
  MenuSectionItem,
  OfferDetail,
  Step as OfferStep,
} from '@fhs/backend/amplify/functions/_temp/graphql/API';
import { create } from '@fhs/zustand';
import { ICartEntry } from '@rbi-ctg/menu';

export type OfferGuideCart = {
  modifyItemSelectionInStep: (step: number, cartEntry: ICartEntry) => void;
  goToStep: (index: number, isEdit?: boolean) => void;
  goToPrevStep: () => void;
  goToNextStep: () => void;
  goFromScratch: () => void;
  applyPreSelectedItems: (cartEntries: ICartEntry[]) => void;
  setOffer: (offer: SimplyOfferType) => void;
  reset: () => void;
  offerInfo?: Omit<SimplyOfferType, 'steps'>;
  steps: Step[];
  currentStepIndex: number;
  isGoingForward: boolean;
  showSummary: boolean;
};

export type Step = OfferStep & {
  selectedEntry?: ICartEntry;
};

export type SimplyOfferType = OfferDetail & {
  steps: Step[];
};

const _isValidIndex = (newIndex: number, stepsLength: number) => {
  if (newIndex < 0 || newIndex >= stepsLength) {
    return false;
  }

  return true;
};

const _getNextNotDoneStep = (steps: Step[]) => {
  const lastIndexWithoutProduct = steps.findIndex(step => !step.selectedEntry);

  if (lastIndexWithoutProduct === -1) {
    return steps.length;
  }
  return lastIndexWithoutProduct;
};

export const useOfferCart = create<OfferGuideCart>()((set, get) => {
  const goToStep = (index: number) => {
    const state = get();
    const stepsLength = state.steps.length;
    if (index === stepsLength) {
      setTimeout(() => {
        set({ currentStepIndex: index, showSummary: true });
      }, 100);
      set({ isGoingForward: true });
      return;
    }

    if (_isValidIndex(index, stepsLength)) {
      setTimeout(() => {
        set({ currentStepIndex: index, showSummary: false });
      }, 100);
      if (index < state.currentStepIndex) {
        set({ isGoingForward: false });
        return;
      }
      set({ isGoingForward: true });
    }
  };
  return {
    steps: [],
    currentStepIndex: 0,
    isGoingForward: true,
    showSummary: false,
    reset: () => set({ steps: [], currentStepIndex: 0, isGoingForward: true, showSummary: false }),
    goToNextStep: () => () => goToStep(get().currentStepIndex + 1),
    goToPrevStep: () => goToStep(get().currentStepIndex - 1),
    goToStep,
    applyPreSelectedItems: (cartEntries: ICartEntry[]) =>
      set(state => {
        const newSteps = produce(state.steps, draftSteps => {
          draftSteps.forEach(draftStep => {
            const selectedEntry = cartEntries.find(
              cartEntry => cartEntry.cartId === draftStep.preSelectedItem
            );
            draftStep.preSelectedItem = undefined;

            if (selectedEntry) {
              const eligibleItem = draftStep.eligibleItems.find(
                item => item.id === selectedEntry?._id
              );

              draftStep.selectedEntry = addCartEntryImage(selectedEntry, eligibleItem);
            }
          });
        });

        const nextStepIndex = _getNextNotDoneStep(newSteps);
        goToStep(nextStepIndex);

        return { steps: newSteps };
      }),
    goFromScratch: () =>
      set(state => {
        const newSteps = produce(state.steps, draftSteps => {
          draftSteps.forEach(draftStep => {
            draftStep.preSelectedItem = undefined;
          });
        });

        return { steps: newSteps };
      }),
    setOffer: (offer: SimplyOfferType) => {
      const { steps, ...offerInfo } = offer;
      set({ steps, offerInfo });
    },
    modifyItemSelectionInStep: (step: number, cartEntry: ICartEntry) =>
      set(state => {
        const newSteps = produce(state.steps, draftSteps => {
          draftSteps[step].selectedEntry = cartEntry;
        });

        const nextStepIndex = _getNextNotDoneStep(newSteps);
        goToStep(nextStepIndex);

        return { steps: newSteps };
      }),
  };
});

export const useIsOfferCartDirty = () => {
  const steps = useOfferCart(state => state.steps);
  const isDirty = useMemo(() => steps.some(step => !!step.selectedEntry), [steps]);

  return isDirty;
};

export const useIsItemInCart = () => {
  const steps = useOfferCart(state => state.steps);
  const isItemInCart = useMemo(() => steps.some(step => !!step.preSelectedItem), [steps]);

  return isItemInCart;
};

export const useSelectedStep = () => {
  const steps = useOfferCart(state => state.steps);
  const currentStepIndex = useOfferCart(state => state.currentStepIndex);
  const selectedStep = useMemo(() => steps[currentStepIndex], [steps, currentStepIndex]);

  return selectedStep;
};

export const addCartEntryImage = (cartEntry: ICartEntry, eligibleItem: MenuSectionItem) => {
  return {
    ...cartEntry,
    image: {
      ...cartEntry.image,
      asset: { ...cartEntry?.image?.asset, url: eligibleItem?.image?.asset?.uri ?? '' },
    },
  } as ICartEntry;
};
