import { ActivityIndicator, Pressable, StyleSheet, View } from 'react-native';

import { useQuery } from '@fhs/client';
import {
  IconPaymentTypeAddNewPayment,
  IconPaymentTypeAmex,
  IconPaymentTypeMastercard,
  IconPaymentTypeVisa,
  Text,
  tokens,
} from '@fhs/ui';

import { getLegacyUserPaymentInformationQuery } from '../../api';

const iconMap = {
  VISA: <IconPaymentTypeVisa size={24} />,
  MASTERCARD: <IconPaymentTypeMastercard size={24} />,
  AMEX: <IconPaymentTypeAmex size={24} />,
};

export function PaymentMethodSelection() {
  const { data, isPending } = useQuery(getLegacyUserPaymentInformationQuery);

  if (isPending) {
    return <ActivityIndicator />;
  }

  return (
    <>
      {(data?.storedPaymentMethods ?? []).map(method => {
        // The types of the data assume null could be a valid value here. It's not,
        // but we have to account for it to make TS happy.
        if (!method) {
          return null;
        }

        return (
          <Pressable style={styles.row} key={method.id}>
            <View style={styles.innerRow}>
              <View style={styles.iconCircle}>{iconMap[method.brand!]}</View>
              <Text.Ui weight="semibold" size="lg">
                {method.brand} • • • • {method.last4}
              </Text.Ui>
            </View>
          </Pressable>
        );
      })}
      <Pressable style={styles.row}>
        <View style={styles.innerRow}>
          <View style={styles.iconCircle}>
            <IconPaymentTypeAddNewPayment size={24} />
          </View>
          <Text.Ui weight="semibold" size="lg">
            Add New Credit Card
          </Text.Ui>
        </View>
      </Pressable>
    </>
  );
}

const styles = StyleSheet.create({
  row: {
    borderBottomColor: tokens.colors.$black10,
    borderBottomWidth: 1,
    width: '100%',
    height: 64,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  innerRow: {
    width: 390,
    marginHorizontal: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
  },

  iconCircle: {
    height: 40,
    width: 40,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colors.$houseLight,
    marginRight: 12,
  },
});
