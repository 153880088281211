import { Link, useLocalSearchParams } from 'expo-router';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { Button, TabbedHeader, createMqStyles, tokens } from '@fhs/ui';
export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export function ScreenCustomizeItem() {
  const mqStyles = useMqStyles();
  const { pickerAspectOptionId } = useLocalSearchParams();
  const { slug } = useLocalSearchParams();
  const onCloseUrl = `/v2/menu/${slug}?pickerAspectOptionId=${pickerAspectOptionId}`;
  return (
    <SafeAreaView edges={['right', 'bottom', 'left']} style={mqStyles.container}>
      <View style={mqStyles.tabbedHeader}>
        <TabbedHeader activeIndex={1} items={mockedTabbedHeaderItems} onSelect={() => {}} />
      </View>
      <View style={mqStyles.buttonContainer}>
        <Link href={onCloseUrl} asChild style={mqStyles.looksGoodButton}>
          <Button loading={false} onPress={() => {}}>
            <Button.Text style={{ fontSize: 18 }}>Looks Good!</Button.Text>
          </Button>
        </Link>
      </View>
    </SafeAreaView>
  );
}

const useMqStyles = createMqStyles({
  container: {
    $base: {
      flex: 1,
      width: '100%',
      backgroundColor: tokens.colors.$white,
      justifyContent: 'space-between',
    },
  },
  tabbedHeader: {
    $base: {
      alignItems: 'center',
      backgroundColor: tokens.colors.$white,
      borderBottomWidth: 3,
      borderColor: tokens.colors.$blackOpacity10,
      width: '100%',
    },
    $gteDesktop: {},
  },
  content: {
    $base: {
      flex: 1,
      backgroundColor: tokens.colors.$white,
    },
    $gteDesktop: {
      width: '100%',
      height: '100%',
    },
  },
  buttonContainer: {
    $base: {
      height: 72,
      alignItems: 'center',
      justifyContent: 'center',
      borderTopColor: tokens.colors.$blackOpacity10,
      borderTopWidth: 1,
      paddingHorizontal: 16,
      paddingVertical: 16,
      backgroundColor: tokens.colors.$white,
    },
    $gteDesktop: {
      width: '100%',
    },
  },
  looksGoodButton: {
    $base: {
      height: 48,
      width: '100%',
      maxWidth: 480,
    },
    $ltDesktop: {
      maxWidth: 390,
    },
  },
});

const mockedTabbedHeaderItems = [
  {
    displayName: 'Default Toppings',
    id: '6',
    slug: 'Default Toppings',
    to: '',
  },
  {
    displayName: 'Cheese',
    id: '7',
    slug: 'Cheese',
    to: '',
  },
  {
    displayName: 'Meats',
    id: '4',
    slug: 'Meats',
    to: '',
  },
  {
    displayName: 'Extras',
    id: '2',
    slug: 'Extras',
    to: '',
  },
  {
    displayName: 'Desserts',
    id: '1',
    slug: 'Desserts',
    to: '',
  },
  {
    displayName: 'Beverages',
    id: '3',
    slug: 'Beverages',
    to: '',
  },
  {
    displayName: 'Kids Combo',
    id: '5',
    slug: 'Kids Combo',
    to: '',
  },
];
