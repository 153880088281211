import { Image, type ImageProps } from 'expo-image';
import { StyleSheet, View } from 'react-native';

import { useMqSelect } from '@fhs/ui';

import {
  productImageContainerHeight,
  productImageContainerHeightDesktop,
  productImageHeight,
  productImageHeightDesktop,
} from './constants';

export type ProductTrayProps = {
  source: ImageProps['source'];
};

export function ProductTray(props: ProductTrayProps) {
  const productImageHeightStyle = useMqSelect(
    { $gteDesktop: productImageHeightDesktop },
    productImageHeight
  );
  const productImageContainerHeightStyle = useMqSelect(
    {
      $gteDesktop: productImageContainerHeightDesktop,
    },
    productImageContainerHeight
  );

  return (
    <View
      style={[
        styles.container,
        {
          height: productImageContainerHeightStyle,
        },
      ]}
    >
      <Image
        source={props.source}
        style={[
          styles.productImg,
          {
            height: productImageHeightStyle,
          },
        ]}
        contentFit="contain"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  productImg: {
    width: '100%',
    position: 'absolute',
    top: 0,
  },
});
