import { Stack, useLocalSearchParams } from 'expo-router';

import { useStaticPage } from '@fhs/static-page';

import { getScreenTitleHeader } from '../../../components/get-screen-title-header';
import { fetchQuery } from '../../../static/fetch-query';

export default function StaticPageLayout() {
  const { staticPage: staticPageSlug = '' } = useLocalSearchParams<{ staticPage: string }>();
  const { staticPage } = useStaticPage(staticPageSlug);
  return (
    <Stack
      screenOptions={{
        header: getScreenTitleHeader({ backStyle: 'close', cannotGoBackHref: '/' }),
        title: staticPage?.title,
      }}
    />
  );
}

export async function generateStaticParams(): Promise<Record<'staticPage', string>[]> {
  const data = await fetchQuery(`query GetStaticPageRoutes {
        allStaticPages {
            _id
            path {
              current
            }
            localePath {
              en { current }
            }
            includedInSitemap
          }
        }
      `);

  return data.allStaticPages.map(({ path }) => ({
    staticPage: path.current,
  }));
}
