import { useEffect } from 'react';

import { useConfigValue } from 'hooks/configs/use-config-value';
import { useAmplitudeContext } from 'state/amplitude';
import { useLocale } from 'state/intl';
import { useGatewayFlags } from 'state/launchdarkly';

import { Header, setHeaders } from '../headers';

/**
 * Synchronizes dynamic context values with http headers to be sent with each GraphQL request
 */
export const useHeadersSync = () => {
  const { language, region } = useLocale();
  const { sessionId } = useAmplitudeContext();
  const gatewayFlags = useGatewayFlags();
  const applicationVersion = useConfigValue({ key: 'applicationVersion', isRegionalized: false });
  const commitSha = useConfigValue({ key: 'revisionId', isRegionalized: false });

  useEffect(() => {
    setHeaders(Header.LANGUAGE, language);
    setHeaders(Header.REGION, region);
    setHeaders(Header.SESSION_ID, sessionId);
    setHeaders(Header.GATEWAY_FLAGS, gatewayFlags);
    setHeaders(Header.UI_VERSION, applicationVersion);
    setHeaders(Header.UI_COMMIT_SHA, commitSha);
  }, [language, region, sessionId, gatewayFlags, applicationVersion, commitSha]);
};
