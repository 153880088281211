import { StyleSheet, View } from 'react-native';

import { Text } from '@fhs/ui';

type Props = {
  total: string;
};

export function CartSubtotals({ total }: Props) {
  return (
    <View style={styles.container}>
      <Text.Heading type="four">Subtotal:</Text.Heading>
      <Text.Heading type="four">${total}</Text.Heading>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
  },
});
