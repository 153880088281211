import { useMemo } from 'react';

import {
  GetStaticPageQuery,
  GetStaticPageRoutesQuery,
  IGetStaticPageQuery,
  IGetStaticPageRoutesQuery,
  IGetStaticPageVariables,
} from '@fhs-legacy/frontend/src/remote/queries/static-page';
import { useSanityQuery } from '@fhs-legacy/frontend/src/state/graphql';
import { useLocale } from '@fhs-legacy/frontend/src/state/intl';
import { getConfigValue } from '@fhs-legacy/frontend/src/utils/environment';

export const useStaticPage = (staticPageSlug: string) => {
  const staticPageIdFromConfig = getConfigValue({ key: 'staticPagesMap' })?.[staticPageSlug];

  const { data: staticPageRoutes = { allStaticPage: [] }, loading: isStaticPageRoutesLoading } =
    useSanityQuery<IGetStaticPageRoutesQuery>(
      GetStaticPageRoutesQuery,
      { skip: !!staticPageIdFromConfig },
      { isV2: true }
    );
  const routes = staticPageRoutes.allStaticPage;
  const appLanguage = useLocale().language as 'en' | 'fr';

  const { routeId: staticPageId, defaultLanguage: staticPageDefaultLanguange } = useMemo(() => {
    if (staticPageIdFromConfig) {
      return { routeId: staticPageIdFromConfig, defaultLanguage: appLanguage };
    }
    for (const route of routes || []) {
      const routeId = route._id;
      if (route.localePath?.en?.current === staticPageSlug) {
        return { routeId, defaultLanguage: 'en' } as const;
      }

      if (route.localePath?.fr?.current === staticPageSlug) {
        return { routeId, defaultLanguage: 'fr' } as const;
      }

      if (route.path.current === staticPageSlug) {
        return { routeId, defaultLanguage: appLanguage } as const;
      }
    }
    return {};
  }, [staticPageIdFromConfig, appLanguage, routes, staticPageSlug]);

  const { data: staticPage, loading: isStaticPageLoading } = useSanityQuery<
    IGetStaticPageQuery,
    IGetStaticPageVariables
  >(GetStaticPageQuery, {
    variables: {
      id: staticPageId,
    },
    skip: !staticPageId,
  });

  const isLoading = isStaticPageRoutesLoading || isStaticPageLoading;
  const isNotFound = !isStaticPageRoutesLoading && !staticPageId;

  return {
    isNotFound,
    isLoading,
    staticPage: staticPage?.StaticPage,
    staticPageDefaultLanguange,
  };
};
