import { router } from 'expo-router';
import { memo } from 'react';

import {
  ActionSheet,
  ActionSheetRow,
  IconAccount,
  IconCopy,
  IconInfo,
  ScrollView,
  Text,
  YStack,
} from '@fhs/ui';

import { IdentifyAuthJwt } from '../../backend/types';

type IdentifyActionSheetProps = {
  authJwts: IdentifyAuthJwt[];
  isVisible: boolean;
  onClose: () => void;
  onSelect: (jwt: string) => void;
};

function _IdentifyActionSheet({
  isVisible = false,
  authJwts = [],
  onClose,
  onSelect,
}: IdentifyActionSheetProps) {
  return (
    <ActionSheet isVisible={isVisible} onClose={onClose}>
      <YStack style={{ padding: 40, maxHeight: 400 }}>
        <YStack style={{ justifyContent: 'center', alignItems: 'center' }}>
          <IconCopy size={40} />
          <Text style={{ fontSize: 24, textAlign: 'center' }} weight="semibold">
            We found multiple accounts with this phone number
          </Text>
        </YStack>
        <ScrollView>
          {authJwts.map(user => {
            return (
              <ActionSheetRow
                type="radio"
                icon={<IconAccount size={24} />}
                onPress={() => {
                  onSelect(user.jwt);
                  onClose();
                }}
              >
                <ActionSheetRow.Item
                  firstLine={user.redactedIdentity.redactedName}
                  secondLine={user.redactedIdentity.redactedEmail}
                />
              </ActionSheetRow>
            );
          })}
          <ActionSheetRow
            type="radio"
            icon={<IconInfo size={24} />}
            onPress={() => {
              router.push('/v2/signin/(continuewithemail)');
              onClose();
            }}
          >
            <ActionSheetRow.Item
              firstLine={'None Of The Above'}
              secondLine={'We will help you find your account.'}
            />
          </ActionSheetRow>
        </ScrollView>
      </YStack>
    </ActionSheet>
  );
}

export const IdentifyActionSheet = memo(_IdentifyActionSheet);
