import { router } from 'expo-router';
import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, Divider, IconMail, PhoneNumberMaskInput, Text, XStack, tokens } from '@fhs/ui';

import { AuthIdentifiers, useAuthV2 } from '../backend';
import { LegalDisclaimer } from '../components';

export const SignIn = () => {
  const { createOtp, clearLoginJwt } = useAuthV2();
  const [loginIdentifier, setLoginIdentifier] = useState<string>('');

  const attemptLogin = useCallback(async () => {
    clearLoginJwt();
    await createOtp({
      loginIdentifier,
      identifierType: AuthIdentifiers.PHONE_NUMBER,
    });
  }, [createOtp, clearLoginJwt, loginIdentifier]);

  return (
    <>
      <PhoneNumberMaskInput value={loginIdentifier} onChange={setLoginIdentifier} />
      <XStack style={styles.dividerContainer}>
        <Divider />
        <Text style={styles.dividerText}>or</Text>
        <Divider />
      </XStack>
      <View style={styles.continueButton}>
        <Button
          style={styles.button}
          type={'outline'}
          size="md"
          onPress={() => {
            router.push('/v2/signin/(continuewithemail)');
          }}
        >
          <Button.Icon color={tokens.colors.$blackOpacity75}>
            <IconMail />
          </Button.Icon>
          <Button.Text style={styles.buttonContent}>Continue With Email</Button.Text>
        </Button>
      </View>
      <LegalDisclaimer />
      <View style={styles.continueButton}>
        <Button onPress={attemptLogin}>
          <Button.Text>Continue</Button.Text>
        </Button>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  continueButton: {
    width: '100%',
    height: 48,
  },
  button: {
    width: '100%',
  },
  buttonContent: {
    color: tokens.colors.$black,
    textDecorationColor: tokens.colors.$black,
  },
  dividerText: {
    color: tokens.colors.$disabledText,
    padding: 24,
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
