import { OfferIncentiveType } from '@fhs/backend/amplify/functions/_temp/graphql/API';
import { ISanityLocaleBlockContent, ServiceMode } from '@rbi-ctg/menu';

export type OfferMock = {
  name: string;
  id: string;
  description: string;
  expiration: string;
  imageUrl: string;
  type: string; // TODO: Type this properly
};

export interface Offer {
  id: string;
  image: string;
  name: string;
  expiryDate: string;
  isAvailable: boolean;
}

export interface Reward {
  id: string;
  image: string;
  name: string;
  point: number;
  isAvailable: boolean;
}

export interface LoyaltyIncentives {
  offers: Offer[];
  rewards: Reward[];
}

export interface GetLoyaltyIncentivesParams {
  loyaltyId?: string;
  storeId: string;
  serviceMode: ServiceMode;
}

// Offer V2

export enum PricingStrategyEnum {
  DISCOUNT = 'DISCOUNT',
  FIXED_PRICE = 'FIXED_PRICE',
  TIER_PRICE = 'TIER_PRICE',
}

export enum DiscountIncentiveTypeEnum {
  ITEM_LEVEL_DISCOUNT = 'ITEM_LEVEL_DISCOUNT',
  GLOBAL_DISCOUNT = 'GLOBAL_DISCOUNT',
  BOGO_DISCOUNT = 'BOGO_DISCOUNT',
  BOGO_DYNAMIC_DISCOUNT = 'BOGO_DYNAMIC_DISCOUNT',
  BUNDLE_DISCOUNT = 'BUNDLE_DISCOUNT',
  FEES_DISCOUNT = 'FEES_DISCOUNT',
}

export enum FixedPriceIncentiveTypeEnum {
  PRICE_POINT_DISCOUNT = 'PRICE_POINT_DISCOUNT',
  STEPPER_DISCOUNT = 'STEPPER_DISCOUNT',
}

export enum TierIncentiveTypeEnum {
  TIER_PRICE = 'TIER_PRICE',
}

export type IncentiveType =
  | DiscountIncentiveTypeEnum
  | FixedPriceIncentiveTypeEnum
  | TierIncentiveTypeEnum;

export interface LoyaltyOfferV2Incentive {
  defaultPrice?: number;
  maxItemQuantityDiscount?: number;
  maxPriceToDiscount?: number;
  pricingStrategy?: string; //pricingStrategyEnum
  type?: string;
  value?: number;
}

export interface LoyaltyCmsOfferV2Incentive {
  discountType: string;
  discountValue: number;
  incentiveType: OfferIncentiveType;
  discountFees?: (string | null)[] | null | undefined;
  _type: string;
}

export interface LoyaltyOfferV2 {
  cardDescription?: string;
  cmsId: string;
  description: string;
  expiryDate?: string;
  id: string;
  image?: string;
  incentives?: LoyaltyOfferV2Incentive;
  isAvailable?: boolean;
  name: string;
  termsAndConditions?: string;
}

export interface LoyaltyCmsOfferV2 {
  __typename: string;
  _type: string;
  _id: string;
  id: string;
  name: ISanityLocaleBlockContent;
  description: ISanityLocaleBlockContent;
  loyaltyEngineId: string;
  incentives: LoyaltyCmsOfferV2Incentive[];
}
