import { usePathname } from 'expo-router';
import { useEffect, useRef } from 'react';

import { useCRMEventsContext } from 'state/crm-events';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';

export default function useLogPageView() {
  const pathname = usePathname();
  const lastLoggedPathname = useRef('');
  const { logPageView } = useCRMEventsContext();
  const { store } = useStoreContext();
  const { serviceMode, deliveryFees } = useServiceModeContext();
  const fees = serviceMode === ServiceMode.DELIVERY ? deliveryFees : undefined;

  useEffect(() => {
    if (lastLoggedPathname.current !== pathname) {
      logPageView(pathname, store, fees ?? undefined);
      lastLoggedPathname.current = pathname;
    }
  }, [fees, logPageView, pathname, store]);
}
