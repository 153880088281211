import { router } from 'expo-router';
import { useCallback, useRef, useState } from 'react';

import { routes } from '@fhs-legacy/frontend/src/utils/routing';

import {
  useLoyaltyLegacyStates,
  useLoyaltyRedeemPromoCodeMutation,
} from '../../hooks/use-loyalty-legacy-states';
import { useLoyaltyIncentives } from '../../queries/loyalty.queries';

export enum PromoCodeErrorReason {
  redeemedCode = 'REDEEMED_CODE',
  expiredCode = 'EXPIRED_CODE',
  invalidCode = 'INVALID_CODE',
}

export class PromoCodeError extends Error {
  public message: PromoCodeErrorReason;

  constructor(message: PromoCodeErrorReason) {
    super();
    this.message = message;
  }
}

export const useRedeemPromoCode = ({ onDismiss }: { onDismiss: () => void }) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isRedeeming, setIsRedeeming] = useState<boolean>(false);
  const promoCodeOfferId = useRef('');
  const [redeemMutation] = useLoyaltyRedeemPromoCodeMutation();
  const { loyaltyId } = useLoyaltyLegacyStates();
  const { refetch } = useLoyaltyIncentives();
  const redeemPromoCode = useCallback(
    async (promoCode: string) => {
      try {
        setIsRedeeming(true);
        const { data } = await redeemMutation({
          variables: {
            input: {
              loyaltyId,
              code: promoCode,
              shouldRedeem: true,
            },
          },
        });
        const configId = data?.loyaltyValidatePromoCode?.configId;
        const personalizedOfferId = data?.loyaltyValidatePromoCode?.personalizedOfferId;

        if (!configId || !personalizedOfferId) {
          const reason = data?.loyaltyValidatePromoCode?.reason;
          const error =
            (reason && PromoCodeErrorReason[reason]) || PromoCodeErrorReason.invalidCode;

          throw new PromoCodeError(error);
        }
        promoCodeOfferId.current = personalizedOfferId;
        onDismiss();
        await refetch();
        setTimeout(() => {
          router.navigate(`${routes.offersV2Details}/${promoCodeOfferId.current}`);
        }, 1000);
      } catch (err) {
        const error =
          err instanceof PromoCodeError
            ? err
            : new PromoCodeError(PromoCodeErrorReason.invalidCode);
        switch (error.message) {
          case PromoCodeErrorReason.expiredCode:
            setErrorMessage('This promo code is expired. Please enter a valid one.');
            break;
          case PromoCodeErrorReason.redeemedCode:
            setErrorMessage('Promo code already redeemed. Please enter a valid one.');
            break;
          case PromoCodeErrorReason.invalidCode:
          default:
            setErrorMessage('Please enter a valid promo code.');
        }
      } finally {
        setIsRedeeming(false);
      }
    },
    [loyaltyId, onDismiss, redeemMutation, refetch]
  );
  return { redeemPromoCode, errorMessage, isRedeeming };
};
