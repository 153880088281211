import { Stack, useLocalSearchParams } from 'expo-router';
import Head from 'expo-router/head';
import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

import { NotFound, tokens } from '@fhs/ui';
import { RenderCurrentStaticPage } from '@fhs-legacy/frontend/src/pages/static/render-current-static-page';
import { StaticContentLanguageProvider } from '@fhs-legacy/frontend/src/pages/static/static-content-language';

import { getMetaTags } from './get-meta-tags';
import { useStaticPage } from './use-static-page';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  rendererContainer: {
    flex: 1,
    paddingTop: 0,
    marginTop: 0,
  },
  loadingContainer: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const StaticPage = () => {
  const { staticPage: staticPageSlug = '' } = useLocalSearchParams<{ staticPage: string }>();
  const { isNotFound, staticPageDefaultLanguange, staticPage } = useStaticPage(staticPageSlug);
  if (isNotFound) {
    return <NotFound />;
  }
  if (!staticPage) {
    return (
      <>
        <Stack.Screen options={{ title: '' }} />
        <View style={styles.loadingContainer}>
          <ActivityIndicator color={tokens.colors.$houseRedDarken} size="large" />
        </View>
      </>
    );
  }
  const title = `${staticPage.title} - Firehouse Subs`;
  return (
    <StaticContentLanguageProvider defaultLanguage={staticPageDefaultLanguange}>
      <View testID="static-page" style={[styles.container]}>
        <Head>
          <title>{title}</title>
          {getMetaTags({ staticPageSlug, title })}
        </Head>
        <Stack.Screen options={{ title: staticPage.title }} />
        <View style={[styles.rendererContainer]}>
          <RenderCurrentStaticPage currentPage={staticPage} />
        </View>
      </View>
    </StaticContentLanguageProvider>
  );
};
