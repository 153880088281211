import React from 'react';
import { GestureResponderEvent, Image, ImageStyle, StyleProp, View } from 'react-native';

import { Button, IconLocked, IconStarRateFill, Text, createMqStyles, tokens } from '@fhs/ui';

export interface RewardCardProps {
  imageUrl: string;
  title: string;
  points: number;
  onPress: (event: GestureResponderEvent) => void;
  isDisabled?: boolean;
  buttonLabel: string;
}

export function RewardCard({
  imageUrl,
  title,
  points,
  onPress,
  isDisabled,
  buttonLabel,
}: RewardCardProps) {
  const styles = useMqStyles();
  return (
    <View style={styles.rewardContainer}>
      <View style={styles.rewardChildrenContent}>
        <View style={styles.children}>
          <Text.Ui size="lg" numberOfLines={2} style={styles.childrenTextTitle} weight="bold">
            {title}
          </Text.Ui>
          <View style={styles.childrenPointsContainer}>
            {isDisabled ? (
              <IconLocked color={tokens.colors.$houseRed} />
            ) : (
              <IconStarRateFill color={tokens.colors.$houseYellow} />
            )}

            <Text.Ui size="md" weight="bold" style={styles.childrenPointsText}>
              {points.toLocaleString()}
            </Text.Ui>
          </View>
          <Button
            disabled={isDisabled}
            type="outline"
            size="xs"
            style={styles.childrenButton}
            onPress={onPress}
          >
            <Button.Text style={styles.buttonLabel}>{buttonLabel}</Button.Text>
          </Button>
        </View>
      </View>
      <Image source={{ uri: imageUrl }} style={styles.rewardImage as StyleProp<ImageStyle>} />
    </View>
  );
}
const useMqStyles = createMqStyles({
  rewardContainer: {
    $base: {
      alignItems: 'center',
      borderRadius: 8,
    },
    $ltDesktop: {
      height: 206,
      width: 160,
    },
    $gteDesktop: {
      height: 211,
      width: 215,
    },
  },
  rewardChildrenContent: {
    $base: {
      borderColor: tokens.colors.$black10,
      borderRadius: 8,
      borderWidth: 1,
      alignItems: 'center',
    },
    $ltDesktop: {
      // lt container heigh = marginTop + height
      marginTop: 32,
      height: 174,
      width: 160,
    },
    $gteDesktop: {
      // gte container heigh = marginTop + height
      marginTop: 36,
      height: 175,
      width: 215,
    },
  },
  children: {
    $base: {
      marginTop: 42,
    },
    $ltDesktop: {
      width: 128,
      height: 112,
    },
    $gteDesktop: {
      width: 167,
      height: 121,
    },
  },
  childrenTextTitle: {
    $base: {
      fontSize: 16,
      textAlign: 'center',
      alignContent: 'center',
      justifyContent: 'center',
    },
    $ltDesktop: {
      height: 40,
      fontSize: 14,
    },
    $gteDesktop: {
      height: 43,
      fontSize: 16,
    },
  },
  childrenPointsContainer: {
    $base: {
      alignItems: 'flex-end',
      flexDirection: 'row',
      paddingTop: 4,
      justifyContent: 'center',
    },
    $ltDesktop: {
      height: 25,
    },
    $gteDesktop: {
      height: 28,
    },
  },
  childrenPointsText: {
    $base: {
      paddingLeft: 4,
    },
    $ltDesktop: {
      fontSize: 12,
    },
    $gteDesktop: {
      fontSize: 14,
    },
  },
  childrenButton: {
    $base: {
      textAlign: 'center',
      marginTop: 12,
      height: 34,
      justifyContent: 'center',
    },
  },
  rewardImage: {
    $base: {
      height: 70,
      marginTop: 4,
      marginBottom: 4,
      position: 'absolute',
      width: 102.67,
    },
  },
  buttonLabel: {
    $ltDesktop: {
      fontSize: 10,
      lineHeight: 14,
    },
  },
});
