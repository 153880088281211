import { Fragment, type ReactNode } from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';

import { listStyles } from './shared-styles';

type ListItemProps = {
  isFirstItem?: boolean;
  isLastItem?: boolean;
};

type ListItemGroupProps = ViewProps & {
  header?: ReactNode;
  items: Array<{ id?: string; render: (props: ListItemProps) => ReactNode }>;
  footer?: ReactNode;
};

export function ListItemGroup({ header, footer, items = [], ...viewProps }: ListItemGroupProps) {
  const hasHeader = Boolean(header);
  const hasFooter = Boolean(footer);

  return (
    <View {...viewProps} style={[listStyles.list, viewProps.style]}>
      {hasHeader && <View style={styles.header}>{header}</View>}
      {items.map((listItem, index) => (
        <Fragment key={listItem.id ?? index}>
          {listItem.render({
            isFirstItem: !hasHeader && index === 0,
            isLastItem: !hasFooter && index === items.length - 1,
          })}
        </Fragment>
      ))}
      {hasFooter && <View style={styles.footer}>{footer}</View>}
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    padding: 16,
  },
  footer: {
    padding: 16,
  },
});
