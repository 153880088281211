import { createContext, useContext } from 'react';

export type QuantityContext<T> = {
  accessibilityHint?: string;
  defaultValue?: T;
  disabled?: boolean;
  options: Array<{ label: string; value: T }>;
  value: T;
  onChangeValue: (newValue: T) => void;
  label?: string;
  customLabelComponent?: React.ReactNode;
};

export const QuantityContext = createContext<QuantityContext<any> | null>(null);

export const useQuantity = <T>() => {
  const context = useContext(QuantityContext);

  if (__DEV__) {
    if (context == null) {
      throw new Error('Must have Quantity ancestor.');
    }
  }

  return context as QuantityContext<T>;
};
