import { Stack } from 'expo-router';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@fhs-legacy/universal-components';
import { ActionButtonVariants } from 'components/action-button';

import { ModalContent } from '../styled';

import {
  ButtonContainer,
  Container,
  LoadingText,
  StyledActionButton,
  StyledActionLink,
} from './delivery-unavailable.styled';
import { IDeliveryUnavailableProps } from './types';

/**
 *
 * DeliveryUnavailable informs the guest that the address they entered
 * is unable to receive delivery at this time
 *
 */

const DeliveryUnavailable: React.FC<React.PropsWithChildren<IDeliveryUnavailableProps>> = ({
  address,
  onChooseNewAddress,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Container flex={1} testID="delivery-unavailable-for-address">
      <Stack.Screen options={{ title: formatMessage({ id: 'deliveryNotAvailable' }) }} />
      <ModalContent>
        <Box flex={1}>
          <LoadingText fontSize="sm">{formatMessage({ id: 'cannotDeliveryTo' })}</LoadingText>
          <LoadingText>{address}</LoadingText>
        </Box>
        <ButtonContainer>
          <StyledActionLink fullWidth to="/store-locator/service-mode" replace>
            {formatMessage({ id: 'orderPickup' })}
          </StyledActionLink>
        </ButtonContainer>
        <ButtonContainer>
          <StyledActionButton
            testID="use-different-address"
            fullWidth
            onPress={onChooseNewAddress}
            variant={ActionButtonVariants.OUTLINE}
          >
            {formatMessage({ id: 'useDifferentAddress' })}
          </StyledActionButton>
        </ButtonContainer>
      </ModalContent>
    </Container>
  );
};

export default DeliveryUnavailable;
