import React from 'react';
import { useIntl } from 'react-intl';
import { StyleProp, View, ViewStyle } from 'react-native';

import {
  Button,
  IconList,
  IconStarRateFill,
  Skeleton,
  Text,
  createMqStyles,
  tokens,
} from '@fhs/ui';

interface PointsProps {
  isLoading?: boolean;
  points: number;
}

const Points = ({ points, isLoading }: PointsProps) => {
  const { formatMessage } = useIntl();
  const mqStyles = useMqStyles();

  return (
    <View style={mqStyles.pointsContainer}>
      <IconStarRateFill color={tokens.colors.$houseYellow} size={28} />
      <View style={mqStyles.pointsContent}>
        {isLoading ? (
          <Skeleton style={mqStyles.pointsSkeleton} />
        ) : (
          <Text.Ui style={mqStyles.points} weight="semibold" size="md">
            {points.toLocaleString()}{' '}
          </Text.Ui>
        )}
        <Text.Ui size="md" style={mqStyles.points}>
          {formatMessage({ id: 'points' }).toLowerCase()}
        </Text.Ui>
      </View>
    </View>
  );
};

interface DisclaimerProps {
  expirationDateFormatted?: string;
}

const Disclaimer = ({ expirationDateFormatted }: DisclaimerProps) => {
  const { formatMessage } = useIntl();
  const mqStyles = useMqStyles();

  if (expirationDateFormatted) {
    return (
      <>
        <View style={mqStyles.disclaimerSeparator} />
        <Text.Ui size="sm" style={mqStyles.disclaimer}>
          {formatMessage(
            { id: 'rewardsPointsWillExpireDate' },
            {
              date: (
                <Text.Ui weight="semibold" size="md">
                  {expirationDateFormatted}
                </Text.Ui>
              ),
            }
          )}
        </Text.Ui>
      </>
    );
  }

  return null;
};

export interface ILoyaltyPointsBalanceProps {
  expirationDateFormatted?: string;
  isLoading?: boolean;
  onPressSeeHistory: () => void;
  points?: number;
  style?: StyleProp<ViewStyle>;
}

function LoyaltyPointsBalance({
  expirationDateFormatted,
  isLoading,
  onPressSeeHistory,
  points = 0,
  style,
}: ILoyaltyPointsBalanceProps) {
  const { formatMessage } = useIntl();
  const mqStyles = useMqStyles();

  return (
    <View style={[mqStyles.container, style]}>
      <View style={mqStyles.content}>
        <Points points={points} isLoading={isLoading} />
        <Button size="xs" type="outline" style={mqStyles.button} onPress={onPressSeeHistory}>
          <Button.Icon>
            <IconList />
          </Button.Icon>
          <Button.Text style={mqStyles.buttonText}>
            {formatMessage({ id: 'seeHistory' })}
          </Button.Text>
        </Button>
      </View>
      <Disclaimer expirationDateFormatted={expirationDateFormatted} />
    </View>
  );
}

const useMqStyles = createMqStyles({
  container: {
    $ltDesktop: {
      borderWidth: 1,
      borderRadius: 8,
      borderColor: tokens.colors.$blackOpacity10,
    },
  },
  content: {
    $base: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    $ltDesktop: {
      paddingHorizontal: 16,
      paddingVertical: 12,
    },
  },
  pointsContainer: {
    $base: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  pointsContent: {
    $base: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 4,
    },
  },
  points: {
    $base: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  pointsSkeleton: {
    $base: {
      marginRight: 4,
      width: 55,
      height: 16,
      borderRadius: 4,
    },
  },
  button: {
    $base: {
      paddingLeft: 16,
      paddingVertical: 8,
    },
  },
  buttonText: {
    $ltDesktop: {
      fontSize: 10,
    },
  },
  disclaimerSeparator: {
    $ltDesktop: {
      height: 1,
      backgroundColor: tokens.colors.$blackOpacity10,
      width: '100%',
    },
  },
  disclaimer: {
    $base: {
      padding: 16,
      paddingBottom: 20,
      textAlign: 'center',
    },
  },
});

export default LoyaltyPointsBalance;
