import { useQuery } from '@fhs/client';
import { create } from '@fhs/zustand';

import { getLegacyUserPaymentInformationQuery } from '../api';

export const usePaymentsStore = create<{
  selectedPaymentMethodId: null | string;
  setSelectedPaymentMethodId(payment: string): void;
}>(set => ({
  selectedPaymentMethodId: null,
  setSelectedPaymentMethodId: selectedPaymentMethodId => {
    set({ selectedPaymentMethodId });
  },
}));

export const useSelectedPayment = () => {
  const { data } = useQuery(getLegacyUserPaymentInformationQuery);
  const selectedPaymentMethodId =
    usePaymentsStore(s => s.selectedPaymentMethodId) ??
    data?.paymentPreferences?.storedPaymentMethodId;

  if (!data?.storedPaymentMethods) {
    return null;
  }
  if (!selectedPaymentMethodId) {
    return null;
  }

  return data.storedPaymentMethods.find(s => s?.id === selectedPaymentMethodId);
};
