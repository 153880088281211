import { OffersAndRewardsPage } from '@fhs/loyalty';
import { ErrorBoundary, ErrorCard } from '@fhs/ui';
import { withStoreSelected } from '@fhs-legacy/frontend/src/components/store-selected';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

function Screen() {
  return (
    <ErrorBoundary fallback={<ErrorCard />}>
      <OffersAndRewardsPage />
    </ErrorBoundary>
  );
}

export default withStoreSelected(Screen);
