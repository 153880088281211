import { Image } from 'expo-image';
import { useState } from 'react';
import { ImageStyle, StyleProp, TouchableWithoutFeedback, View, ViewStyle } from 'react-native';

import {
  Button,
  IconCheck,
  IconInfoFill,
  IconOffer,
  Text,
  createMqStyles,
  tokens,
  useMqSelect,
} from '@fhs/ui';

export type OfferCardProps = {
  imageUrl: string;
  title: string;
  description: string;
  onPressButton: () => Promise<void> | void;
  onPressImage: () => void;
  isDisabled?: boolean;
  buttonLabel: string;
  buttonVariant?: 'default' | 'applied';
  style?: StyleProp<ViewStyle>;
  tag?: string;
  isHidden?: boolean;
};

export function OfferCard({
  imageUrl,
  title,
  description,
  onPressButton,
  onPressImage,
  buttonLabel,
  isDisabled,
  style,
  tag,
  isHidden,
  buttonVariant = 'default',
}: OfferCardProps) {
  const isApplied = buttonVariant === 'applied';
  const [actionIsPending, setActionIsPending] = useState(false);
  const styles = useMqStyles();
  const isMobile = useMqSelect({ $ltDesktop: true }, false);
  const infoIconSize = isMobile ? 16 : 20;
  if (isHidden) {
    return <View style={style} />;
  }
  return (
    <View style={[styles.container, style]}>
      <TouchableWithoutFeedback onPress={onPressImage}>
        <View style={styles.imageContainer}>
          <Image
            style={styles.image as StyleProp<ImageStyle>}
            source={{ uri: imageUrl }}
            contentFit="contain"
          />
          <IconInfoFill
            color={tokens.colors.$blackOpacity75}
            style={styles.infoIcon}
            size={infoIconSize}
          />
          {tag && (
            <View style={styles.tagBadge}>
              <IconOffer size={16} color={tokens.colors.$white} />
              <Text.Ui size="xs" weight="semibold" style={styles.tagBadgeText}>
                {tag}
              </Text.Ui>
            </View>
          )}
        </View>
      </TouchableWithoutFeedback>
      <View style={styles.contentContainer}>
        <View style={styles.titleContainer}>
          <Text.Ui size="md" weight="semibold" ellipsizeMode="tail" numberOfLines={2}>
            {title}
          </Text.Ui>
        </View>
        <Text.Ui size="sm" numberOfLines={isMobile ? 1 : 2}>
          {description}
        </Text.Ui>
        <Button
          style={[
            styles.buttonContainer,
            isApplied && {
              backgroundColor: tokens.colors.$success,
              borderColor: tokens.colors.$success,
            },
          ]}
          hoveredStyle={
            isApplied && {
              backgroundColor: tokens.colors.$success,
              borderColor: tokens.colors.$success,
            }
          }
          disabledStyle={isApplied && { backgroundColor: tokens.colors.$success }}
          disabled={isDisabled || actionIsPending}
          loading={actionIsPending}
          type={isApplied ? 'solid' : 'outline'}
          size="xs"
          onPress={async () => {
            setActionIsPending(true);
            try {
              await onPressButton();
            } finally {
              setActionIsPending(false);
            }
          }}
        >
          {isApplied && (
            <Button.Icon color={tokens.colors.$white} size={16}>
              <IconCheck />
            </Button.Icon>
          )}
          <Button.Text style={[styles.buttonLabel, isApplied && { color: tokens.colors.$white }]}>
            {actionIsPending ? 'Applying' : buttonLabel}
          </Button.Text>
        </Button>
      </View>
    </View>
  );
}

const useMqStyles = createMqStyles({
  container: {
    $base: {
      width: 215,
      height: 297,
      borderColor: tokens.colors.$blackOpacity10,
      borderWidth: 1,
      borderRadius: 8,
      alignItems: 'center',
    },
    $ltDesktop: {
      width: 160,
      height: 232,
    },
  },
  contentContainer: {
    $base: {
      flex: 1,
      padding: 16,
      width: '100%',
    },
    $ltDesktop: {
      padding: 12,
    },
  },
  titleContainer: {
    $base: {
      flex: 1,
    },
  },
  imageContainer: {
    $base: {
      height: 140,
      width: '100%',
      borderTopStartRadius: 8,
      borderTopEndRadius: 8,
      backgroundColor: tokens.colors.$white,
      borderBottomWidth: 1,
      borderBottomColor: tokens.colors.$black10,
      overflow: 'hidden',
    },
    $ltDesktop: {
      height: 96,
    },
  },
  image: {
    $base: {
      flex: 1,
    },
  },
  infoIcon: {
    $base: {
      position: 'absolute',
      right: 12,
      top: 12,
    },
    $ltDesktop: {
      right: 8,
      top: 8,
    },
  },

  buttonContainer: {
    $base: {
      marginTop: 16,
      height: 34,
    },
    $ltDesktop: {
      height: 31,
    },
  },
  buttonLabel: {
    $ltDesktop: {
      fontSize: 10,
      lineHeight: 14,
    },
  },
  tagBadge: {
    $base: {
      bottom: 8,
      flexDirection: 'row',
      right: 8,
      color: tokens.colors.$white,
      backgroundColor: tokens.colors.$black,
      paddingVertical: 4,
      paddingHorizontal: 8,
      position: 'absolute',
      borderRadius: 4,
      gap: 4,
    },
  },
  tagBadgeText: {
    $base: {
      color: tokens.colors.$white,
    },
  },
});
