import { StyleSheet, View } from 'react-native';

import { ScreenCustomizeItem } from '@fhs/menu/src/screens/screen-customize-item';

export default function CustomizeItemModalScreen() {
  return (
    <View style={styles.container}>
      <ScreenCustomizeItem />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
